import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { BrokerQuoteResponse } from "api/rest/quote-requests";
import usableActions from "utils/store/usableActions";

type State = {
  quote: Partial<BrokerQuoteResponse>;
};

const initialState: State = {
  quote: {
    mode: "DRAYAGE",
  },
};

const brokerQuoteSubmissionSlice = createSlice({
  name: "brokerQuoteSubmissionSlice",
  initialState,
  reducers: {
    setQuote: (state, action: PayloadAction<Partial<State["quote"]>>) => {
      state.quote = { ...state.quote, ...action.payload };
    },
  },
});

export const { useSetQuote } = usableActions(brokerQuoteSubmissionSlice.actions);
export default brokerQuoteSubmissionSlice;
