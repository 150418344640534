import { AppBar, Divider, ModeLaneBanner, styled, Toolbar } from "@portex-pro/ui-components";
import { ShipmentAddress } from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import withAsync from "components/withAsync";
import Breadcrumb from "pages/shipper/components/Breadcrumb";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";
import { ModeEnum } from "types/Mode";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";

const modePalleteMap = {
  [ModeEnum.FTL]: "green",
  [ModeEnum.LTL]: "green",
  [ModeEnum.DRAYAGE]: "green",
  [ModeEnum.FCL]: "blue",
  [ModeEnum.AIR]: "purple",
} as const;

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${theme.palette.grey[300]}`,
  height: TOOLBAR_HEIGHT_PX,
}));

const QuoteRequestAppBar = withAsync({
  useHook: useLoadQuoteRequest,
  Component: ({ loadedData: { quoteRequest } }) => {
    const { t } = useTranslation(["shipper", "quoteRequest"]);
    const selectedMode = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.selectedMode);
    const currentStep = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.step);

    const breadcrumbElements = [
      {
        element: (
          <BreadcrumbLink key="requestQuote" active={!selectedMode} to="/shipper/requests/create">
            {t("shipper:requestQuote")}
          </BreadcrumbLink>
        ),
        predicate: true,
      },
      {
        element: (
          <Breadcrumb key="selectedMode" active={!currentStep}>
            <ModeLaneBanner
              palette={selectedMode && modePalleteMap[selectedMode]}
              lanes={quoteRequest.stops.map((stop) =>
                stop.address ? formatAddress(stop.address as unknown as ShipmentAddress, "short") : ""
              )}
              mode={selectedMode}
            />
          </Breadcrumb>
        ),
        predicate: !!selectedMode,
      },
      {
        element: (
          <Breadcrumb key="step" active>
            {currentStep ? t(`quoteRequest:QuoteRequestPageSteps_${currentStep}`) : ""}
          </Breadcrumb>
        ),
        predicate: !!currentStep,
      },
    ].reduce<JSX.Element[]>((acc, { element, predicate }) => {
      if (predicate) {
        acc.push(element);
      }
      return acc;
    }, []);

    return (
      <AppBar>
        <StyledToolbar variant="dense" disableGutters>
          <BreadcrumbsContainer>{breadcrumbElements}</BreadcrumbsContainer>
        </StyledToolbar>
        <Divider />
      </AppBar>
    );
  },
});

export default QuoteRequestAppBar;
