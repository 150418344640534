import { FC, ReactNode } from "react";

import { AppBar, Box, BoxProps, portexColor } from "@portex-pro/ui-components";

const SIDEBAR_WIDTH = "220px";
const SIDEBAR_BORDER = `1px solid ${portexColor.grey300}`;

type BidRequestPageLayoutProps = {
  appbar: ReactNode;
  sidebar: ReactNode;
  sidebarPosition?: "left" | "right";
  sidebarBoxProps?: BoxProps;
};

type BidRequestPageLayoutFC = FC<BidRequestPageLayoutProps> & { Sidebar: typeof RequestFlowSidebarLayout };

const RequestFlowLayout: BidRequestPageLayoutFC = ({
  appbar,
  children,
  sidebar,
  sidebarPosition = "right",
  sidebarBoxProps,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    bgcolor="background.paper"
    height="100%"
    minHeight={0}
    /** This 'overflow=auto' is important. Without it the vertical scrolling behavior cuts off the bg color */
    overflow="auto"
  >
    <AppBar>{appbar}</AppBar>
    <Box display="flex" flexGrow={1}>
      {sidebar && sidebarPosition === "left" && (
        <RequestFlowSidebarLayout sidebarPosition={sidebarPosition} boxProps={sidebarBoxProps}>
          {sidebar}
        </RequestFlowSidebarLayout>
      )}
      <Box flex={1}>{children}</Box>
      {sidebar && sidebarPosition === "right" && (
        <RequestFlowSidebarLayout sidebarPosition={sidebarPosition} boxProps={sidebarBoxProps}>
          {sidebar}
        </RequestFlowSidebarLayout>
      )}
    </Box>
  </Box>
);

const RequestFlowSidebarLayout: FC<{ sidebarPosition?: "left" | "right"; boxProps?: BoxProps }> = ({
  children,
  sidebarPosition = "right",
  boxProps,
}) => (
  <Box
    display="flex"
    width={SIDEBAR_WIDTH}
    minWidth={SIDEBAR_WIDTH}
    bgcolor={portexColor.grey50}
    borderLeft={sidebarPosition === "right" ? SIDEBAR_BORDER : undefined}
    borderRight={sidebarPosition === "left" ? SIDEBAR_BORDER : undefined}
    {...boxProps}
  >
    {children}
  </Box>
);

RequestFlowLayout.Sidebar = RequestFlowSidebarLayout;

export default RequestFlowLayout;
