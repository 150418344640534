import { EquipmentType } from "../api/types/EquipmentType";
import { ContainerType } from "../types";

export const getContainerTypeLabel = (type: ContainerType): string => {
  switch (type) {
    case EquipmentType.Dry:
      return "Dry";
    case EquipmentType.FlatRack:
      return "Flat Rack";
    case EquipmentType.OpenTop:
      return "Open Top";
    case EquipmentType.Platform:
      return "Platform";
    case EquipmentType.Reefer:
      return "Reefer";
    default: {
      return "";
    }
  }
};
