import { baseRestApi } from "api/rest/baseRestApi";
import { UrlParam } from "api/rest/types/responses";

import { BrokerQuoteResponse } from "./types";

type UrlParams = {
  quoteRequestId: UrlParam;
};

type BodyParams = BrokerQuoteResponse;

type QueryArgs = {
  urlParams: UrlParams;
  body: BodyParams;
};

type ResponseType = void;

module.hot?.accept();

export const submitBrokerQuoteApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["QUOTE_REQUESTS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    submitBrokerQuote: builder.mutation<ResponseType, QueryArgs>({
      query: ({ urlParams, body }) => ({
        method: "POST",
        url: `broker/quote-requests/${urlParams.quoteRequestId}/quote`,
        body: {
          ...body,
          submitterTimezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      }),
      invalidatesTags: ["QUOTE_REQUESTS"],
    }),
  }),
});

export const { useSubmitBrokerQuoteMutation } = submitBrokerQuoteApi;
