import { VFC } from "react";

import { Add, Close, FileCopyOutlined } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import AccordionControl from "pages/shipper/pages/request-quote/pages/ltl/components/AccordionControl";
import AccordionView from "pages/shipper/pages/request-quote/pages/ltl/components/AccordionView";
import { useTranslation } from "react-i18next";

import { NormalizedCargoGroup } from "../api/types/NormalizedCargoGroup";
import { containerSizesList } from "../const";
import { ContainerType } from "../types";
import { getCargoGroupEquipmentString } from "../utils/getCargoGroupEquipmentString";
import ContainerTypeButtonViewGroup from "./ContainerTypeButtonGroupView";

interface ContainerCargoGroupsView {
  cargoGroups: NormalizedCargoGroup[];

  onChangeCargoGroup: (id: string | number, changes: Partial<NormalizedCargoGroup>) => void;
  onAddCargoGroup: () => void;
  onDuplicate: (cargoGroup: NormalizedCargoGroup) => void;
  onRemoveCargoGroup: (id: string | number) => void;

  renderHandlingUnits: VFC<{ cargoGroupIds: NormalizedCargoGroup["id"][]; parentId: string | number }>;
}

const ContainerCargoGroupsView: VFC<ContainerCargoGroupsView> = (props) => {
  const {
    cargoGroups,
    onChangeCargoGroup,
    onAddCargoGroup,
    onDuplicate,
    onRemoveCargoGroup,
    renderHandlingUnits: HandlingUnitsView,
  } = props;
  const { t } = useTranslation(["common"]);

  return (
    <AccordionControl>
      <div className="w-full flex flex-col bg-white p-4">
        {cargoGroups.map((cargoGroup) => (
          <AccordionView
            summaryContent={
              <div className="w-full flex flex-row items-center justify-between content-center">
                <div className="flex flex-row space-x-2 items-center">
                  <Text size="medium" weight="bold">
                    {cargoGroup.equipment_type
                      ? getCargoGroupEquipmentString(cargoGroup, t)
                      : t("common:containers_count", { count: cargoGroup.count })}
                  </Text>
                  <FileCopyOutlined
                    color="primary"
                    fontSize="small"
                    onClick={(e) => {
                      onDuplicate(cargoGroup);
                      e.stopPropagation();
                    }}
                  />
                </div>
                <Close onClick={() => onRemoveCargoGroup(cargoGroup.id)} className="cursor-pointer" />
              </div>
            }
            controllerId={cargoGroup.id}
            accordionProps={{ style: { marginBottom: "12px" }, defaultExpanded: true }}
            accordionSummaryProps={{
              style: { backgroundColor: "white" },
              typographyProps: { style: { width: "100%" } },
            }}
            accordionDetailsProps={{ style: { backgroundColor: "white" } }}
          >
            <div className="grid grid-cols-4 gap-x-4 gap-y-8">
              <div className="col-span-2 flex flex-col">
                <Text size="medium" weight="bold" required>
                  {t("common:equipment")}
                </Text>
                <ContainerTypeButtonViewGroup
                  value={cargoGroup.equipment_type as ContainerType}
                  onChange={(equipment_type) => onChangeCargoGroup(cargoGroup.id, { equipment_type })}
                />
              </div>
              <div className="flex flex-col col-span-2">
                <Text size="medium" weight="bold" required>
                  {t("common:containerSize")}
                </Text>
                <FormSelectView
                  items={containerSizesList}
                  getItemCopy={(item) => t(`common:trailerSizes.${item}`)}
                  value={cargoGroup.equipment_size}
                  onChange={(equipment_size) => onChangeCargoGroup(cargoGroup.id, { equipment_size })}
                />
              </div>
              <div className="flex flex-col col-span-2">
                <Text size="medium" weight="bold">
                  {t("common:weightPerContainerOptional")}
                </Text>
                <PositiveNumberInput
                  value={cargoGroup.weight_per_count}
                  disableError
                  onChange={(total_weight) => onChangeCargoGroup(cargoGroup.id, { weight_per_count: total_weight })}
                />
              </div>
              <div className="flex flex-col col-span-2">
                <Text size="medium" weight="bold">
                  {t("common:volumePerContainerOptional")}
                </Text>
                <PositiveNumberInput
                  value={cargoGroup.volume_per_count}
                  disableError
                  onChange={(total_volume) => onChangeCargoGroup(cargoGroup.id, { volume_per_count: total_volume })}
                />
              </div>
              <div className="col-span-full">
                <HandlingUnitsView cargoGroupIds={cargoGroup.handling_units ?? []} parentId={cargoGroup.id} />
              </div>
            </div>
          </AccordionView>
        ))}
        <div className="w-full flex flex-row justify-end">
          <Button variant="outlined" color="primary" startIcon={<Add />} onClick={() => onAddCargoGroup()}>
            {t("common:addAnotherContainerType")}
          </Button>
        </div>
      </div>
    </AccordionControl>
  );
};

export default ContainerCargoGroupsView;
