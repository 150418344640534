import { useEffect, useState } from "react";

import { usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import { ShipperQuoteRequest } from "api/rest/quote-requests";
import usePrefetchAllContactIds from "app/pages/bid-request/hooks/usePrefetchAllPartnerIds";
import AddPartnerButtonContainer from "app/pages/bid-request/pages/partners/components/AddPartnerButtonContainer";
import PartnersHeaderView from "app/pages/bid-request/pages/partners/components/PartnersHeaderView";
import PartnersSearchView from "app/pages/bid-request/pages/partners/components/PartnersSearchView";
import PartnersTableView from "app/pages/bid-request/pages/partners/components/PartnersTableView";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ProgressionButtons from "components/ProgressionButtons";
import ScrollableView from "components/ScrollableView";
import SplitContentLayout from "components/SplitContentLayout";
import withAsync from "components/withAsync";
import without from "lodash/without";
import { useBoolean } from "usehooks-ts";
import { filterContacts } from "utils/filterContacts";

import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const mapContactTypeToPartner = (contact: ContactType): ShipperQuoteRequest["partners"][number] => ({
  contact_id: contact.id,
  company_name: contact.company_name,
  email: contact.user.email,
  first_name: contact.first_name,
  last_name: contact.last_name,
  has_responded: false,
  is_internal: false,
  quotes: [],
});

const PartnersStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Partners),
  Component: ({ loadedData: { quoteRequest } }) => {
    const changeQuoteRequest = quoteRequestActions.useChangeQuoteRequest();
    const saveAndChangeRoute = useSaveAndChangeRoute();

    const [search, setSearch] = useState("");
    const isIndeterminate = useBoolean(false);
    const { data, paginationOptions, isLoading, isFetching } = usePaginatedGetPartnersRequestQuery({
      queryParams: { partnerType: "external", search },
    });
    const partners = data?.data.partners;
    const selectedPartners: ShipperQuoteRequest["partners"] = quoteRequest.partners;

    const { allContacts: allPartners, isLoading: isLoadingAllPartners } = usePrefetchAllContactIds("external");
    const [isAwaitingPartners, setIsAwaitingPartners] = useState(false);

    const isChecked = (partner: ContactType): boolean => {
      return selectedPartners.some((selectedPartner) => selectedPartner.contact_id === partner.id);
    };

    const onClickRow = (partner: ContactType): void => {
      isIndeterminate.setTrue();
      const selectedPartner = selectedPartners.find((selectedPartner) => partner.id === selectedPartner.contact_id);

      if (selectedPartner) {
        changeQuoteRequest({ changes: { partners: without(selectedPartners, selectedPartner) } });
      } else {
        changeQuoteRequest({
          changes: {
            partners: [...selectedPartners, mapContactTypeToPartner(partner)],
          },
        });
      }
    };

    const onClickSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
      _event,
      checked
    ) => {
      isIndeterminate.setFalse();
      if (!checked) {
        changeQuoteRequest({ changes: { partners: [] } });
        return;
      }
      if (isLoadingAllPartners) {
        setIsAwaitingPartners(true);
      } else {
        // These are filtered partners
        const filteredPartners = filterContacts(allPartners, search);
        changeQuoteRequest({ changes: { partners: filteredPartners.map(mapContactTypeToPartner) } });
      }
    };

    useEffect(() => {
      if (!isLoadingAllPartners && isAwaitingPartners) {
        changeQuoteRequest({ changes: { partners: allPartners.map(mapContactTypeToPartner) } });
        setIsAwaitingPartners(false);
      }
    }, [isLoadingAllPartners, isAwaitingPartners, allPartners, changeQuoteRequest]);

    const onSearchChange = (search: string): void => {
      // without this check, checkbox is set to indeterminate on search when there are no selected partners
      if (selectedPartners.length > 0) {
        isIndeterminate.setTrue();
      }
      setSearch(search);
    };

    return (
      <ProgressionButtons
        onBack={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Shipment })}
        onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Team })}
      >
        <PartnersHeaderView />
        <SplitContentLayout
          leftContent={
            <>
              <PartnersSearchView search={search} onChange={onSearchChange} />
            </>
          }
          rightContent={<AddPartnerButtonContainer />}
        />
        <ScrollableView>
          <PartnersTableView
            isLoading={isLoading || isFetching}
            partners={partners ?? []}
            isChecked={isChecked}
            onClickRow={onClickRow}
            onClickSelectAll={onClickSelectAll}
            checkboxIndeterminate={isIndeterminate.value}
          />
        </ScrollableView>
        <CursorPaginationTableControlsView {...paginationOptions} />
      </ProgressionButtons>
    );
  },
});

export default PartnersStep;
