import { VFC } from "react";

import { AddressOption } from "types/AddressOption";
import Maybe from "types/Maybe";

import ManualLocationPickerDefaultLayout from "./ManualLocationPickerDefaultLayout";
import ManualLocationPickerVerticalLayout from "./ManualLocationPickerVerticalLayout";
import { ManualLocationHookState, useManualLocationState } from "./useManualLocationState";

type ManualLocationPickerViewProps = {
  value: Maybe<AddressOption>;
  onChange: (manualAddress: ManualLocationHookState["manualAddress"]) => void;
  exactAddressRequired: boolean;
  countries?: "domestic" | "international";
  variant: "vertical" | "default";
} & (
  | {
      variant: "default";
      onClickCancel: () => void;
    }
  | {
      variant: "vertical";
    }
);

const ManualLocationPickerView: VFC<ManualLocationPickerViewProps> = ({
  value,
  onChange,
  exactAddressRequired,
  countries = "domestic",
  ...props
}) => {
  const manualLocationState = useManualLocationState({
    value,
    onChange,
    exactAddressRequired,
  });

  if (props.variant === "vertical") {
    return (
      <ManualLocationPickerVerticalLayout
        {...manualLocationState}
        variant={props.variant}
        exactAddressRequired={exactAddressRequired}
        countries={countries}
      />
    );
  }

  return (
    <ManualLocationPickerDefaultLayout
      {...manualLocationState}
      variant={props.variant}
      countries={countries}
      onClickCancel={props.onClickCancel}
    />
  );
};

export default ManualLocationPickerView;
