import { ReactElement, VFC } from "react";

import { AppBar } from "@portex-pro/ui-components";

interface PageLayoutProps {
  header: ReactElement | ReactElement[];
  leftContent: ReactElement[];
  rightContent?: ReactElement[];
}

const PageLayout: VFC<PageLayoutProps> = (props) => {
  const { header, leftContent, rightContent } = props;

  return (
    <div className="w-full h-full flex flex-col overflow-none">
      {header && <AppBar>{header}</AppBar>}
      <div className="flex space-x-4 px-4 py-5 overflow-auto">
        <div className="flex flex-col space-y-3.5 w-full">{leftContent}</div>
        {rightContent && (
          <div className="flex flex-col space-y-3.5 w-96" style={{ flexShrink: 0 }}>
            {rightContent}
          </div>
        )}
      </div>
    </div>
  );
};

export default PageLayout;
