import { baseRestApi } from "api/rest/baseRestApi";

import { NormalizedCargoGroup } from "./types";
import { mapNormalizedCargoGroupsToCreateApi } from "./utils/mapNormalizedCargoGroupsToCreateApi";

const API_ROOT = "/shipper/cargo-groups";
module.hot?.accept();

type BodyParams = {
  normalizedCargoGroups: NormalizedCargoGroup[];
};

type QueryArgs = { body: BodyParams; cacheId: string };

const createCargoGroupsApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    createCargoGroups: builder.mutation<void, QueryArgs>({
      query: (params) => {
        const body = {
          cargo_groups: mapNormalizedCargoGroupsToCreateApi(structuredClone(params.body.normalizedCargoGroups)),
        };

        return {
          url: `${API_ROOT}`,
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export default createCargoGroupsApi;

export const { useCreateCargoGroupsMutation } = createCargoGroupsApi;
