import { FC } from "react";

import { Dialog } from "@portex-pro/ui-components";
import { Maybe } from "api/types/generated-types";
import { useBoolean } from "usehooks-ts";

import FilesControl, { OnUploadSuccessCallback } from "../file-uploads/FilesControl";
import { ChatContext, ChatContextType } from "./ChatContext";
import { useChatStyles } from "./chatStyles";
import ChatView from "./ChatView";
import { useChat } from "./useChat";

interface ChatContainerProps {
  conversationId: Maybe<string> | undefined;
  onReceiveFile?: () => void;
  maxFiles?: number;
}

const ChatContainer: FC<ChatContainerProps> = ({ conversationId, onReceiveFile, maxFiles }) => {
  const { value: isFullScreen, toggle: toggleFullScreen } = useBoolean(false);
  const classes = useChatStyles();

  const {
    messages,
    participants,
    addMessage,
    isSending,
    draftAttachments,
    addDraftAttachment,
    removeDraftAttachment,
    addParticipant,
  } = useChat(conversationId ? Number(conversationId) : 0, onReceiveFile);

  const contextValue: ChatContextType = { isFullScreen };

  const handleFileUploaded: OnUploadSuccessCallback = async ({ fileId, filename }) => {
    addDraftAttachment({ filename, fileId });
  };

  const chatContent = (
    <ChatView
      ChatHeadingViewProps={{
        onToggleFullscreen: toggleFullScreen,
        isFullScreen: isFullScreen,
        chatParticipants: participants,
        addParticipant,
      }}
      ChatContentViewProps={{
        messages: messages,
        addMessage: addMessage,
        isSending: isSending,
        draftAttachments: draftAttachments,
      }}
      isFullScreen={isFullScreen}
    />
  );

  if (!conversationId) {
    return null;
  }

  return (
    <ChatContext.Provider value={contextValue}>
      <FilesControl
        fileIds={draftAttachments.map((draft) => draft.fileId)}
        onUploadSuccess={handleFileUploaded}
        onDeleteSuccess={(fileId) => Promise.resolve(removeDraftAttachment(fileId))}
        dropzoneOptions={{ noClick: false, maxFiles, maxSize: 5e6 }}
      >
        {isFullScreen ? (
          <Dialog
            open={true}
            maxWidth={false}
            onClose={toggleFullScreen}
            classes={{
              paper: classes.fullScreenWrapper,
            }}
          >
            {chatContent}
          </Dialog>
        ) : (
          <>{chatContent}</>
        )}
      </FilesControl>
    </ChatContext.Provider>
  );
};

export default ChatContainer;
