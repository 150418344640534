import { ChangeEvent, ComponentProps, ReactElement, useState } from "react";

import { AddLocationOutlined, Flight, LocationOn, LocationOnOutlined } from "@material-ui/icons";
import {
  AutocompleteChangeReason,
  ComboBox,
  CustomColor,
  Icon,
  SvgAnchor,
  red,
  useComboBoxInputStyles,
} from "@portex-pro/ui-components";
import { Maybe } from "api/types/generated-types";
import uniqueId from "lodash/uniqueId";
import { useTranslation } from "react-i18next";
import { AddressOption, LocationOptionTypeEnum } from "types/AddressOption";
import { Mode, ModeEnum } from "types/Mode";
import { filterOptions } from "utils/addresses/filterOptions";
import { renderGroupLabel } from "utils/addresses/renderGroupLabel";
import { renderOptionLabel } from "utils/addresses/renderOptionLabel";

const renderGroupedOptions = (
  groupedOptions: { key: number; index: number; group: string; options: AddressOption[] }[]
) => {
  let optionIndex = 0;
  return (
    <ComboBox.List>
      {groupedOptions.map(({ options, group, index: groupIndex }) => (
        <ComboBox.Group key={groupIndex} label={renderGroupLabel(group as AddressOption["optionType"])}>
          {options.map((option) => (
            <ComboBox.Option
              key={option.id ?? option.placeId ?? option.google_place_id}
              option={option}
              index={optionIndex++} // important!
            >
              {option.optionType === LocationOptionTypeEnum.Manual ? (
                <AddLocationOutlined color="disabled" />
              ) : (
                <LocationOnOutlined color="disabled" />
              )}
              {
                <span style={{ fontWeight: option.optionType === LocationOptionTypeEnum.Manual ? "bolder" : "normal" }}>
                  {renderOptionLabel(option)}
                </span>
              }
            </ComboBox.Option>
          ))}
        </ComboBox.Group>
      ))}
    </ComboBox.List>
  );
};

const renderStartAdornment = (mode: Mode, startIconPallete: CustomColor, toDoor?: boolean) => {
  if (mode === ModeEnum.FCL && !toDoor) {
    return <Icon as={SvgAnchor} palette={startIconPallete} />;
  } else if (mode === ModeEnum.DRAYAGE && !toDoor) {
    return <Icon as={SvgAnchor} palette={startIconPallete} />;
  } else if (mode === ModeEnum.AIR && !toDoor) {
    return <Icon as={Flight} palette={startIconPallete} />;
  } else {
    return <Icon as={LocationOn} palette={startIconPallete} />;
  }
};

type LocationPickerViewProps = {
  mode: Mode;
  autoFocus: boolean;
  disabled: boolean;
  highlight: boolean;
  label?: string;
  required?: boolean;
  onBlur: ComponentProps<typeof ComboBox.Input>["onBlur"];
  onChange: (event: ChangeEvent<{}>, value: AddressOption | null, reason: AutocompleteChangeReason) => void;
  onInputChange: ComponentProps<typeof ComboBox>["onInputChange"];
  options: AddressOption[];
  placeholder?: string;
  startIconPallete: CustomColor;
  value?: Maybe<AddressOption>;
  toDoor?: boolean;
};

const LocationPickerView = ({
  mode,
  autoFocus,
  disabled,
  highlight,
  label,
  required = false,
  onBlur,
  onChange,
  onInputChange,
  options,
  startIconPallete,
  value,
  toDoor,
  ...props
}: LocationPickerViewProps): ReactElement => {
  const { t } = useTranslation();
  const { placeholder = t("addresses.addressPicker_placeholder") } = props;
  const [focused, setFocused] = useState(false);
  const shouldHighlight = highlight && !focused;

  const groupBy = (option: AddressOption) => option.optionType;
  const getOptionLabel = (option: AddressOption) => renderOptionLabel(option);
  const getOptionSelected = (option: AddressOption, valueOption?: Maybe<AddressOption>): boolean => {
    return (
      !!valueOption &&
      ((!!option.id && option.id === valueOption.id) ||
        (!!option.google_place_id && option.google_place_id === valueOption.google_place_id))
    );
  };
  const allowManualOption = mode === ModeEnum.FTL || mode === ModeEnum.LTL || toDoor;
  return (
    <ComboBox<AddressOption>
      id={uniqueId("location-picker-")}
      useStyles={useComboBoxInputStyles}
      openOnFocus
      options={options}
      value={value}
      filterOptions={(options, state) => filterOptions(options, state, { allowManualOption })}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getOptionSelected}
      groupBy={groupBy}
      onChange={onChange}
      onInputChange={onInputChange}
      renderGroupedOptions={renderGroupedOptions}
    >
      <ComboBox.FormControl>
        {label ? <ComboBox.Label required={required}>{label}</ComboBox.Label> : null}
        <ComboBox.Input
          onBlur={(e) => {
            onBlur && onBlur(e);
            setFocused(false);
          }}
          onFocus={() => setFocused(true)}
          autoFocus={autoFocus}
          inputProps={{ autocomplete: "new-password" }} // This is a stupid hack to prevent autofill on this input (will show as warning in dev for React.StrictMode)
          disabled={disabled}
          placeholder={placeholder}
          style={{
            borderWidth: shouldHighlight ? 2 : undefined,
            borderColor: shouldHighlight ? red["500"] : undefined,
          }}
          startAdornment={renderStartAdornment(mode, startIconPallete, toDoor)}
        />
      </ComboBox.FormControl>
    </ComboBox>
  );
};

export default LocationPickerView;
