import { ChangeEventHandler, FC, VFC, useState } from "react";

import { KeyboardArrowDown, KeyboardArrowUp } from "@material-ui/icons";
import { Button, Collapse, TextInput } from "@portex-pro/ui-components";
import Frame from "app/pages/shipments/components/Frame";
import { Shipment, ShipmentTruck } from "app/pages/shipments/types/domain";
import formatEquipment from "app/pages/shipments/utils/formatEquipment";
import TrailerTypeIcon from "components/TrailerTypeIcon";
import { Trans, useTranslation } from "react-i18next";

import { useShipmentDetails } from "../provider/ShipmentDetailsProvider";

interface TruckTableRowProps {
  label: string;
}

const TruckTableRow: FC<TruckTableRowProps> = ({ children, label }) => (
  <div className="flex gap-2 p-[16px] even:bg-zebra text-rem items-center justify-between">
    <div className="font-bold">{label}</div>
    <div className="flex-1 w-full max-w-[700px] flex gap-2 items-center">{children}</div>
  </div>
);

const MIN_NUMBER_OF_TRUCKS = 1;
const MAX_NUMBER_OF_TRUCKS = 100;

interface TruckSummaryViewProps {
  truck: ShipmentTruck;
  truckIndex: number;
  shipment: Shipment;
  isEditing: boolean;
  onChange(editedTruck: ShipmentTruck): void;
  removeTruck: (index: number) => void;
}

const TruckSummaryView: VFC<TruckSummaryViewProps> = ({
  truck,
  shipment,
  truckIndex,
  isEditing,
  onChange,
  removeTruck,
}) => {
  const { t } = useTranslation(["common", "shipper", "shipments"]);

  const handleRefNumberChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
    onChange({ ...truck, referenceNumber: ev.currentTarget.value });
  };

  return (
    <div className="flex gap-2 p-[16px] even:bg-zebra text-rem items-center justify-between">
      <div className="flex gap-2 items-center">
        <div className="whitespace-nowrap">
          <div className="font-bold">{`${t("shipments:shipmentDetails_truckDetails_rowTitle")} ${truckIndex + 1}`}</div>
          <div className="text-[14px]">{formatEquipment(t, shipment)}</div>
        </div>
        {(shipment.mode === "FTL" || shipment.mode === "LTL") && (
          <TrailerTypeIcon type={shipment.loadSpec.trailerType ?? null} small />
        )}
      </div>
      <div className="flex-1 w-full max-w-[700px] flex gap-2 items-center">
        <div className="font-bold whitespace-nowrap">{t("shipments:shipmentDetails_truckPO_label")}</div>
        <TextInput value={truck.referenceNumber} disabled={!isEditing} onChange={handleRefNumberChange} fullWidth />
        {shipment.mode !== "LTL" && (
          <button
            type="button"
            className="w-[42px] h-[42px] shrink-0 border border-border hover:border-brandBlue hover:text-brandBlue rounded-md"
            onClick={() => removeTruck(truckIndex)}
          >
            -
          </button>
        )}
      </div>
    </div>
  );
};

interface ShipmentDetailsTrucksViewProps {
  showEditDetails?: boolean;
  rolloutLoadStatuses: boolean | undefined;
}

const ShipmentDetailsTrucksView: VFC<ShipmentDetailsTrucksViewProps> = (props) => {
  const { showEditDetails = false } = props;
  const { t } = useTranslation(["shipments", "quoteDetails"]);
  const { isEditing, patch, shipmentPatch, patchedShipment, startEditing, save } = useShipmentDetails();
  const [isTruckListOpen, setIsTruckListOpen] = useState(patchedShipment.trucks?.length === 1);

  const handleTruckChange = (truckIndex: number) => (editedTruck: ShipmentTruck) => {
    const trucks: ShipmentTruck[] = shipmentPatch?.trucks || patchedShipment?.trucks || [];
    const newTrucks = [...trucks];
    newTrucks[truckIndex] = { ...trucks[truckIndex], ...editedTruck };

    patch({ trucks: newTrucks });
  };

  const removeTruck = (index: number) => {
    if (patchedShipment.trucks?.length && patchedShipment.trucks.length <= MIN_NUMBER_OF_TRUCKS) {
      return;
    }

    const newTrucks = patchedShipment.trucks?.slice(0, index).concat(patchedShipment.trucks?.slice(index + 1));

    patch({ trucks: newTrucks });
  };

  const addTruck = () => {
    if (patchedShipment.trucks?.length && patchedShipment.trucks.length >= MAX_NUMBER_OF_TRUCKS) {
      return;
    }

    const newTrucks = patchedShipment.trucks?.slice() ?? [];

    newTrucks.push({ referenceNumber: "" });

    setIsTruckListOpen(true);

    patch({ trucks: newTrucks });
  };

  return (
    <Frame
      title={t("shipments:shipmentDetails_poReference_title")}
      actions={
        showEditDetails ? (
          <Button variant="text" color="primary" onClick={() => (isEditing ? save() : startEditing())}>
            {isEditing
              ? t("shipments:shipmentDetails_save_buttonLabel")
              : t("shipments:shipmentDetails_editDetails_button")}
          </Button>
        ) : undefined
      }
    >
      <TruckTableRow label={t("quoteDetails:poReferenceDetails_shipment")}>
        {isEditing ? (
          <TextInput
            fullWidth
            value={patchedShipment.referenceNumber}
            onChange={(ev) => patch({ referenceNumber: ev.target.value })}
          />
        ) : (
          <>{patchedShipment.referenceNumber}</>
        )}
      </TruckTableRow>
      <TruckTableRow label={t("shipments:shipmentDetails_numberOfTrucks_label")}>
        {isEditing ? (
          <div className="flex gap-2 items-center w-full">
            <input
              type="text"
              value={patchedShipment.trucks?.length || 0}
              className="w-full text-center h-[42px] border border-border rounded-md"
              disabled
            />
            {patchedShipment.mode !== "LTL" && (
              <button
                type="button"
                className="w-[42px] h-[42px] shrink-0 border border-border hover:border-brandBlue hover:text-brandBlue rounded-md"
                onClick={addTruck}
              >
                +
              </button>
            )}
          </div>
        ) : (
          <>{patchedShipment.trucks?.length || 0}</>
        )}
      </TruckTableRow>
      {Boolean(patchedShipment.trucks?.length) && (
        <>
          <Collapse in={isTruckListOpen} timeout={1000 + 250 * ((patchedShipment.trucks?.length || 0) / 10)}>
            {patchedShipment?.trucks?.map((truck, index) => (
              <TruckSummaryView
                isEditing={isEditing}
                key={truck.id || `new-truck-${index}`}
                onChange={handleTruckChange(index)}
                shipment={patchedShipment}
                truck={truck}
                truckIndex={index}
                removeTruck={removeTruck}
              />
            ))}
          </Collapse>
          {(patchedShipment.trucks?.length ?? 0) > 1 && (
            <Button
              fullWidth
              style={{ height: "56px" }}
              color="primary"
              variant="text"
              onClick={() => setIsTruckListOpen((value) => !value)}
            >
              <Trans
                ns="quoteDetails"
                i18nKey="poReferenceDetails_viewTrucksButton"
                count={patchedShipment.trucks?.length || 0}
                values={{
                  state: isTruckListOpen
                    ? t("quoteDetails:poReferenceDetails_viewTrucksButton_close")
                    : t("quoteDetails:poReferenceDetails_viewTrucksButton_open"),
                }}
                components={[
                  <div className="mx-[0.5rem] text-[14px] text-white bg-brandBlue w-[24px] h-[24px] p-[4px] rounded-full inline-flex justify-center items-center"></div>,
                ]}
              />
              {isTruckListOpen ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </Button>
          )}
        </>
      )}
    </Frame>
  );
};

export default ShipmentDetailsTrucksView;
