import { VFC } from "react";

import { Button } from "@portex-pro/ui-components";
import { useDuplicateQuoteRequestMutation, useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import PortexDrawer from "components/PortexDrawer";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";

import {
  useSetIsDuplicatingQuoteRequest,
  useSetStopForDuplicateQuoteRequest,
} from "../../store/quoteRequestManagementSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import DuplicateQuoteRequestView from "../../views/quotes/DuplicateQuoteRequestView";

interface DuplicateQuoteRequestProps {
  onClose: () => void;
}

const DuplicateQuoteRequest = withAsync({
  useHook: (_props: DuplicateQuoteRequestProps) => {
    const { quoteRequestId } = useParams<{ quoteRequestId: string }>();
    return useGetShipperQuoteRequestQuery({ urlParams: { quoteRequestId } });
  },

  Component: ({ loadedData }) => {
    const quoteRequestManagementSlice = useRequestManagementSliceSelector((state) => state.quoteRequestManagementSlice);
    const setStopForDuplicateQuoteRequest = useSetStopForDuplicateQuoteRequest();

    return (
      <DuplicateQuoteRequestView
        stops={loadedData.data.data.quoteRequest.stops.map((stop, i, { length }) => {
          const patch = quoteRequestManagementSlice.stopsForDuplicateQuoteRequest[i];
          return (
            <DuplicateQuoteRequestView.Stop
              key={stop.id}
              mode={loadedData.data.data.quoteRequest.mode}
              stop={{
                ...stop,
                ...patch,
              }}
              stopIndex={i}
              stopsLength={length}
              onChange={(stop) => setStopForDuplicateQuoteRequest({ stop, stopIndex: i })}
            />
          );
        })}
      />
    );
  },
});

const Actions: VFC<Pick<DrawerProps, "onClose">> = ({ onClose }) => {
  const { t } = useTranslation(["common", "shipper"]);
  const { quoteRequestId } = useParams<{ quoteRequestId: string }>();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const quoteRequestManagementSlice = useRequestManagementSliceSelector((state) => state.quoteRequestManagementSlice);
  const setIsDuplicatingQuoteRequest = useSetIsDuplicatingQuoteRequest();
  const [duplicateQuoteRequest, duplicateQuoteRequestMutation] = useDuplicateQuoteRequestMutation();

  const handleDuplicate = async () => {
    try {
      const result = await duplicateQuoteRequest({
        urlParams: {
          quoteRequestId,
        },
        body: {
          stops: quoteRequestManagementSlice.stopsForDuplicateQuoteRequest,
        },
      }).unwrap();
      setIsDuplicatingQuoteRequest(false);
      history.push(`/shipper/quotes/${result.data.id}`);
    } catch (e) {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  return (
    <>
      <Button variant="outlined" onClick={onClose}>
        {t("common:cancel")}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={!quoteRequestManagementSlice.canDuplicateQuoteRequest}
        loading={duplicateQuoteRequestMutation.isLoading}
        onClick={handleDuplicate}
      >
        {t("shipper:duplicateQuoteRequest")}
      </Button>
    </>
  );
};

interface DrawerProps extends DuplicateQuoteRequestProps {
  isOpen: boolean;
  onClose: () => void;
}

const Drawer: VFC<DrawerProps> = (props) => {
  const { t } = useTranslation("shipper");
  const { isOpen, onClose, ...duplicateQuoteRequestProps } = props;

  return (
    <PortexDrawer
      width="60%"
      open={isOpen}
      onClose={onClose}
      headerTitle={t("duplicateQuoteRequest")}
      renderActions={(props) => <Actions {...props} />}
    >
      <DuplicateQuoteRequest onClose={onClose} {...duplicateQuoteRequestProps} />
    </PortexDrawer>
  );
};

type DuplicateQuoteRequest = typeof DuplicateQuoteRequest & { Drawer: typeof Drawer };

(DuplicateQuoteRequest as DuplicateQuoteRequest).Drawer = Drawer;

export default DuplicateQuoteRequest as DuplicateQuoteRequest;
