import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";

import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import DrayageReviewView from "../../views/quotes/drayage/DrayageReviewView";

const ReviewStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Review),
  Component: ({ loadedData: { quoteRequest } }) => {
    const saveAndChangeRoute = useSaveAndChangeRoute();
    return (
      <ProgressionButtons
        onBack={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Team })}
        onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Confirmation }, true)}
      >
        <DrayageReviewView quoteRequest={quoteRequest} />
      </ProgressionButtons>
    );
  },
});

export default ReviewStep;
