export enum TransportationMode {
  All = "ALL",
  Trucking = "TRUCKING",
  FTL = "FTL",
  LTL = "LTL",
  DRAYAGE = "DRAYAGE",
  INTERMODAL = "INTERMODAL",
  Ocean = "OCEAN",
  Air = "AIR",
}
