import { useContext, VFC } from "react";

import { ShipperQuoteRequest } from "api/rest/quote-requests";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import CargoGroupProvider, { CargoGroupContext } from "features/cargo-groups/CargoGroupProvider";

import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import DrayageShipmentDetailsView from "../../views/quotes/drayage/DrayageShipmentDetailsView";

const BaseShipmentStep: VFC<{ quoteRequest: ShipperQuoteRequest }> = ({ quoteRequest }) => {
  const changeQuoteRequest = quoteRequestActions.useChangeQuoteRequest();
  const saveAndChangeRoute = useSaveAndChangeRoute();
  const { is_hazardous, insurance_required, include_customs_clearance } = useRequestManagementSliceSelector(
    (store) => store.quoteRequestSlice.quoteRequest ?? ({} as ShipperQuoteRequest)
  );
  const { isValid: isCargoGroupsValid } = useContext(CargoGroupContext);

  const isValid =
    is_hazardous !== undefined &&
    insurance_required !== undefined &&
    include_customs_clearance !== undefined &&
    isCargoGroupsValid;

  return (
    <ProgressionButtons
      onBack={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Routing })}
      nextProps={{ disabled: !isValid }}
      onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Partners })}
    >
      <DrayageShipmentDetailsView quoteRequest={quoteRequest} onChange={(changes) => changeQuoteRequest({ changes })} />
    </ProgressionButtons>
  );
};

const ShipmentStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Shipment),
  Component: ({ loadedData: { quoteRequest } }) => {
    return (
      <CargoGroupProvider cargoGroups={quoteRequest.cargo_groups} quote_request_id={quoteRequest.id}>
        <BaseShipmentStep quoteRequest={quoteRequest} />
      </CargoGroupProvider>
    );
  },
});

export default ShipmentStep;
