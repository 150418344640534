import { CargoGroup, NormalizedCargoGroup } from "../types";

export const normalizeCargoGroups = (cargoGroups: CargoGroup[]): NormalizedCargoGroup[] => {
  const cargoGroupsToNormalize = [...cargoGroups];
  const normalizedCargoGroups: NormalizedCargoGroup[] = [];

  while (cargoGroupsToNormalize.length > 0) {
    const nextCargoGroup = cargoGroupsToNormalize.shift();

    if (!nextCargoGroup) {
      continue;
    }

    nextCargoGroup.handling_units?.forEach((handlingUnit) => cargoGroupsToNormalize.push(handlingUnit));
    nextCargoGroup.packing_units?.forEach((packingUnit) => cargoGroupsToNormalize.push(packingUnit));

    const normalizedCargoGroup: NormalizedCargoGroup = {
      ...nextCargoGroup,
      handling_units: nextCargoGroup?.handling_units?.map((handlingUnit) => handlingUnit.id),
      packing_units: nextCargoGroup?.packing_units?.map((packingUnits) => packingUnits.id),
    };

    Object.entries(normalizedCargoGroup).forEach(([key, value]) => {
      if (value === null) {
        // @ts-expect-error ts will complain about this assignment
        normalizedCargoGroup[key] = undefined;
      }
    });

    normalizedCargoGroups.push(normalizedCargoGroup);
  }

  return normalizedCargoGroups;
};
