import { EM_DASH } from "constants/index";

import { VFC } from "react";

import Text from "components/Text";
import { useTranslation } from "react-i18next";

import { CargoGroup } from "../api/types";
import { getCargoGroupEquipmentString } from "../utils/getCargoGroupEquipmentString";

interface ContainerCargoGroupsOverviewViewProps {
  cargoGroups: CargoGroup[];
}

const ContainerCargoGroupsOverviewView: VFC<ContainerCargoGroupsOverviewViewProps> = (props) => {
  const { cargoGroups } = props;
  const { t } = useTranslation(["common", "shipper", "dispatchRequest"]);

  return (
    <div className="w-full grid grid-cols-4">
      <div>
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("common:container")}
        </Text>
      </div>
      <div>
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("common:weight")}
        </Text>
      </div>
      <div>
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("common:volume")}
        </Text>
      </div>
      <div>
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("common:commodities")}
        </Text>
      </div>

      {cargoGroups.map((cargoGroup) => {
        const containerString = [getCargoGroupEquipmentString(cargoGroup, t)];

        return (
          <>
            <div>
              <Text size="medium" weight="bold">
                {containerString}
              </Text>
            </div>
            <div>
              <Text size="medium">
                {cargoGroup.weight_per_count
                  ? t("dispatchRequest:totalWeight", { context: "KG", value: cargoGroup.weight_per_count })
                  : EM_DASH}
              </Text>
            </div>
            <div>
              {cargoGroup.volume_per_count ? t("common:volume_cbm", { value: cargoGroup.volume_per_count }) : EM_DASH}
            </div>
            <div>{cargoGroup.commodities && cargoGroup.commodities.length ? cargoGroup.commodities : EM_DASH}</div>
          </>
        );
      })}
    </div>
  );
};

export default ContainerCargoGroupsOverviewView;
