import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse, UrlParam } from "api/rest/types/responses";

import { BrokerQuoteRequest } from "./types";

type UrlParams = {
  quoteRequestGuid: UrlParam;
};

type QueryArgs = {
  urlParams: UrlParams;
};

type ResponseType = ApiResponse<{ quoteRequest: BrokerQuoteRequest }>;

module.hot?.accept();

export const getBrokerQuoteRequestApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["QUOTE_REQUESTS"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      getBrokerQuoteRequest: builder.query<ResponseType, QueryArgs>({
        query: ({ urlParams }) => ({
          method: "GET",
          url: `broker/quote-requests/${urlParams.quoteRequestGuid}`,
        }),
        providesTags: ["QUOTE_REQUESTS"],
      }),
    }),
  });

export const { useGetBrokerQuoteRequestQuery } = getBrokerQuoteRequestApi;
