import { TFunction } from "react-i18next";

import { CargoGroup, NormalizedCargoGroup } from "../api/types";

export const getCargoGroupEquipmentString = (
  cargoGroup: CargoGroup | NormalizedCargoGroup,
  t: TFunction<["common"]>
): string => {
  return [
    cargoGroup.count,
    [
      cargoGroup.equipment_size ? t(`common:trailerSizes.${cargoGroup.equipment_size}`) : "",
      cargoGroup.equipment_type ? t(`common:trailerTypes.${cargoGroup.equipment_type}`) : "",
    ]
      .filter(Boolean)
      .join(" "),
  ]
    .filter(Boolean)
    .join(" x ");
};
