import { configureSlices } from "utils/store/configureSlices";

import brokerQuoteSubmissionSlice from "./brokerQuoteSubmissionSlice";

const slicesMap = {
  brokerQuoteSubmissionSlice,
};

export const { useSlices: useBrokerQuoteSubmissionSlices, useSliceSelector: useBrokerQuoteSubmissionSliceSelector } =
  configureSlices(slicesMap);
