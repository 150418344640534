import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ShipperDispatchRequest } from "api/rest/dispatches";
import ComingSoon from "components/ComingSoon";
import Loading from "components/Loading";
import NotificationBadge from "components/NotificationBadge";
import useLDFlag from "hooks/useLDFlag";
import { Redirect } from "react-router-dom";

import { OverflowMenuOption } from "../../../../components/OverflowMenuView";
import { Shipment, ShipmentQuoteDetails, ShipmentTabId } from "../types/domain";
import GenerateBOLContainer from "./generate-bol/GenerateBOLContainer";
import ScorecardTabContainer from "./scorecard/containers/ScorecardTabContainer";
import ShipmentActivityTab from "./ShipmentActivityTab";
import ShipmentDetailsHeader from "./ShipmentDetailsHeader";
import ShipmentDetailsTabContainer from "./ShipmentDetailsTabContainer";
import ShipmentDetailsTabsContainer, { ShipmentTab } from "./ShipmentDetailsTabsContainer";
import ShipmentDocumentsTabContainer from "./ShipmentDocumentsTabContainer";
import ShipmentQuotesTabView from "./ShipmentQuotesTabView";

interface ShipmentDetailsViewProps {
  shipment?: Shipment;
  quotes?: ShipmentQuoteDetails[];
  activeTabId: ShipmentTabId;
  shipmentActions: OverflowMenuOption[];
  dispatchRequest?: ShipperDispatchRequest;
}

export const ShipmentDetails: VFC<ShipmentDetailsViewProps> = ({
  activeTabId,
  shipment,
  shipmentActions,
  quotes,
  dispatchRequest,
}) => {
  const rolloutLoadStatuses = useLDFlag("rolloutLoadStatuses");
  const rolloutBrokerScorecards = useLDFlag("rolloutBrokerScorecards");

  if (!shipment) {
    return <Loading height="100%" />;
  }

  const shipmentTabs: ShipmentTab[] = [
    {
      id: "activity",
      label: "shipments:activityTab_label",
      path: "activity",
    },
    {
      id: "details",
      label: "shipments:detailsTab_label",
      path: "details",
    },
    {
      id: "documents",
      badge: shipment.fileIds.length ? String(shipment.fileIds.length) : "",
      label: "shipments:documentsTab_label",
      path: "documents",
      labelComponent: ({ children }) => (
        <NotificationBadge identifiers={[`bolGenerationComplete:${shipment.id}`]} position="topRight" size="small">
          {children}
        </NotificationBadge>
      ),
    },
    {
      id: "scorecard",
      label: "scorecard:shipmentsTabLabel",
      path: "scorecard",
    },
    {
      id: "quotes",
      badge: quotes && quotes.length ? String(quotes.length) : "",
      label: "shipments:quotesTab_label",
      path: "quotes",
      sourceType: "quote",
    },
    {
      id: "bol",
      label: "shipments:generateBOLTab_label",
      path: "bol",
    },
    {
      id: "invoices",
      label: "shipments:invoicesTab_label",
      path: "invoices",
    },
  ];

  return (
    <>
      <ShipmentDetailsHeader shipment={shipment} shipmentActions={shipmentActions} />
      <ShipmentDetailsTabsContainer activeTabId={activeTabId} shipment={shipment} tabs={shipmentTabs} />
      <Box flexGrow={1} style={{ overflowY: "auto" }}>
        {activeTabId === "activity" && <ShipmentActivityTab shipment={shipment} dispatchRequest={dispatchRequest} />}
        {activeTabId === "details" && <ShipmentDetailsTabContainer shipment={shipment} />}
        {activeTabId === "documents" && <ShipmentDocumentsTabContainer shipment={shipment} />}
        {activeTabId === "scorecard" &&
          (!!rolloutLoadStatuses && !!rolloutBrokerScorecards ? (
            <ScorecardTabContainer shipment={shipment} />
          ) : (
            <ComingSoon />
          ))}
        {shipment.sourceType === "quote" && activeTabId === "quotes" && (
          <ShipmentQuotesTabView
            quotes={quotes || []}
            bookedQuoteId={shipment.bookedQuote.id}
            quoteRequestId={shipment.quoteRequestId}
          />
        )}
        {activeTabId === "invoices" && <ComingSoon />}
        {!shipmentTabs.find((tab) => tab.id === activeTabId) && (
          <Redirect to={`/shipper/shipments/${shipment.id}/activity`} />
        )}
        {activeTabId === "bol" && <GenerateBOLContainer shipment={shipment} />}
      </Box>
    </>
  );
};

export default ShipmentDetails;
