import { baseRestApi } from "api/rest/baseRestApi";
import { ApiResponse } from "api/rest/types/responses";
import { ModeQuotes } from "types/Mode";

import { ShipperQuoteRequest } from "./types";

type BodyParams = {
  mode: ModeQuotes;
};

type QueryArgs = {
  body: BodyParams;
};

type ResponseType = ApiResponse<{ quoteRequest: ShipperQuoteRequest }>;

module.hot?.accept();

export const createQuoteRequestApi = baseRestApi.injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",
  endpoints: (builder) => ({
    createQuoteRequest: builder.mutation<ResponseType, QueryArgs>({
      query: ({ body }) => ({
        method: "POST",
        url: `shipper/quote-requests`,
        body,
      }),
    }),
  }),
});

export const { useCreateQuoteRequestMutation } = createQuoteRequestApi;
