import { VFC } from "react";

import { FormControl, InputLabel, TextInput } from "@portex-pro/ui-components";
import ClearButtonView from "components/buttons/ClearButtonView";
import { FormData } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

interface BolCarrierViewProps {
  bolNumber?: string;
  carrier?: string;
  partner?: string;

  onChange: (data: Partial<Pick<FormData, "bolNumber" | "carrier" | "partner">>) => void;
}

const BolCarrierView: VFC<BolCarrierViewProps> = (props) => {
  const { onChange, bolNumber, carrier, partner } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="p-5 flex flex-col space-y-2.5">
        <FormControl fullWidth>
          <InputLabel>{t("bol#")}</InputLabel>
          <TextInput
            value={bolNumber}
            onChange={(event) => onChange({ bolNumber: event.target.value })}
            endIcon={bolNumber ? <ClearButtonView onClick={() => onChange({ bolNumber: "" })} /> : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("carrier")}</InputLabel>
          <TextInput
            value={carrier}
            onChange={(event) => onChange({ carrier: event.target.value })}
            endIcon={carrier ? <ClearButtonView onClick={() => onChange({ carrier: "" })} /> : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("partner")}</InputLabel>
          <TextInput
            value={partner}
            onChange={(event) => onChange({ partner: event.target.value })}
            endIcon={partner ? <ClearButtonView onClick={() => onChange({ partner: "" })} /> : undefined}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default BolCarrierView;
