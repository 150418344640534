// import ChatContainer from "components/chat/ChatContainer";
import ChatContainer from "components/chat/ChatContainer";
import withAsync from "components/withAsync";

import useLoadQuoteRequestAndQuote from "../../hooks/quotes/useLoadQuoteRequestAndQuote";
import PageLayout from "../../views/PageLayout";
import BookQuoteContainer from "./BookQuoteContainer";
import QuoteDetailsContainer from "./QuoteDetailsContainer";
import QuoteManagementAppBar from "./QuoteManagementAppBar";
import QuoteRequestHeader from "./QuoteRequestHeader";
import QuoteSummaryContainer from "./QuoteSummaryContainer";
import ReferencesContainer from "./ReferencesContainer";

const QuoteManagementPage = withAsync({
  useHook: useLoadQuoteRequestAndQuote,
  Component: ({ loadedData }) => {
    const conversationId = loadedData.quote.conversation_id;

    return (
      <PageLayout
        header={[<QuoteManagementAppBar />, <QuoteRequestHeader />]}
        leftContent={[<QuoteSummaryContainer />, <ReferencesContainer />, <QuoteDetailsContainer />]}
        rightContent={[
          <BookQuoteContainer />,
          <ChatContainer conversationId={conversationId?.toString()} maxFiles={4} />,
        ]}
      />
    );
  },
});

export default QuoteManagementPage;
