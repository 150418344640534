import { EM_DASH } from "constants/index";

import formatAddress from "app/pages/shipments/utils/formatAddress";
import formatContact from "app/pages/shipments/utils/formatContact";
import LoadTypeChip from "components/LoadTypeChip";
import Text from "components/Text";
import withAsync from "components/withAsync";
import { getCargoGroupEquipmentString } from "features/cargo-groups/utils/getCargoGroupEquipmentString";
import ContainerCargoGroupsOverviewView from "features/cargo-groups/views/ContainerCargoGroupsOverviewView";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { formatUSD } from "utils/formatCurrency";

import useLoadQuoteRequestAndQuote from "../../hooks/quotes/useLoadQuoteRequestAndQuote";

const QuoteDetailsContainer = withAsync({
  useHook: useLoadQuoteRequestAndQuote,
  Component: ({ loadedData: { quote, quoteRequest } }) => {
    const { t } = useTranslation(["shipper", "common", "shipments", "quoteDetails"]);

    return (
      <div className="w-full pb-10">
        {/* card start */}
        <div className="border rounded w-full bg-white">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:quoteDetails")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:totalAmount")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{formatUSD(quote.total_amount)}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quote.booking_notes ?? EM_DASH}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:notes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quote.notes ?? EM_DASH}</Text>
            </div>
          </div>
        </div>

        {/* card start */}
        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:requestDetails")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:mode")}
              </Text>
            </div>
            <div className="w-full">
              <LoadTypeChip mode={quoteRequest.mode} />
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:equipmentType")}
              </Text>
            </div>
            <div className="w-full">
              {quoteRequest.cargo_groups.map((cargoGroup) => (
                <>
                  {getCargoGroupEquipmentString(cargoGroup, t)} <br />
                </>
              ))}
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:incoterms")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.incoterms ?? EM_DASH}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:hazardousCommodities")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.is_hazardous ? t("common:yes") : t("common:no")}</Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:customs")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.include_customs_clearance ? t("common:required") : t("common:notRequired")}
              </Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:insurance")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.insurance_required ? t("common:required") : t("common:notRequired")}
              </Text>
            </div>
          </div>
          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:unloadingDetails")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.unloading_details
                  ? t(`common:unloadingDetails_map.${quoteRequest.unloading_details}`)
                  : EM_DASH}
              </Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:pickup")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:location")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].address
                  ? formatAddress.fromSnakeCase(
                      // @ts-expect-error We can circle around to fix these type issues
                      Object.entries(quoteRequest.stops[0].address ?? {}).reduce((add, [key, value]) => {
                        // @ts-expect-error same as above
                        add[key] = value ?? null;
                        return add;
                      }, {}),
                      "long"
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:addresses.manualName")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[0].address?.name ?? EM_DASH}</Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipments:shipmentDetails_dateAndTime_label")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].start
                  ? DateTime.fromISO(quoteRequest.stops[0].start).toLocaleString(DateTime.DATETIME_FULL)
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:operationsContact")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].address?.address_contacts[0]
                  ? // @ts-expect-error this type error is fine for now
                    formatContact(quoteRequest.stops[0].address?.address_contacts[0])
                  : EM_DASH}
                <br />
                {quoteRequest.stops[0].address?.address_contacts[0]?.phone_number}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[0].address?.booking_notes || EM_DASH}</Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:delivery")}
            </Text>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:location")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].address
                  ? formatAddress.fromSnakeCase(
                      // @ts-expect-error We can circle around to fix these type issues
                      Object.entries(quoteRequest.stops[quoteRequest.stops.length - 1].address ?? {}).reduce(
                        (add, [key, value]) => {
                          // @ts-expect-error same as above
                          add[key] = value ?? null;
                          return add;
                        },
                        {}
                      ),
                      "long"
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:addresses.manualName")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">{quoteRequest.stops[quoteRequest.stops.length - 1].address?.name ?? EM_DASH}</Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipments:shipmentDetails_dateAndTime_label")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].start
                  ? // @ts-expect-error this type error is fine for now
                    DateTime.fromISO(quoteRequest.stops[quoteRequest.stops.length - 1].start).toLocaleString(
                      DateTime.DATETIME_FULL
                    )
                  : EM_DASH}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("shipper:operationsContact")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[0].address?.address_contacts[0]
                  ? formatContact(
                      // @ts-expect-error this type error is fine for now
                      quoteRequest.stops[quoteRequest.stops.length - 1].address?.address_contacts[
                        quoteRequest.stops.length - 1
                      ]
                    )
                  : EM_DASH}
                <br />
                {quoteRequest.stops[quoteRequest.stops.length - 1].address?.address_contacts[0]?.phone_number}
              </Text>
            </div>
          </div>

          {/* card row */}
          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("common:bookingNotes")}
              </Text>
            </div>
            <div className="w-full">
              <Text size="medium">
                {quoteRequest.stops[quoteRequest.stops.length - 1].address?.booking_notes || EM_DASH}
              </Text>
            </div>
          </div>
        </div>

        <div className="border rounded w-full bg-white mt-4">
          {/* card head */}
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("common:containers")}
            </Text>
          </div>

          {/* card row */}
          <div className="w-full flex flex-row items-center py-3 px-4">
            <ContainerCargoGroupsOverviewView cargoGroups={quoteRequest.cargo_groups} />
          </div>
        </div>
      </div>
    );
  },
});

export default QuoteDetailsContainer;
