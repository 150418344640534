import SuccessConfirmation from "app/pages/dispatch-request/views/SuccessConfirmation";
import withAsync from "components/withAsync";
import { useHistory } from "react-router-dom";

import useQuoteRequestStep from "../../hooks/dispatches/useQuoteRequestStep";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const ConfirmationStep = withAsync({
  useHook: () => {
    useQuoteRequestStep(QuoteRequestPageSteps.Confirmation);
    return {
      isLoading: false,
    };
  },
  Component: () => {
    const history = useHistory();

    const onClick = () => {
      history.push("/shipper/requests");
    };

    return (
      <div className="h-full">
        <SuccessConfirmation onClick={onClick} />
      </div>
    );
  },
});

export default ConfirmationStep;
