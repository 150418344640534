import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { baseRestApi } from "api/rest/baseRestApi";
import enhanceWithPagination from "utils/enhanceWithPagination";
import paramsToTuples from "utils/paramsToTuples";

import {
  mapFromPartialShipment,
  mapToUpdateShipmentAddressPayload,
  mapFromShipmentState,
  transformListShipmentsResponse,
  transformShipmentDetailsResponse,
  transformShipmentQuotesResponse,
  transformAccessorialListResponse,
} from "./mappers";
import {
  ListAccessorialOptionsApi,
  ListShipmentQuotesApi,
  ListShipmentsApi,
  RawAddress,
  ShipmentDetailsApi,
  UpdateShipmentApi,
} from "./types";

module.hot?.accept();

const shipmentsApi = baseRestApi
  .enhanceEndpoints({ addTagTypes: ["shipment", "ChatConversation", "LOAD_STATUS_UPDATES"] })
  .injectEndpoints({
    overrideExisting: module.hot?.status() === "apply",
    endpoints: (builder) => ({
      getShipmentList: builder.query<ListShipmentsApi["hookReturnValue"], ListShipmentsApi["queryArgs"]>({
        query: ({ queryParams }) => {
          const mappedParams = structuredClone(queryParams);

          if (!mappedParams.cursor) {
            delete mappedParams.cursor;
          }

          if (mappedParams.status) {
            mappedParams.status = mappedParams.status.map(mapFromShipmentState);
          }

          return {
            url: "shipper/shipments",
            method: "GET",
            params: paramsToTuples(mappedParams),
          };
        },
        transformResponse: transformListShipmentsResponse,
        providesTags: (result) =>
          !!result && !!result.data
            ? [...result.data.map(({ id }) => ({ type: "shipment" as const, id })), "shipment"]
            : ["shipment"],
      }),

      getShipmentDetails: builder.query<ShipmentDetailsApi["hookReturnValue"], ShipmentDetailsApi["queryArgs"]>({
        query: ({ urlParams: { shipmentId }, queryParams }) => ({
          url: `shipper/shipments/${shipmentId}`,
          method: "GET",
          params: queryParams,
        }),
        transformResponse: transformShipmentDetailsResponse,
        providesTags: (result) =>
          !!result ? [{ type: "shipment" as const, id: result.id }, "shipment"] : ["shipment"],
      }),

      getAccessorialOptions: builder.query<ListAccessorialOptionsApi["hookReturnValue"], {}>({
        query: () => ({ url: "shipper/accessorials", method: "GET" }),
        transformResponse: transformAccessorialListResponse,
      }),

      updateShipment: builder.mutation<UpdateShipmentApi["hookReturnValue"], UpdateShipmentApi["queryArgs"]>({
        async queryFn(arg, _api, _extraOptions, baseQuery) {
          const body: typeof arg.body = structuredClone(arg.body);
          const stops = body.stops || [];

          for (const stop of stops) {
            const address = stop?.address;

            if (!stop || !address || !stop.address?.isDirty) {
              continue;
            }

            if (!address.id) {
              const response = await baseQuery({
                url: `shipper/addresses`,
                method: "POST",
                body: mapToUpdateShipmentAddressPayload(address),
              });

              if (response.error) {
                return { error: response.error as FetchBaseQueryError };
              }

              stop.address.id = (response.data as { data: { address: RawAddress } }).data.address.id;
            }
          }

          const response = await baseQuery({
            url: `shipper/shipments/${arg.urlParams.shipmentId}`,
            method: "PUT",
            body: {
              ...mapFromPartialShipment(body),
              recreate: body.recreate,
            },
          });

          if (response.error) {
            return { error: response.error as FetchBaseQueryError };
          }

          const responseData = response.data as unknown as UpdateShipmentApi["response"];
          return {
            data: {
              shipment: transformShipmentDetailsResponse(responseData),
              recreate: responseData.data.recreate,
              isEmailSent: responseData.data.isEmailSent,
            },
          };
        },
        onQueryStarted({ urlParams: { shipmentId }, ...patch }, { dispatch, queryFulfilled }) {
          const patchResult = dispatch(
            shipmentsApi.util.updateQueryData("getShipmentDetails", { urlParams: { shipmentId } }, (draft) => {
              Object.assign(draft, patch);
            })
          );
          queryFulfilled.catch(patchResult.undo);
        },
        invalidatesTags: ["shipment", "ChatConversation", "LOAD_STATUS_UPDATES"],
      }),

      getShipmentQuotes: builder.query<ListShipmentQuotesApi["hookReturnValue"], ListShipmentQuotesApi["queryArgs"]>({
        query: ({ urlParams: { shipmentId } }) => ({
          url: `shipper/shipments/${shipmentId}/quotes`,
          method: "GET",
        }),
        transformResponse: transformShipmentQuotesResponse,
      }),
    }),
  });

export default shipmentsApi;

export const {
  useGetShipmentListQuery,
  useGetShipmentDetailsQuery,
  useGetShipmentQuotesQuery,
  useUpdateShipmentMutation,
  useGetAccessorialOptionsQuery,
} = enhanceWithPagination(shipmentsApi, "getShipmentList", { take: 25 });
