import { useState, VFC } from "react";

import { QuoteUrgency, TextInput } from "@portex-pro/ui-components";
import { ShipperQuoteRequest } from "api/rest/quote-requests";
import classNames from "classnames";
import Text from "components/Text";
import YesNoRadio from "components/YesNoRadio";
import ContainerCargoGroups from "features/cargo-groups/ContainerCargoGroups";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";

interface DrayageShipmentDetailsViewProps {
  quoteRequest: ShipperQuoteRequest;
  onChange: (changes: Partial<ShipperQuoteRequest>) => void;
}

const stackedStyles = "[&_>_.stacked]:flex [&_>_.stacked]:flex-col [&_>_.stacked]:space-y-3";

const DrayageShipmentDetailsView: VFC<DrayageShipmentDetailsViewProps> = (props) => {
  const { quoteRequest, onChange } = props;
  const [selectedUrgency, setSelectedUrgency] = useState<string | undefined>();
  const { t } = useTranslation(["requestManagement"]);

  return (
    <div className="w-full h-full">
      <div className={classNames("w-full grid grid-cols-2 gap-x-4 gap-y-8", stackedStyles)}>
        <div className="stacked col-span-2 items-center p-10">
          <Text size="x-large" weight="bold">
            {t("requestManagement:shipmentDetails")}
          </Text>
          <Text size="medium">{t("requestManagement:shipmentDetails_description")}</Text>
        </div>

        <div className={classNames("grid grid-cols-subgrid col-span-2 bg-grey-50 px-4 py-6", stackedStyles)}>
          <div className="col-span-2 mb-4">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("requestManagement:referenceNoAndUrgency")}
            </Text>
          </div>

          <div className="stacked">
            <Text size="medium" weight="bold">
              {t("requestManagement:poReferenceNumberOptional")}
            </Text>
            <TextInput
              placeholder={t("requestManagement:poReferenceNumber_placeholder")}
              value={quoteRequest.reference_number}
              onChange={(event) => onChange({ reference_number: event.target.value })}
            />
          </div>

          <div className="stacked">
            <Text size="medium" weight="bold">
              {t("requestManagement:urgency")}
            </Text>
            {/* @todo establish what quote urgency values we should use */}
            <QuoteUrgency
              data={[
                { id: "1", label: t("requestManagement:hours", { count: 1 }) },
                { id: "2", label: t("requestManagement:hours", { count: 2 }) },
                { id: "3", label: t("requestManagement:hours", { count: 3 }) },
                { id: "6", label: t("requestManagement:hours", { count: 6 }) },
                { id: "12", label: t("requestManagement:hours", { count: 12 }) },
              ]}
              value={selectedUrgency}
              style={{ height: "100%" }}
              getLabel={selectedUrgency ? (id) => t("requestManagement:hours", { count: Number(id) }) : undefined}
              onClear={() => {
                onChange({ deadline_respond_at: null });
                setSelectedUrgency(undefined);
              }}
              onChange={(id) => {
                onChange({
                  deadline_respond_at: DateTime.now()
                    .plus({ hours: Number(id) })
                    .toISO(),
                });
                setSelectedUrgency(id);
              }}
            />
          </div>
        </div>

        <div className={classNames("grid grid-cols-subgrid col-span-2", stackedStyles)}>
          <div className="col-span-2 px-4">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("requestManagement:containers")}
            </Text>
          </div>

          <div className="col-span-2">
            <ContainerCargoGroups />
          </div>
        </div>

        <div className={classNames("grid grid-cols-subgrid col-span-2 gap-y-4 bg-grey-50 px-4 py-6", stackedStyles)}>
          <div className="col-span-2">
            <Text
              size="medium"
              weight="bold"
              typographyProps={{ color: "textSecondary", style: { textTransform: "uppercase" } }}
            >
              {t("requestManagement:commodities")}
            </Text>
          </div>

          <div className="stacked">
            <Text size="medium" weight="bold" required>
              {t("requestManagement:doesThisContainerHazardousGoods")}
            </Text>
            <YesNoRadio value={quoteRequest.is_hazardous} onChange={(value) => onChange({ is_hazardous: value })} />
          </div>

          <div />

          {quoteRequest.is_hazardous && (
            <div className="col-span-2 stacked">
              <Text size="medium" weight="bold">
                {t("requestManagement:describeTheHazardousGoods")}
              </Text>
              <TextInput
                value={quoteRequest.hazardous_goods_details}
                multiline
                rows={3}
                onChange={(event) => onChange({ hazardous_goods_details: event.target.value })}
              />
            </div>
          )}

          <div className="stacked">
            <Text size="medium" weight="bold" required>
              {t("requestManagement:doYouNeedCargoInsurance")}
            </Text>
            <YesNoRadio
              value={quoteRequest.insurance_required}
              onChange={(value) => onChange({ insurance_required: value })}
            />
          </div>

          <div className="stacked">
            <Text size="medium" weight="bold" required>
              {t("requestManagement:shouldCustomsClearanceBeIncluded")}
            </Text>
            <YesNoRadio
              value={quoteRequest.include_customs_clearance}
              onChange={(value) => onChange({ include_customs_clearance: value })}
            />
          </div>
        </div>

        <div className={classNames("grid grid-cols-subgrid col-span-2 px-4 pb-6", stackedStyles)}>
          <div className="stacked col-span-2">
            <Text size="medium" weight="bold">
              {t("requestManagement:additionalNotes")}
            </Text>
            <TextInput
              value={quoteRequest.note}
              placeholder={t("requestManagement:additionalNotes_shipmentDetailsPlaceholder")}
              onChange={(event) => onChange({ note: event.target.value })}
              multiline
              rows={3}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrayageShipmentDetailsView;
