import { useEffect } from "react";

import { Container, Paper } from "@portex-pro/ui-components";
import { BrokerQuoteResponse } from "api/rest/quote-requests";
import { useGetBrokerQuoteRequestQuery } from "api/rest/quote-requests/getBrokerQuoteRequest";
import { useSubmitBrokerQuoteMutation } from "api/rest/quote-requests/submitBrokerQuote";
import NotFound404 from "components/errors/NotFound404";
import HtmlTitle from "components/HtmlTitle";
import Loading from "components/Loading";
import PortexAppBar from "components/PortexAppBar";
import withAsync from "components/withAsync";
import { useUserContext } from "hooks/useUserContext";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Sentry } from "sentry";
import { validateEmail } from "utils/validateEmail";

import SubmitDeadlineAlertView from "../dispatch-submit/views/SubmitDeadlineAlertView";
import { useSetQuote } from "./store/brokerQuoteSubmissionSlice";
import {
  useBrokerQuoteSubmissionSlices,
  useBrokerQuoteSubmissionSliceSelector,
} from "./store/brokerQuoteSubmissionStore";
import DrayageSummaryView from "./views/DrayageSummaryView";
import QuoteDetailsView from "./views/QuoteDetailsView";

const BrokerQuoteSubmissionPage = withAsync({
  useHook: () => {
    const params = useParams<{ quoteRequestGuid: string }>();
    useBrokerQuoteSubmissionSlices();
    const setQuote = useSetQuote();

    const { user } = useUserContext();

    useEffect(() => {
      if (user?.email) {
        setQuote({ submitterEmail: user.email });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return useGetBrokerQuoteRequestQuery({
      urlParams: {
        quoteRequestGuid: params.quoteRequestGuid,
      },
    });
  },
  useHandleError: () => {
    const { t } = useTranslation("broker");
    const { enqueueSnackbar } = useSnackbar();

    return (error) => {
      if (error?.status === 403) {
        enqueueSnackbar(t("quoteSubmissionPage_message"), { variant: "warning", preventDuplicate: true });
        return;
      }

      return Sentry.captureException(error);
    };
  },
  LoadingComponent: <Loading showPortexLogo />,
  Component: ({ loadedData }) => {
    const quoteRequest = loadedData?.data?.data?.quoteRequest;
    const quote = useBrokerQuoteSubmissionSliceSelector((state) => state.brokerQuoteSubmissionSlice.quote);
    const [submitQuote, { isLoading }] = useSubmitBrokerQuoteMutation();
    const onChange = useSetQuote();
    const { t } = useTranslation(["broker", "common"]);
    const { enqueueSnackbar } = useSnackbar();

    const isValid =
      !!quote.totalAmount &&
      !!quote.validUntil &&
      !!quote.minTransitTime &&
      !!quote.maxTransitTime &&
      !!quote.submitterEmail &&
      validateEmail(quote.submitterEmail);

    const onSubmitQuote = async () => {
      if (!quoteRequest || !quoteRequest.guid) {
        return;
      }

      try {
        await submitQuote({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: quote as BrokerQuoteResponse,
        }).unwrap();
        enqueueSnackbar(t("broker:quoteSubmission.successText"), { variant: "success" });
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
      }
    };

    if (loadedData.isError || !quoteRequest) {
      return <NotFound404 showAppBar useMarketingUrl />;
    }

    return (
      <>
        <HtmlTitle title={`${t("broker:submitQuoteForm.title")} ${quoteRequest.shipper_name}`} />
        <PortexAppBar shipperName={quoteRequest.shipper_name} useMarketingUrl />

        <Container maxWidth="lg" className="py-5 space-y-5">
          {quoteRequest.deadline_respond_at && (
            <SubmitDeadlineAlertView deadlineRespondAt={quoteRequest.deadline_respond_at} />
          )}
          <Paper className="Por-outlined-light" elevation={8}>
            <DrayageSummaryView quoteRequest={quoteRequest} />
            <QuoteDetailsView
              quote={quote}
              onChange={(changes) => onChange(changes)}
              onSubmit={onSubmitQuote}
              isLoading={isLoading}
              isValid={isValid}
            />
          </Paper>
        </Container>
      </>
    );
  },
});

export default BrokerQuoteSubmissionPage;
