import { useState, VFC } from "react";

import { ChatBubbleOutlineOutlined, Delete, Email } from "@material-ui/icons";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextInput,
} from "@portex-pro/ui-components";
import PortexDialog, { PortexDialogProps } from "components/PortexDialog";
import { useTranslation } from "react-i18next";
import { validateEmail } from "utils/validateEmail";

type ShareDocsDialogViewProps = {
  dialogProps: PortexDialogProps;
  documentsSelectedCount: number;
  showShareViaChat?: boolean;
  showShareViaEmail?: boolean;
  onClickShareInChat?: () => void;
  emails: string[];
  onAddEmail?: (value: string) => void;
  onRemoveEmail?: (value: string) => void;
  onClickSendEmail?: () => void;

  isLoading?: boolean;
};

const ShareDocsDialogView: VFC<ShareDocsDialogViewProps> = ({
  dialogProps,
  documentsSelectedCount,
  showShareViaChat = false,
  showShareViaEmail = false,
  onClickShareInChat,
  emails,
  onAddEmail,
  onRemoveEmail,
  onClickSendEmail,

  isLoading = false,
}) => {
  const { t } = useTranslation("shipments");
  const [email, setEmail] = useState<string>("");

  const handleAddEmail = () => {
    onAddEmail?.(email);
    setEmail("");
  };

  return (
    <PortexDialog
      title={t("documents_share_dialog_title", { count: documentsSelectedCount })}
      hideDialogActions
      {...dialogProps}
    >
      <div className="flex flex-col gap-2 p-4 pt-6">
        {!!showShareViaChat && (
          <div className="flex justify-between items-center">
            <span>{t("documents_share_in_chat_instructions")}</span>

            <Button
              startIcon={<ChatBubbleOutlineOutlined />}
              color="primary"
              variant="contained"
              style={{ paddingLeft: 16, paddingRight: 16 }}
              onClick={onClickShareInChat}
              loading={isLoading}
            >
              {t("documents_share_in_chat_button")}
            </Button>
          </div>
        )}

        {!!showShareViaChat && !!showShareViaEmail && (
          <div className="py-4 flex items-center justify-center w-full">
            <div className="flex-1 border-t border-gray-300"></div>
            <span className="mx-4 text-gray-500">{t("documents_share_or")}</span>
            <div className="flex-1 border-t border-gray-300"></div>
          </div>
        )}

        {!!showShareViaEmail && (
          <div className="flex flex-col gap-6">
            <div className="w-full">{t("documents_share_send_email_instructions")}</div>

            <div className="flex justify-between items-end space-x-5">
              <TextInput
                value={email}
                highlight={!!email && !validateEmail(email)}
                label={t("documents_share_send_email_label")}
                placeholder={t("documents_share_send_email_placeholder")}
                autoComplete="new-password" // This is hack to prevent autofill on this input
                style={{ width: "70%" }}
                onChange={(event) => setEmail?.(event.target.value)}
                onKeyPress={(event) => {
                  const isEnterKey = event.key === "Enter";
                  if (validateEmail(email) && isEnterKey) {
                    handleAddEmail();
                  }
                }}
              />
              <Button
                color="primary"
                variant="contained"
                style={{ minWidth: 160 }}
                onClick={handleAddEmail}
                disabled={!email || !validateEmail(email)}
                loading={isLoading}
              >
                {t("documents_share_add_email_button")}
              </Button>
            </div>

            <div>
              <div className="w-[70%]">
                <List dense>
                  {emails.map((email, index) => (
                    <ListItem key={`${index}__${email}`}>
                      <ListItemAvatar>
                        <Email />
                      </ListItemAvatar>
                      <ListItemText primary={email} />
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={() => onRemoveEmail?.(email)}>
                          <Delete />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
                </List>
              </div>
              <div className="flex flex-row-reverse">
                <Button
                  color="primary"
                  variant="contained"
                  style={{ minWidth: 160 }}
                  onClick={onClickSendEmail}
                  disabled={!emails.length}
                  loading={isLoading}
                >
                  {t("documents_share_send_email_button")}
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </PortexDialog>
  );
};

export default ShareDocsDialogView;
