import { VFC } from "react";

import filesApi from "api/rest/files/filesApi";
import {
  shipmentDocumentSelectors,
  shipmentDocumentsActions,
  emailsToShareWithSelectors,
} from "app/pages/shipments/store/shipmentDocumentsSlice";
import { useShipmentsSliceSelector } from "app/pages/shipments/store/shipmentsStore";
import { Shipment } from "app/pages/shipments/types/domain";
import { chatDraftAttachmentsActions } from "components/chat/store/chatDraftFilesSlice";
import useLDFlag from "hooks/useLDFlag";
import compact from "lodash/compact";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { DraftAttachment } from "types/Chat";

import DocumentsBulkActionsView from "../views/DocumentsBulkActionsView";
import ShareDocsDialogView from "../views/ShareDocsDialogView";

const DocumentsBulkActionsContainer: VFC<{ shipment: Shipment }> = (props) => {
  const { shipment } = props;
  const enableShareDocumentsViaChat = useLDFlag("enableShareDocuments");
  const enableShareDocumentsViaEmail = useLDFlag("enableShareDocumentsViaEmail");
  const { isShareDialogOpen } = useShipmentsSliceSelector((state) => state.shipmentDocumentsSlice);
  const setIsShareDialogOpen = shipmentDocumentsActions.useSetIsShareDialogOpen();
  const addManyDraftAttachments = chatDraftAttachmentsActions.useAddManyDraftAttachments();
  const unselectAllFiles = shipmentDocumentsActions.useUnselectAllFiles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation(["shipments", "common"]);

  const documentsSelectedCount = useShipmentsSliceSelector((state) =>
    shipmentDocumentSelectors.selectTotal(state.shipmentDocumentsSlice)
  );
  const selectedDocuments = useShipmentsSliceSelector((state) =>
    shipmentDocumentSelectors.selectAll(state.shipmentDocumentsSlice)
  );

  /** State for sharing emails */
  const emailsToShareDocsWith = useShipmentsSliceSelector((state) =>
    emailsToShareWithSelectors.selectAll(state.shipmentDocumentsSlice.emailsToShareDocsWith).map(({ email }) => email)
  );
  const upsertOneEmail = shipmentDocumentsActions.useUpsertOneEmail();
  const removeOneEmail = shipmentDocumentsActions.useRemoveOneEmail();
  const removeAllEmails = shipmentDocumentsActions.useRemoveAllEmails();
  const [shareFiles, shareFilesMutation] = filesApi.useShareFilesMutation();

  const shareInChat = () => {
    if (!shipment.conversationId) {
      return;
    }

    addManyDraftAttachments({
      conversationId: Number(shipment.conversationId),
      attachments: selectedDocuments
        .filter((file) => !!file.file.id)
        .map((document) => ({ fileId: document.file.id, filename: document.file.name ?? "" } as DraftAttachment)),
    });

    setIsShareDialogOpen(false);
    history.push(`/shipper/shipments/${shipment.id}/activity`);
  };

  const shareViaEmail = async () => {
    try {
      await shareFiles({
        body: {
          fileIds: compact(selectedDocuments.map((selectedDocument) => selectedDocument.file.id)),
          recipients: emailsToShareDocsWith,
          shipmentId: shipment.id,
        },
      }).unwrap();

      enqueueSnackbar(t("shipments:documents_share_send_email_success"), {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "top" },
      });

      setIsShareDialogOpen(false);
      removeAllEmails();
      unselectAllFiles();
    } catch {
      enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
    }
  };

  if (!enableShareDocumentsViaChat && !enableShareDocumentsViaEmail) {
    return null;
  }

  return (
    <>
      <DocumentsBulkActionsView
        documentsSelectedCount={documentsSelectedCount}
        onClickShare={() => setIsShareDialogOpen(true)}
      />
      <ShareDocsDialogView
        documentsSelectedCount={documentsSelectedCount}
        dialogProps={{
          open: isShareDialogOpen,
          onClose: () => setIsShareDialogOpen(false),
        }}
        showShareViaChat={enableShareDocumentsViaChat}
        showShareViaEmail={enableShareDocumentsViaEmail}
        onClickShareInChat={shareInChat}
        emails={emailsToShareDocsWith}
        onAddEmail={upsertOneEmail}
        onRemoveEmail={removeOneEmail}
        onClickSendEmail={shareViaEmail}
        isLoading={shareFilesMutation.isLoading}
      />
    </>
  );
};

export default DocumentsBulkActionsContainer;
