import { VFC } from "react";

import ShipmentDetailsProvider from "features/shipments/provider/ShipmentDetailsProvider";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

import { Shipment } from "../types/domain";
import ShipmentDetailsTabView from "./ShipmentDetailsTabView";

interface ShipmentDetailsTabContainerProps {
  shipment: Shipment;
}

const ShipmentDetailsTabContainer: VFC<ShipmentDetailsTabContainerProps> = ({ shipment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation("shipments");

  const onSaveSuccess = ({ isEmailSent }: { isEmailSent: boolean }) => {
    enqueueSnackbar(
      t(
        isEmailSent ? "shipmentDetails_changesSaved_notification" : "shipmentDetails_changesSaved_noEmail_notification"
      ),
      {
        variant: "success",
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
      }
    );
  };

  const onSaveFailure = () => {
    enqueueSnackbar(t("shipmentDetails_failedToSave_notification"), { variant: "error" });
  };

  return (
    <ShipmentDetailsProvider shipment={shipment} onSaveSuccess={onSaveSuccess} onSaveFailure={onSaveFailure}>
      <ShipmentDetailsTabView />
    </ShipmentDetailsProvider>
  );
};

export default ShipmentDetailsTabContainer;
