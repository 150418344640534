import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { deepNullToOptional } from "api/rest/dispatches/transformers";
import { getShipperQuoteRequestApi, ShipperQuoteRequest } from "api/rest/quote-requests";
import { createQuoteRequestApi } from "api/rest/quote-requests/createQuoteRequest";
import { ModeQuotes } from "types/Mode";
import usableActions from "utils/store/usableActions";

import { QuoteRequestPageSteps } from "../types/QuoteRequestPageSteps";

type State = {
  selectedMode?: ModeQuotes;
  step?: QuoteRequestPageSteps;
  quoteRequest?: ShipperQuoteRequest;
};

const initialState: State = {};

const quoteRequestSlice = createSlice({
  name: "quoteRequestSlice",
  initialState,
  reducers: {
    resetState: () => initialState,
    selectMode: (state, action: PayloadAction<ModeQuotes | undefined>) => {
      quoteRequestSlice.caseReducers.resetState();
      state.selectedMode = action.payload;
    },
    setStep: (state, action: PayloadAction<QuoteRequestPageSteps | undefined>) => {
      state.step = action.payload;
    },
    changeQuoteRequest: (state, action: PayloadAction<{ changes: Partial<ShipperQuoteRequest> }>) => {
      if (!state.quoteRequest) {
        return;
      }

      state.quoteRequest = { ...state.quoteRequest, ...action.payload.changes };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(createQuoteRequestApi.endpoints.createQuoteRequest.matchFulfilled, (state, action) => {
      state.quoteRequest = deepNullToOptional(action.payload.data.quoteRequest) as ShipperQuoteRequest;
    });
    builder.addMatcher(getShipperQuoteRequestApi.endpoints.getShipperQuoteRequest.matchFulfilled, (state, action) => {
      if (state.quoteRequest && state.quoteRequest.id !== action.payload.data.quoteRequest.id) {
        return;
      }
      state.quoteRequest = deepNullToOptional(action.payload.data.quoteRequest) as ShipperQuoteRequest;
    });
  },
});

export const quoteRequestActions = usableActions(quoteRequestSlice.actions);
export default quoteRequestSlice;
