import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import { useUpdateQuoteRequestMutation } from "pages/shipper/pages/request-quote/pages/ltl/api/generated/updateQuoteRequest.generated";
import { useParams } from "react-router-dom";

import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";
import useChangeQuoteRequestRoute from "../dispatches/useChangeQuoteRequestRoute";
import useQuoteRequestStep from "../dispatches/useQuoteRequestStep";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLoadQuoteRequestAndRoutingStep = (step: QuoteRequestPageSteps) => {
  const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
  const [_, { isLoading: isUpdatingQuoteRequest }] = useUpdateQuoteRequestMutation();
  useQuoteRequestStep(step);
  const { quoteRequestId } = useParams<{ quoteRequestId?: string }>();

  if (!quoteRequestId) {
    changeQuoteRequestRoute({ step: "initial" });
  }

  useGetShipperQuoteRequestQuery(quoteRequestId ? { urlParams: { quoteRequestId } } : skipToken);
  const quoteRequest = useRequestManagementSliceSelector((store) => store.quoteRequestSlice.quoteRequest);

  if (!quoteRequest || isUpdatingQuoteRequest) {
    return {
      isLoading: true,
    };
  }

  return {
    isLoading: false,
    quoteRequest,
  };
};

export default useLoadQuoteRequestAndRoutingStep;
