import { useModifyShipperDispatchMutation } from "api/rest/dispatches";
import FilesControl, { OnMultiUploadSuccessCallback } from "components/file-uploads/FilesControl";
import Loading from "components/Loading";
import withAsync from "components/withAsync";

import { useDispatchShipmentAndAwards } from "../../hooks/dispatches/useDispatchShipmentAndAwards";
import PageLayout from "../../views/PageLayout";
import Confirmation from "./Confirmation";
import ConfirmationSummary from "./ConfirmationSummary";
import Overview from "./Overview";
import QuoteDetails from "./QuoteDetails";
import RateSummary from "./RateSummary";
import RouteDetails from "./RouteDetails";
import TruckDetails from "./TruckDetails";

const DispatchManagementPage = withAsync({
  useHook: useDispatchShipmentAndAwards,
  LoadingComponent: <Loading />,
  Component: ({ loadedData: { dispatch } }) => {
    const [modifyDispatch] = useModifyShipperDispatchMutation();

    const uploadFiles: OnMultiUploadSuccessCallback = async (results) => {
      return await modifyDispatch({
        body: {
          confirmation_files: [...(dispatch.confirmation_files ?? []), ...results.map((result) => result.fileId)],
        },
        urlParams: { requestId: dispatch.id },
      }).unwrap();
    };

    const deleteFile = async (fileId: string) => {
      return await modifyDispatch({
        body: {
          confirmation_files: (dispatch.confirmation_files ?? []).filter(
            (confirmationFileId) => confirmationFileId !== fileId
          ),
        },
        urlParams: { requestId: dispatch.id },
      }).unwrap();
    };

    return (
      <FilesControl
        fileIds={dispatch.confirmation_files ?? []}
        onMultiUploadSuccess={uploadFiles}
        onDeleteSuccess={deleteFile}
        dropzoneOptions={{
          disabled: dispatch.status === "CONFIRMED" || dispatch.status === "REJECTED" || dispatch.status === "CANCELED",
          maxFiles: 4,
          maxSize: 5e6,
        }}
      >
        <PageLayout
          header={<Overview />}
          leftContent={[<RateSummary />, <ConfirmationSummary />, <TruckDetails />, <RouteDetails />, <QuoteDetails />]}
          rightContent={[<Confirmation />]}
        />
      </FilesControl>
    );
  },
});

export default DispatchManagementPage;
