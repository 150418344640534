import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { FileControlWrapper } from "components/file-uploads/FilesCache";
import usableActions from "utils/store/usableActions";

const documentsAdapter = createEntityAdapter<FileControlWrapper>({
  selectId: (file) => file.localId,
});

const emailsToShareWithAdapter = createEntityAdapter<{ email: string }>({
  selectId: ({ email }) => email,
});
export const emailsToShareWithSelectors = emailsToShareWithAdapter.getSelectors();

const {
  getInitialState,
  getSelectors,
  selectId: _selectId,
  sortComparer: _sortCompare,
  ...reducers
} = documentsAdapter;

export const shipmentDocumentSelectors = getSelectors();

type ShipmentDocumentsSliceState = EntityState<FileControlWrapper> & {
  selectAllState: "empty" | "indeterminate" | "allSelected";
  isShareDialogOpen: boolean;
  emailsToShareDocsWith: EntityState<{ email: string }>;
};

const initialState: ShipmentDocumentsSliceState = getInitialState({
  selectAllState: "empty",
  isShareDialogOpen: false,
  emailsToShareDocsWith: emailsToShareWithAdapter.getInitialState(),
});

const shipmentDocumentsSlice = createSlice({
  name: "shipmentDocumentsSlice",
  initialState,
  reducers: {
    ...reducers,
    setIsShareDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareDialogOpen = action.payload;
    },
    toggleFile: (state, action: PayloadAction<FileControlWrapper>) => {
      const fileIsSelected = !!shipmentDocumentSelectors.selectById(state, action.payload.localId);
      const selectedFilesCount = shipmentDocumentSelectors.selectTotal(state);

      if (fileIsSelected) {
        reducers.removeOne(state, action.payload.localId);

        if (selectedFilesCount === 1) {
          state.selectAllState = "empty";
        } else {
          state.selectAllState = "indeterminate";
        }
      } else {
        reducers.addOne(state, action.payload);
        state.selectAllState = "indeterminate";
      }
    },
    toggleAllFiles: (state, action: PayloadAction<FileControlWrapper[]>) => {
      const selectedFilesCount = shipmentDocumentSelectors.selectTotal(state);

      if (!!selectedFilesCount) {
        shipmentDocumentsSlice.caseReducers.unselectAllFiles(state);
      } else {
        reducers.addMany(state, action.payload);
        state.selectAllState = "allSelected";
      }
    },
    unselectAllFiles: (state) => {
      reducers.removeAll(state);
      state.selectAllState = "empty";
    },
    upsertOneEmail: (state, action: PayloadAction<string>) => {
      const email = action.payload.trim().toLowerCase();
      emailsToShareWithAdapter.upsertOne(state.emailsToShareDocsWith, { email });
    },
    removeOneEmail: (state, action: PayloadAction<string>) => {
      const email = action.payload.trim().toLowerCase();
      emailsToShareWithAdapter.removeOne(state.emailsToShareDocsWith, email);
    },
    removeAllEmails: (state) => {
      emailsToShareWithAdapter.removeAll(state.emailsToShareDocsWith);
    },
  },
});

export const shipmentDocumentsActions = usableActions(shipmentDocumentsSlice.actions);
export default shipmentDocumentsSlice;
