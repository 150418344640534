import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { Fade, Icon, Tooltip } from "@portex-pro/ui-components";
import Switch from "components/Switch";
import useLDFlag from "hooks/useLDFlag";
import { Trans, useTranslation } from "react-i18next";
import { useEffectOnce } from "usehooks-ts";

import { useUpdateState } from "../store/dispatchRequestSlice";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";

const AutoConfirmContainer: VFC = () => {
  const { t } = useTranslation("dispatchRequest");
  const autoConfirm = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.autoConfirm);
  const updateState = useUpdateState();
  const enableDispatchAutoConfirm = useLDFlag("enableDispatchAutoConfirm");

  useEffectOnce(() => {
    if (!enableDispatchAutoConfirm || typeof autoConfirm === "boolean") {
      return;
    }

    /**
     * > If we’re turning this feature on for Shippers in launch darkly, they will want this on most of the time.
     * @see https://portexpro.atlassian.net/browse/PR-1924
     */
    updateState({ autoConfirm: true });
  });

  if (!enableDispatchAutoConfirm) {
    return null;
  }

  return (
    <div className="flex items-center space-x-1">
      <Tooltip
        interactive
        title={
          <div className="py-1">
            <div className="pb-3">
              <Trans ns="dispatchRequest" i18nKey="autoConfirm_tooltip" />
            </div>
          </div>
        }
        placement="top"
        arrow
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 600 }}
        enterDelay={0}
      >
        <div className="flex items-center space-x-1">
          <span className="font-bold">{t("autoConfirm")}</span>
          <Icon as={Info} fontSize="small" />
        </div>
      </Tooltip>
      <Switch checked={autoConfirm} onChange={() => updateState({ autoConfirm: !autoConfirm })} />
    </div>
  );
};

export default AutoConfirmContainer;
