import { VFC } from "react";

import { ShipmentStop } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { FormData, PackingGroup, ShipLocation } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

import BolCarrierView from "./BolCarrierView";
import CargoView from "./CargoView";
import FinalDetailsView from "./FinalDetailsView";
import ReferencesView from "./ReferencesView";
import ShipLocationView from "./ShipLocationView";
import SpecialInstructionsView from "./SpecialInstructionsView";
import ThirdPartyFreightView from "./ThirdPartyFreightView";

interface BolFormViewProps {
  formData: FormData;
  stops: ShipmentStop[];

  onChange: (data: Partial<FormData>) => void;
  onChangeStop: (stopId: ShipmentStop["id"], location: "pickup" | "delivery") => void;
  onChangeShipLocation: (data: Partial<ShipLocation>, location: "pickup" | "delivery") => void;
  onChangeCargoDetail: (changes: Partial<PackingGroup>, index: number) => void;
  onRemoveCargo: (index: number) => void;
  onAddAnotherItem: () => void;
}

const BolFormView: VFC<BolFormViewProps> = (props) => {
  const {
    formData,
    stops,
    onChange,
    onChangeShipLocation,
    onChangeStop,
    onAddAnotherItem,
    onChangeCargoDetail,
    onRemoveCargo,
  } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="w-full bg-white border border-grey-300 flex flex-col">
      <div className="flex flex-row w-full">
        <div className="flex flex-col w-2/3 border-r border-grey-300">
          <Text
            size="large"
            weight="bold"
            typographyProps={{
              style: { textTransform: "uppercase", width: "100%", padding: "1.25rem", textAlign: "center" },
            }}
          >
            {t("billOfLading")}
          </Text>
          <ShipLocationView
            shipLocation={formData.shipFrom}
            onChangeShipLocation={(data) => onChangeShipLocation(data, "pickup")}
            onChangeStop={(stopId) => onChangeStop(stopId, "pickup")}
            stops={stops}
            style="pickup"
            showDate
          />
          <ShipLocationView
            shipLocation={formData.shipTo}
            onChangeShipLocation={(data) => onChangeShipLocation(data, "delivery")}
            onChangeStop={(stopId) => onChangeStop(stopId, "delivery")}
            stops={stops}
            style="delivery"
          />
          <ThirdPartyFreightView
            onChange={onChange}
            freightTerms={formData.freightTerms}
            thirdPartyBillTo={formData.thirdPartyBillTo}
          />
          <SpecialInstructionsView
            onChange={onChange}
            shipFromAccessorials={formData.shipFromAccessorials}
            shipToAccessorials={formData.shipToAccessorials}
            specialInstructions={formData.specialInstructions}
          />
        </div>
        <div className="flex flex-col w-1/3">
          <BolCarrierView
            onChange={onChange}
            bolNumber={formData.bolNumber}
            carrier={formData.carrier}
            partner={formData.partner}
          />
          <ReferencesView
            onChange={onChange}
            onChangeShipLocation={onChangeShipLocation}
            shipFromRef={formData.shipFrom.referenceNumber}
            shipToRef={formData.shipTo.referenceNumber}
            loadId={formData.loadId}
            poReferenceNumber={formData.poReferenceNumber}
            trailerNumber={formData.trailerNumber}
            additionalReferences={formData.additionalReferences}
          />
        </div>
      </div>
      <CargoView
        cargo={formData.cargo ?? []}
        onAddAnotherItem={onAddAnotherItem}
        onChangeCargoDetail={onChangeCargoDetail}
        onRemoveCargo={onRemoveCargo}
      />
      <FinalDetailsView
        onChange={onChange}
        remitCodTo={formData.remitCodTo}
        codAmount={formData.codAmount}
        declaredValueAmount={formData.declaredValueAmount}
        declaredValueUnit={formData.declaredValueUnit}
        feeTerms={formData.feeTerms}
        isHazardous={formData.isHazardous}
      />
    </div>
  );
};

export default BolFormView;
