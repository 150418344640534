import { useCallback, useContext } from "react";

import { useUpdateShipperQuoteRequestMutation } from "api/rest/quote-requests/updateShipperQuoteRequest";
import { CargoGroupContext } from "features/cargo-groups/CargoGroupProvider";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";

import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import useChangeQuoteRequestRoute, { QuoteRequestRouteOptions } from "../dispatches/useChangeQuoteRequestRoute";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSaveAndChangeRoute = () => {
  const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
  const quoteRequest = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.quoteRequest);
  const [updateQuoteRequest] = useUpdateShipperQuoteRequestMutation();
  const { t } = useTranslation("common");
  const { enqueueSnackbar } = useSnackbar();
  const { save: saveCargoGroups } = useContext(CargoGroupContext);

  return useCallback(
    async (options: QuoteRequestRouteOptions, sendQuoteRequest?: boolean) => {
      if (!quoteRequest) {
        return;
      }

      try {
        await updateQuoteRequest({
          urlParams: { quoteRequestId: quoteRequest.id },
          body: { ...quoteRequest, status: sendQuoteRequest ? "SENT" : quoteRequest?.status },
        }).unwrap();
        await saveCargoGroups();
        changeQuoteRequestRoute(options);
      } catch (e) {
        Sentry.captureException(e);
        enqueueSnackbar(t("errors.generic"), { variant: "error" });
      }
    },
    [changeQuoteRequestRoute, enqueueSnackbar, t, quoteRequest, updateQuoteRequest, saveCargoGroups]
  );
};
