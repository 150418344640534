import { VFC } from "react";

import { Button, ButtonGroup, FloatInput, FormGroup } from "@portex-pro/ui-components";
import { FuelCostCalcMethod } from "app/pages/dispatch-request/store/dispatchRequestSlice";
import classNames from "classnames";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import round from "lodash/round";
import { useTranslation } from "react-i18next";

export interface FuelSurchargeViewProps {
  calcMethod: FuelCostCalcMethod;
  setCalcMethod: (method: FuelCostCalcMethod) => void;
  fuelCost?: number;
  perMileRate?: number;
  percentageRate?: number;
  miles: number | null;
  setPerMileRate: (perMileRate: number) => void;
  setPercentageRate: (percentageRate: number) => void;
  setFuelSurcharge: (fuelCost: number) => void;
  required?: boolean;
  disableMiles?: boolean;
  setMiles?: (miles: number) => void;
}

const FuelSurchargeView: VFC<FuelSurchargeViewProps> = ({
  calcMethod,
  setCalcMethod,
  fuelCost,
  perMileRate,
  percentageRate,
  miles,
  setPercentageRate,
  setFuelSurcharge,
  setPerMileRate,
  required,
  disableMiles,
  setMiles,
}: FuelSurchargeViewProps) => {
  const { t } = useTranslation("dispatchRequest");

  return (
    <div className="space-y-4 w-full">
      <div className="flex w-full space-x-2">
        <FormGroup className="w-2/3 space-y-2">
          <Text size="small" weight="bold">
            {t("fuelCalc_calcMethod_label")}
          </Text>
          <ButtonGroup color="primary" className="w-full">
            <Button
              onClick={() => setCalcMethod("perMile")}
              className={classNames("w-full", { "Por-selected": calcMethod === "perMile" })}
              disabled={!miles}
            >
              {t("fuelCalc_calcMethod_perMile")}
            </Button>
            <Button
              onClick={() => setCalcMethod("dollar")}
              className={classNames("w-full", { "Por-selected": calcMethod === "dollar" })}
            >
              {t("fuelCalc_calcMethod_dollarCost")}
            </Button>
            <Button
              onClick={() => setCalcMethod("percentBase")}
              className={classNames("w-full", { "Por-selected": calcMethod === "percentBase" })}
            >
              {t("fuelCalc_calcMethod_percentBaseRate")}
            </Button>
          </ButtonGroup>
        </FormGroup>

        <div className="w-1/3 flex space-x-2">
          {calcMethod === "perMile" && (
            <>
              <FormGroup className="space-y-2 w-1/2">
                <Text size="small" weight="bold">
                  {t("fuelCalc_perMileRate_label")}
                </Text>
                <PositiveNumberInput
                  value={typeof perMileRate === "number" ? round(perMileRate, 2) : undefined}
                  allowFloat
                  disableError
                  allowEmpty
                  required={required}
                  decimalPlace={2}
                  error={required && typeof perMileRate !== "number"}
                  onChange={setPerMileRate}
                  startIcon={<div className="m-1">$</div>}
                />
              </FormGroup>
              <FormGroup className="space-y-2 w-1/2">
                <Text size="small" weight="bold">
                  {t("fuelCalc_miles_label")}
                </Text>
                <FloatInput
                  decimalPlace={2}
                  value={round(miles ?? 0, 2)}
                  disabled={disableMiles}
                  onChange={(value) => setMiles && setMiles(value)}
                />
              </FormGroup>
            </>
          )}
          {calcMethod === "dollar" && (
            <FormGroup className="space-y-2 w-full">
              <Text size="small" weight="bold">
                {t("fuelCalc_fuelSurcharge_label")}
              </Text>
              <PositiveNumberInput
                value={typeof fuelCost === "number" ? round(fuelCost, 2) : undefined}
                allowFloat
                disableError
                allowEmpty
                required={required}
                decimalPlace={2}
                error={required && typeof fuelCost !== "number"}
                onChange={setFuelSurcharge}
                startIcon={<div className="m-1">$</div>}
              />
            </FormGroup>
          )}
          {calcMethod === "percentBase" && (
            <FormGroup className="space-y-2 w-full">
              <Text size="small" weight="bold">
                {t("fuelCalc_percentOfBase_label")}
              </Text>
              <PositiveNumberInput
                value={typeof percentageRate === "number" ? round(percentageRate, 2) : undefined}
                allowFloat
                disableError
                allowEmpty
                required={required}
                error={required && typeof percentageRate !== "number"}
                onChange={setPercentageRate}
                endIcon={<div className="m-1">%</div>}
              />
            </FormGroup>
          )}
        </div>
      </div>
    </div>
  );
};

export default FuelSurchargeView;
