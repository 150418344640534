import { EntityState, PayloadAction, createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { DraftAttachment } from "types/Chat";
import usableActions from "utils/store/usableActions";

const draftAttachmentsAdapter = createEntityAdapter<DraftAttachment>({
  selectId: (draft) => draft.fileId,
});

type CacheState = {
  conversationId: number;
  attachments: EntityState<DraftAttachment>;
};

const cacheAdapter = createEntityAdapter<CacheState>({
  selectId: (cache) => cache.conversationId,
});

const {
  getInitialState: getCacheInitialState,
  getSelectors: getCacheSelectors,
  selectId: _selectId,
  sortComparer: _sortCompare,
  ...cacheReducers
} = cacheAdapter;

export const cacheSelectors = getCacheSelectors();

const {
  getInitialState: getDraftAttachmentsInitialState,
  getSelectors: getDraftAttachmentsSelectors,
  selectId: _draftAttachmentsSelectId,
  sortComparer: _draftAttachmentsSortComparer,
  ...draftAttachmentsReducers
} = draftAttachmentsAdapter;

export const draftAttchmentSelectors = getDraftAttachmentsSelectors();

const initialState = getCacheInitialState();

const sliceName = "chatDraftAttachmentsSlice";

const chatDraftAttachmentsSlice = createSlice({
  name: sliceName,
  initialState,
  reducers: {
    addManyDraftAttachments: (
      state,
      action: PayloadAction<{ conversationId: number; attachments: DraftAttachment[] }>
    ) => {
      let conversationCache = cacheSelectors.selectById(state, action.payload.conversationId);

      if (!conversationCache) {
        cacheReducers.addOne(state, {
          conversationId: action.payload.conversationId,
          attachments: getDraftAttachmentsInitialState(),
        });

        conversationCache = cacheSelectors.selectById(state, action.payload.conversationId);
      }

      if (!conversationCache) {
        return;
      }

      cacheReducers.updateOne(state, {
        id: action.payload.conversationId,
        changes: {
          attachments: draftAttachmentsReducers.upsertMany(conversationCache.attachments, action.payload.attachments),
        },
      });
    },
    removeManyDraftAttachments: (
      state,
      action: PayloadAction<{ conversationId: number; attachmentIds: DraftAttachment["fileId"][] }>
    ) => {
      const conversationCache = cacheSelectors.selectById(state, action.payload.conversationId);

      if (!conversationCache) {
        return;
      }

      cacheReducers.updateOne(state, {
        id: action.payload.conversationId,
        changes: {
          attachments: draftAttachmentsReducers.removeMany(conversationCache.attachments, action.payload.attachmentIds),
        },
      });
    },
    removeAllDraftAttachments: (state, action: PayloadAction<number>) => {
      const conversationCache = cacheSelectors.selectById(state, action.payload);

      if (!conversationCache) {
        return;
      }

      cacheReducers.updateOne(state, {
        id: action.payload,
        changes: {
          attachments: draftAttachmentsReducers.removeAll(conversationCache.attachments),
        },
      });
    },
  },
});

export const chatDraftAttachmentsActions = usableActions(chatDraftAttachmentsSlice.actions);
export default chatDraftAttachmentsSlice;
