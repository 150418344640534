import cloneDeep from "lodash/cloneDeep";

import { CreateCargoGroup, NormalizedCargoGroup } from "../types";

export const mapNormalizedCargoGroupsToCreateApi = (
  normalizedCargoGroups: NormalizedCargoGroup[]
): CreateCargoGroup[] => {
  const copiedNormalizedCargoGroups = normalizedCargoGroups
    .map((cargoGroup) => cloneDeep(cargoGroup))
    .map((cargoGroup) => ({ ...cargoGroup, packing_units: [], handling_units: [] }));
  const mapIdToCargoGroup: Record<string | number, CreateCargoGroup> = copiedNormalizedCargoGroups.reduce(
    (map, cargoGroup) => {
      map[cargoGroup.id] = cargoGroup as unknown as CreateCargoGroup;
      return map;
    },
    {} as Record<string | number, CreateCargoGroup>
  );
  const cargoGroupsWithoutNewParentGroup: CreateCargoGroup[] = copiedNormalizedCargoGroups.filter(
    (cargoGroup) => typeof cargoGroup.cargo_unit_id !== "string" && typeof cargoGroup.handling_unit_id !== "string"
  ) as unknown as CreateCargoGroup[];

  copiedNormalizedCargoGroups.forEach((cargoGroup) => {
    // @ts-expect-error we need to set this to undefined
    cargoGroup.id = undefined;

    if (cargoGroup.cargo_unit_id && typeof cargoGroup.cargo_unit_id === "string") {
      const parent = mapIdToCargoGroup[cargoGroup.cargo_unit_id];

      if (!parent) {
        throw new Error("Parent cargo unit not present");
      }

      parent.handling_units = [...(parent.handling_units ?? []), cargoGroup as unknown as CreateCargoGroup];

      cargoGroup.cargo_unit_id = undefined;
    }

    if (cargoGroup.handling_unit_id && typeof cargoGroup.handling_unit_id === "string") {
      const parent = mapIdToCargoGroup[cargoGroup.handling_unit_id];

      if (!parent) {
        throw new Error("Parent handling unit not present");
      }

      parent.packing_units = [...(parent.packing_units ?? []), cargoGroup as unknown as CreateCargoGroup];

      cargoGroup.handling_unit_id = undefined;
    }
  });

  return cargoGroupsWithoutNewParentGroup;
};
