import { baseRestApi } from "api/rest/baseRestApi";
import paramsToTuples from "utils/paramsToTuples";

import { CargoGroup } from "./types";

const API_ROOT = "/shipper/cargo-groups";
module.hot?.accept();

type QueryParams = {
  ids: CargoGroup["id"][];
};

type QueryArgs = { queryParams: QueryParams };

type ReturnValue = {
  data: CargoGroup[];
};

const getCargoGroupsApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["CARGO_GROUPS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    getCargoGroups: builder.query<ReturnValue, QueryArgs>({
      query: (params) => {
        return {
          url: `${API_ROOT}`,
          method: "GET",
          params: paramsToTuples(params.queryParams),
        };
      },
      providesTags: (returnValue) =>
        returnValue?.data.map((cargoGroup) => ({ type: "CARGO_GROUPS", id: cargoGroup.id })) ?? [],
    }),
  }),
});

export default getCargoGroupsApi;

export const { useGetCargoGroupsQuery, useLazyGetCargoGroupsQuery } = getCargoGroupsApi;
