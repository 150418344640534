import { useMemo } from "react";

import { AppBar, Divider, styled, Toolbar } from "@portex-pro/ui-components";
import BreadcrumbsContainer from "components/BreadcrumbsContainer";
import withAsync from "components/withAsync";
import Breadcrumb from "pages/shipper/components/Breadcrumb";
import BreadcrumbLink from "pages/shipper/components/BreadcrumbLink";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";
import { QuoteManagementPageParams } from "../../types/QuoteManagementPageParams";

const TOOLBAR_BORDER_PX = 1;
const TOOLBAR_HEIGHT_PX = 50;

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  boxShadow: `0 ${TOOLBAR_BORDER_PX}px 0 ${theme.palette.grey[300]}`,
  height: TOOLBAR_HEIGHT_PX,
}));

const QuoteManagementAppBar = withAsync({
  useHook: () => {
    const { quoteRequestId, quoteId } = useParams<QuoteManagementPageParams>();

    const { quoteRequest, isLoading } = useLoadQuoteRequest();

    if (isLoading || !quoteRequest) {
      return {
        isLoading: true,
      };
    }

    return {
      quoteRequestId,
      quoteId,
      isLoading: false,
      quoteRequest,
    };
  },
  Component: ({ loadedData: { quoteId, quoteRequest, quoteRequestId } }) => {
    const { t } = useTranslation("quoteRequest");

    const selectedQuote = useMemo(
      () => quoteRequest.quotes.find((quote) => quote.mode === "DRAYAGE" && quote.drayage_quote_id === Number(quoteId)),
      [quoteId, quoteRequest]
    );

    const breadcrumbElements = [
      {
        element: (
          <BreadcrumbLink key="quotes" active={false} to="/shipper/requests">
            {t("quotes")}
          </BreadcrumbLink>
        ),
        predicate: true,
      },
      {
        element: (
          <BreadcrumbLink key="portexId" active={!quoteId} to={`/shipper/requests/quotes/${quoteRequestId}`}>
            {quoteRequest.portex_id}
          </BreadcrumbLink>
        ),
        predicate: !!quoteRequest,
      },
      {
        element: (
          <Breadcrumb key="quote" active={!!quoteId}>
            {selectedQuote?.company_name ?? selectedQuote?.submitter_email}
          </Breadcrumb>
        ),
        predicate: !!selectedQuote,
      },
    ].reduce<JSX.Element[]>((acc, { element, predicate }) => {
      if (predicate) {
        acc.push(element);
      }
      return acc;
    }, []);

    return (
      <AppBar>
        <StyledToolbar variant="dense" disableGutters>
          <BreadcrumbsContainer>{breadcrumbElements}</BreadcrumbsContainer>
        </StyledToolbar>
        <Divider />
      </AppBar>
    );
  },
});

export default QuoteManagementAppBar;
