import { Dropdown, MenuItem } from "@portex-pro/ui-components";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import withAsync from "components/withAsync";
import { DateTime } from "luxon";
import { useParams } from "react-router-dom";

import { useSetState } from "../../store/quoteIndexSlice";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";

const QuoteListControlsContainer = withAsync({
  useHook: () => {
    return {
      isLoading: false,
    };
  },
  Component: () => {
    const sortMode = useRequestManagementSliceSelector((store) => store.quoteIndexSlice.sortBy);
    const setState = useSetState();
    const { quoteRequestId } = useParams<{ quoteRequestId?: string }>();
    const { isLoading, isFetching, refetch, fulfilledTimeStamp } = useGetShipperQuoteRequestQuery(
      quoteRequestId ? { urlParams: { quoteRequestId } } : skipToken
    );

    return (
      <div className="flex flex-row justify-between">
        <Dropdown
          placeholder={`Sort by ${
            sortMode === "CHEAPEST" ? "Cheapest first" : sortMode === "FASTEST" ? "Fastest first" : "Quote Validity"
          }`}
        >
          {({ onClose }) =>
            [
              <MenuItem
                className={sortMode === "CHEAPEST" ? "Por-selected" : ""}
                onClick={() => {
                  setState({ sortBy: "CHEAPEST" });
                  onClose();
                }}
              >
                Cheapest first
              </MenuItem>,
              <MenuItem
                className={sortMode === "FASTEST" ? "Por-selected" : ""}
                onClick={() => {
                  setState({ sortBy: "FASTEST" });
                  onClose();
                }}
              >
                Fastest first
              </MenuItem>,
              <MenuItem
                className={sortMode === "VALIDITY" ? "Por-selected" : ""}
                onClick={() => {
                  setState({ sortBy: "VALIDITY" });
                  onClose();
                }}
              >
                Quote Validity
              </MenuItem>,
            ].map((element, idx) => <span key={idx}>{element}</span>)
          }
        </Dropdown>
        <div className="flex flex-row space-x-2 items-center">
          {fulfilledTimeStamp && (
            <LastRefreshedAtText
              value={DateTime.fromMillis(fulfilledTimeStamp).toLocaleString(DateTime.DATETIME_SHORT_WITH_SECONDS)}
            />
          )}
          <RefetchButton loading={isLoading || isFetching} onClick={refetch} />
        </div>
      </div>
    );
  },
});

export default QuoteListControlsContainer;
