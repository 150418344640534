import { CargoGroupType, NormalizedCargoGroup } from "../api/types";

export const isNormalizedCargoGroupValid = (cargoGroup: NormalizedCargoGroup): boolean => {
  let isValid = false;

  if (cargoGroup.cargo_group_type === CargoGroupType.CARGO) {
    isValid = !!cargoGroup.equipment_size && !!cargoGroup.equipment_type;
  } else {
    isValid = !!cargoGroup.packaging_type && !!cargoGroup.count;
  }

  return isValid;
};
