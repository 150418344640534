import { VFC } from "react";

import { Button } from "@material-ui/core";
import { InfoRounded } from "@material-ui/icons";
import { FormControl, FormLabel, Paper, portexColor, TextInput } from "@portex-pro/ui-components";
import { BrokerQuoteResponse } from "api/rest/quote-requests";
import PositiveNumberInput from "components/PositiveNumberInput";
import Text from "components/Text";
import { DateTime } from "luxon";
import QuoteValidityPicker from "pages/broker/quote/components/QuoteSubmissionLTL/QuoteValidityPicker";
import { useTranslation } from "react-i18next";

interface QuoteDetailsViewProps {
  quote: Partial<BrokerQuoteResponse>;
  onChange: (changes: Partial<BrokerQuoteResponse>) => void;
  onSubmit: () => void;
  isLoading: boolean;
  isValid: boolean;
}

const QuoteDetailsView: VFC<QuoteDetailsViewProps> = (props) => {
  const { onChange, quote, onSubmit, isLoading, isValid } = props;
  const { t } = useTranslation(["common", "broker"]);

  return (
    <Paper className="Por-outlined-light" elevation={8}>
      <div className="w-full flex flex-col py-6 px-5 bg-grey-50 space-y-5">
        <Text size="large" weight="bold">
          {t("broker:submitQuote")}
        </Text>

        <Paper
          style={{
            width: "100%",
            height: "100%",
            padding: "14px 20px",
            backgroundColor: portexColor.cyan100,
            borderColor: portexColor.cyan500,
          }}
          variant="outlined"
        >
          <div className="flex flex-row space-x-2.5">
            <InfoRounded style={{ color: portexColor.cyan500 }} />
            <div className="flex flex-col">
              <Text size="medium" weight="bold">
                {t("broker:submitQuote_desc1")}
              </Text>
              <br />
              <Text size="medium" weight="bold">
                {t("broker:submitQuote_desc2")}
              </Text>
            </div>
          </div>
        </Paper>

        <div className="flex flex-row space-x-3">
          <FormControl>
            <FormLabel required>{t("common:totalAmount")}</FormLabel>
            <PositiveNumberInput
              startIcon={<>$</>}
              value={quote.totalAmount}
              onChange={(value) => onChange({ totalAmount: value })}
            />
          </FormControl>
          <QuoteValidityPicker
            value={quote.validUntil ? DateTime.fromISO(quote.validUntil) : null}
            onChange={(date) => onChange({ validUntil: date.toISO() })}
            disabled={false}
            showError={false}
          />
        </div>

        <div className="flex flex-row space-x-3 w-full">
          <FormControl fullWidth>
            <FormLabel required>{t("broker:estimatedTransitTimeStep.minimum.label")}</FormLabel>
            <PositiveNumberInput
              value={quote.minTransitTime}
              fullWidth
              endIcon={
                <Text size="small" weight="light">
                  {t("broker:day", { count: quote.minTransitTime ?? 1 })}
                </Text>
              }
              onChange={(value) => onChange({ minTransitTime: value })}
            />
          </FormControl>

          <FormControl fullWidth>
            <FormLabel required>{t("broker:estimatedTransitTimeStep.maximum.label")}</FormLabel>
            <PositiveNumberInput
              value={quote.maxTransitTime}
              fullWidth
              endIcon={
                <Text size="small" weight="light">
                  {t("broker:day", { count: quote.maxTransitTime ?? 1 })}
                </Text>
              }
              onChange={(value) => onChange({ maxTransitTime: value })}
            />
          </FormControl>
        </div>

        <FormControl fullWidth>
          <FormLabel required>{t("common:emailAddress")}</FormLabel>
          <TextInput
            value={quote.submitterEmail}
            fullWidth
            onChange={(event) => onChange({ submitterEmail: event.target.value })}
          />
        </FormControl>

        <FormControl fullWidth>
          <FormLabel>{t("common:additionalNotes")}</FormLabel>
          <TextInput
            fullWidth
            value={quote.notes}
            multiline
            rows={4}
            onChange={(event) => onChange({ notes: event.target.value })}
            placeholder={t("broker:additionalNotes_placeholder")}
          />
        </FormControl>

        <Button onClick={onSubmit} variant="contained" color="primary" disabled={isLoading || !isValid}>
          {t("broker:submitQuote")}
        </Button>
      </div>
    </Paper>
  );
};

export default QuoteDetailsView;
