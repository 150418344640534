import { ReactElement } from "react";

import { useRequestManagementSlices } from "app/pages/request-management/store/requestManagementStore";

import LayoutColumnTwo from "../../../../../../components/LayoutColumnTwo";
import SelectModeStep from "./components/SelectModeStep";

const ModeSelectionPage = (): ReactElement => {
  useRequestManagementSlices();
  return (
    <LayoutColumnTwo>
      <SelectModeStep />
    </LayoutColumnTwo>
  );
};

export default ModeSelectionPage;
