import { Add } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { useCreateQuoteRequestMutation } from "api/rest/quote-requests/createQuoteRequest";
import Loading from "components/Loading";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";
import { Redirect, useParams } from "react-router-dom";
import { useEffectOnce } from "usehooks-ts";

import useChangeQuoteRequestRoute from "../../hooks/dispatches/useChangeQuoteRequestRoute";
import useQuoteRequestStep from "../../hooks/dispatches/useQuoteRequestStep";
import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const LaneStep = withAsync({
  useHook: () => {
    useQuoteRequestStep("initial");
    const { quoteRequestId } = useParams<{ quoteRequestId?: string }>();

    return {
      isLoading: false,
      quoteRequestId,
    };
  },
  Component: ({ loadedData }) => {
    const { t } = useTranslation("quoteRequest");
    const changeQuoteRequestRoute = useChangeQuoteRequestRoute();
    const quoteRequestId = loadedData.quoteRequestId;
    const selectedMode = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.selectedMode);
    const [createQuoteRequest, createQuoteRequestMutation] = useCreateQuoteRequestMutation();

    const handleCreateQuoteRequest = async () => {
      if (!selectedMode) {
        throw new Error("Expected mode to be selected");
      }

      const response = await createQuoteRequest({ body: { mode: selectedMode } }).unwrap();
      changeQuoteRequestRoute({
        step: QuoteRequestPageSteps.Routing,
        quoteRequestId: response.data.quoteRequest.id,
      });
    };

    useEffectOnce(() => {
      /** @todo PR-1953 -- Auto-create quote request until we support quote request templates */
      if (selectedMode && !quoteRequestId) {
        handleCreateQuoteRequest();
      }
    });

    if (!selectedMode) {
      return <Redirect to="/shipper/requests/create" />;
    }

    if (createQuoteRequestMutation.isLoading) {
      return <Loading spinnerOnly />;
    }

    return (
      <div className="flex gap-3">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={handleCreateQuoteRequest}
          loading={createQuoteRequestMutation.isLoading}
        >
          {t("createQuoteRequestButton")}
        </Button>
      </div>
    );
  },
});

export default LaneStep;
