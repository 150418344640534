import { useAuth0 } from "@auth0/auth0-react";
import { Box, Paper, SvgPortexLogo } from "@portex-pro/ui-components";
import { useCompleteClaimMutation } from "api/rest/users/completeClaimApi";
import Loading from "components/Loading";
import Text from "components/Text";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Sentry } from "sentry";
import { StringParam, useQueryParam } from "use-query-params";
import { useEffectOnce } from "usehooks-ts";

const ClaimComplete: React.VFC = () => {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [signupToken] = useQueryParam("signupToken", StringParam);
  const { logout } = useAuth0();

  const [completeClaim, { isLoading, isUninitialized, isError }] = useCompleteClaimMutation();

  const complete = async () => {
    try {
      if (!signupToken) {
        throw new Error("No signupToken was provided to complete claim");
      }
      await completeClaim({ body: { signupToken } }).unwrap();
    } catch (e) {
      enqueueSnackbar(t("errors.generic"), { variant: "error" });
      Sentry.captureException(e);
      logout({ localOnly: true });
    }
  };

  useEffectOnce(() => {
    complete();
  });

  if (isLoading || isUninitialized) {
    return (
      <div className="w-full h-full flex flex-col">
        <Loading />
      </div>
    );
  }

  return (
    <Box width="100%" height="100%" display="flex" alignItems="center" justifyContent="center">
      <Paper elevation={24} style={{ maxWidth: "40%" }}>
        <Box p={2.5}>
          <div className="flex justify-center">
            <SvgPortexLogo width={135} height={40} />
          </div>
          {isError ? (
            <>
              <div className="pt-6 flex justify-center">
                <Text size="large" weight="bold">
                  {t("claimComplete_error_title")}
                </Text>
              </div>
              <div className="pt-4">
                <Text size="medium">{t("claimComplete_error_description")}</Text>
              </div>
            </>
          ) : (
            <>
              <div className="pt-6 flex justify-center">
                <Text size="large" weight="bold">
                  {t("claimComplete_title")}
                </Text>
              </div>
              <div className="pt-4">
                <Text size="medium">{t("claimComplete_description")}</Text>
              </div>
            </>
          )}
        </Box>
      </Paper>
    </Box>
  );
};

export default ClaimComplete;
