import withAsync from "components/withAsync";
import LaneBenchmark from "features/lane-benchmark/LaneBenchmark";

import { useRequestManagementSlices } from "../../store/requestManagementStore";
import PageLayout from "../../views/PageLayout";
import QuoteListContainer from "./QuoteListContainer";
import QuoteListControlsContainer from "./QuoteListControlsContainer";
import QuoteManagementAppBar from "./QuoteManagementAppBar";
import QuoteRequestHeader from "./QuoteRequestHeader";

const QuotesIndexPage = withAsync({
  useHook: () => {
    useRequestManagementSlices();
    return {
      isLoading: false,
    };
  },
  Component: () => {
    return (
      <PageLayout
        header={[<QuoteManagementAppBar />, <QuoteRequestHeader />]}
        leftContent={[<QuoteListControlsContainer />, <LaneBenchmark quoteRequest={null} />, <QuoteListContainer />]}
      />
    );
  },
});

export default QuotesIndexPage;
