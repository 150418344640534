import { BillOfLadingCargoData, BillOfLadingFormData } from "api/rest/bill-of-lading/types";
import { RequiredNotNull } from "api/types/generated-types";
import { palletPackagingTypeOptions } from "app/pages/shipments/components/EditableShipmentPackageGroupElements";
import {
  Shipment,
  ShipmentAccessorialType,
  ShipmentMode,
  ShipmentPackagingType,
  ShipmentPalletType,
  ShipmentStop,
} from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import compact from "lodash/compact";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";
import { TFunction } from "react-i18next";

import { FormData, initialState, PackingGroup, ShipLocation, State } from "../store/generateBOLSlice";

const mapPalletTypeToXDimensions: Record<ShipmentPalletType, number | undefined> = {
  PALLET_36_36: 36,
  PALLET_40_40: 40,
  PALLET_40_48: 40,
  PALLET_42_42: 42,
  PALLET_44_44: 44,
  PALLET_45_455: 45,
  PALLET_48_20: 48,
  PALLET_48_36: 48,
  PALLET_48_40: 48,
  PALLET_48_42: 48,
  PALLET_48_45: 48,
  PALLET_48_48: 48,
  PALLET_NOT_SPECIFIED: undefined,
};
const mapPalletTypeToYDimensions: Record<ShipmentPalletType, number | undefined> = {
  PALLET_36_36: 36,
  PALLET_40_40: 40,
  PALLET_40_48: 48,
  PALLET_42_42: 42,
  PALLET_44_44: 44,
  PALLET_45_455: 455,
  PALLET_48_20: 20,
  PALLET_48_36: 36,
  PALLET_48_40: 40,
  PALLET_48_42: 42,
  PALLET_48_45: 45,
  PALLET_48_48: 48,
  PALLET_NOT_SPECIFIED: undefined,
};

export const mapShipmentStopToShipLocation = (stop: ShipmentStop): ShipLocation => {
  return {
    stopId: stop.id,
    companyName: stop.address?.name,
    date: stop.start,
    includeOperationsContact: false,
    includeStopNotes: false,
    operationsContact: {
      email: stop.address?.contact?.email,
      firstName: stop.address?.contact?.firstName,
      lastName: stop.address?.contact?.lastName,
      phoneNumber: stop.address?.contact?.phoneNumber,
    },
    stopNotes: compact([stop.note, stop.bookingNotes]).join("\n\n") ?? undefined,
    referenceNumber: stop.referenceNumber ?? undefined,
  };
};

export const mapShipmentStopToFormData = (
  stop: ShipmentStop,
  location: "pickup" | "delivery"
): Partial<Pick<FormData, "shipFrom" | "shipTo">> => {
  if (location === "delivery") {
    return { shipTo: mapShipmentStopToShipLocation(stop) };
  }

  return { shipFrom: mapShipmentStopToShipLocation(stop) };
};

export const mapShipmentToCargo = (shipment: Shipment): PackingGroup[] | undefined => {
  if (shipment.mode === ShipmentMode.Ltl) {
    return shipment.loadSpec.packageGroups?.map(
      (packageGroup): PackingGroup => ({
        class: packageGroup.freightClass,
        commodities: [
          packageGroup.commodities,
          !packageGroup.isStackable && palletPackagingTypeOptions.includes(packageGroup.packagingType)
            ? "DO NOT STACK"
            : false,
        ]
          .filter(Boolean)
          .join(", "),
        handlingCount: packageGroup.itemQuantity,
        handlingItem: packageGroup.packagingType,
        nmfc: packageGroup.nmfcCode,
        weight: packageGroup.weightPerPackage * packageGroup.itemQuantity,
        xDimension: packageGroup.widthPerPackage,
        yDimension: packageGroup.lengthPerPackage,
        zDimension: packageGroup.heightPerPackage,
      })
    );
  }

  return [
    {
      commodities: shipment.loadSpec.commodities,
      item: shipment.loadSpec.packagingType,
      count: shipment.loadSpec.isPalletized ? shipment.loadSpec.palletCount : shipment.loadSpec.packingCount,
      weight: shipment.loadSpec.totalWeight,
      xDimension: shipment.loadSpec.palletType ? mapPalletTypeToXDimensions[shipment.loadSpec.palletType] : undefined,
      yDimension: shipment.loadSpec.palletType ? mapPalletTypeToYDimensions[shipment.loadSpec.palletType] : undefined,
      handlingItem: shipment.loadSpec.palletType ? ShipmentPackagingType.Pallets : undefined,
      handlingCount: shipment.loadSpec.palletCount,
    },
  ];
};

export const mapShipmentToFormData = (shipment: Shipment | undefined): State => {
  const loadId = shipment?.trucks?.[0]?.id;

  if (!shipment || !loadId) {
    return initialState;
  }

  if (
    shipment.mode !== ShipmentMode.Ftl &&
    shipment.mode !== ShipmentMode.Ltl &&
    shipment.mode !== ShipmentMode.Intermodal
  ) {
    return initialState;
  }

  return {
    shipment,
    mode: shipment.mode,
    loadId,
    formData: {
      bolNumber: shipment.portexId,
      shipFrom: mapShipmentStopToShipLocation(shipment.stops[0]),
      shipTo: mapShipmentStopToShipLocation(shipment.stops[1]),
      freightTerms: "Prepaid",
      shipFromAccessorials: shipment.accessorials
        ?.filter((accessorial) => accessorial.type === ShipmentAccessorialType.Pickup)
        .map((accessorial) => accessorial.name)
        .join(", "),
      shipToAccessorials: shipment.accessorials
        ?.filter((accessorial) => accessorial.type === ShipmentAccessorialType.Delivery)
        .map((accessorial) => accessorial.name)
        .join(", "),
      loadId: shipment?.trucks?.[0].referenceNumber ?? undefined,
      poReferenceNumber: shipment.referenceNumber,
      cargo: mapShipmentToCargo(shipment),
      carrier: shipment.sourceType === "quote" ? shipment.bookedQuote.carrierName : undefined,
      partner: shipment.partner.company_name ?? undefined,
    },
  };
};

const createOperationsContactString = (
  operationsContact: ShipLocation["operationsContact"] | undefined
): string | undefined => {
  if (!operationsContact) {
    return undefined;
  }

  const name = [operationsContact?.firstName, operationsContact?.lastName].filter(Boolean).join(" ");
  const contactInfo = [operationsContact?.email, operationsContact?.phoneNumber].filter(Boolean).join(", ");

  return [name, contactInfo].filter(Boolean).join(", ");
};

export const mapStateToApiRequest = (
  { shipment, formData }: RequiredNotNull<Pick<State, "shipment" | "formData">>,
  t: TFunction<"shipper">
): BillOfLadingFormData => ({
  shipFromCompanyName: formData.shipFrom.companyName,
  shipFromAddress: formatAddress(
    shipment?.stops.find((stop) => stop.id === formData?.shipFrom.stopId)?.address,
    "long"
  ),
  shipFromOperationsContact: createOperationsContactString(formData.shipFrom.operationsContact),
  shipFromNotes: formData.shipFrom.includeStopNotes ? formData.shipFrom.stopNotes : undefined,
  shipFromDate: formData.shipFrom.date
    ? DateTime.fromISO(formData.shipFrom.date).toLocaleString(DateTime.DATE_SHORT)
    : undefined,
  shipFromReferenceNumber: formData.shipFrom.referenceNumber,

  shipToCompanyName: formData.shipTo.companyName,
  shipToAddress: formatAddress(shipment?.stops.find((stop) => stop.id === formData?.shipTo.stopId)?.address, "long"),
  shipToOperationsContact: createOperationsContactString(formData.shipTo.operationsContact),
  shipToNotes: formData.shipTo.includeStopNotes ? formData.shipTo.stopNotes : undefined,
  shipToReferenceNumber: formData.shipTo.referenceNumber,

  thirdPartyFreightBillTo: formData.thirdPartyBillTo,
  freightTermsCollect: formData.freightTerms === "Collect",
  freightTermsMasterBol: formData.freightTerms === "Master BOL",
  freightTermsPrepaid: formData.freightTerms === "Prepaid",
  freightTermsThirdParty: formData.freightTerms === "3rd Party",

  specialInstructions: formData.specialInstructions,

  pickupAccessorials: !!formData.shipFromAccessorials ? [formData.shipFromAccessorials] : undefined,
  deliveryAccessorials: !!formData.shipToAccessorials ? [formData.shipToAccessorials] : undefined,

  billOfLadingNumber: formData.bolNumber,

  carrierName: formData.carrier,
  partnerName: formData.partner,
  trailerNumber: formData.trailerNumber,
  loadId: formData.loadId,
  poNumber: formData.poReferenceNumber,
  additionalReferences: formData.additionalReferences,

  cargo: formData.cargo?.map((cargo) => {
    const dimensionsList = [cargo.xDimension, cargo.yDimension, cargo.zDimension].filter(Boolean);
    const dimensions = dimensionsList.length ? dimensionsList.join("in x").concat("in") : undefined;

    const cargoData: BillOfLadingCargoData = {
      class: cargo.class?.split("_").slice(1).join("."),
      dimensions,
      weight: cargo.weight,
      weightUnit: !isEmpty(cargo) ? "LB" : undefined,
      hazardous: !isEmpty(cargo) ? formData.isHazardous : undefined,
      item: cargo.item ? t(`packingTypeMap.${cargo.item}`) : undefined,
      count: cargo.count,
      nmfc: cargo.nmfc,
      commodities: cargo.commodities?.split(",").join(", "),
      handlingCount: cargo.handlingCount,
      handlingItem: cargo.handlingItem ? t(`packingTypeMap.${cargo.handlingItem}`) : undefined,
    };

    return cargoData;
  }),
  totalWeight: formData.cargo?.reduce((weight, cargo) => weight + (cargo.weight ?? 0), 0),
  weightUnit: "LB",

  declaredValueAmount: formData.declaredValueAmount,
  declaredValueUnit: formData.declaredValueUnit,

  remitCashOnDeliveryTo: formData.remitCodTo,
  cashOnDeliveryAmount: formData.codAmount,
  feeTermsPrepaid: formData.feeTerms === "prepaid",
  feeTermsCollect: formData.feeTerms === "collect",
  feeTermsCustomerCheck: formData.feeTerms === "customerCheckAcceptable",
});
