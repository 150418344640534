import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Shipment, ShipmentFreightClass, ShipmentPackagingType, ShipmentStop } from "app/pages/shipments/types/domain";
import { ModeShipments } from "types/Mode";
import usableActions from "utils/store/usableActions";

import { mapShipmentStopToFormData, mapShipmentToFormData } from "../utils";

export type PackingGroup = {
  count?: number;
  item?: ShipmentPackagingType;
  handlingCount?: number;
  handlingItem?: ShipmentPackagingType;
  weight?: number;
  xDimension?: number;
  yDimension?: number;
  zDimension?: number;
  commodities?: string;
  nmfc?: string;
  class?: ShipmentFreightClass;
};

export type ShipLocation = {
  stopId: ShipmentStop["id"];
  companyName?: string;
  includeOperationsContact?: boolean;
  operationsContact?: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phoneNumber?: string;
  };
  includeStopNotes?: boolean;
  stopNotes?: string;
  date?: string;
  referenceNumber?: string;
};

export type FreightTerms = "Prepaid" | "Collect" | "3rd Party" | "Master BOL";
export type FeeTerms = "prepaid" | "collect" | "customerCheckAcceptable";

type FtlFormData = {
  cargo?: PackingGroup[];
  shipFrom: ShipLocation;
  shipTo: ShipLocation;
  carrier?: string;
  partner?: string;
  trailerNumber?: string;
  loadId?: string;
  poReferenceNumber?: string;
  additionalReferences?: string;
  thirdPartyBillTo?: string;
  freightTerms?: FreightTerms;
  specialInstructions?: string;
  shipFromAccessorials?: string;
  shipToAccessorials?: string;
  declaredValueAmount?: number;
  declaredValueUnit?: string;
  remitCodTo?: string;
  codAmount?: number;
  feeTerms?: FeeTerms;
  isHazardous?: boolean;
  bolNumber?: string;
};
type LtlFormData = {
  cargo?: PackingGroup[];
  shipFrom: ShipLocation;
  shipTo: ShipLocation;
  carrier?: string;
  partner?: string;
  trailerNumber?: string;
  loadId?: string;
  poReferenceNumber?: string;
  additionalReferences?: string;
  thirdPartyBillTo?: string;
  freightTerms?: FreightTerms;
  specialInstructions?: string;
  shipFromAccessorials?: string;
  shipToAccessorials?: string;
  declaredValueAmount?: number;
  declaredValueUnit?: string;
  remitCodTo?: string;
  codAmount?: number;
  feeTerms?: FeeTerms;
  isHazardous?: boolean;
  bolNumber?: string;
};

export type FormData = LtlFormData | FtlFormData;

const initialFtlFormData: FtlFormData | undefined = undefined;
const initialLtlFormData: LtlFormData | undefined = undefined;

const mapModeToInitialFormState: Record<ModeShipments, FormData | undefined> = {
  FTL: initialFtlFormData,
  INTERMODAL: initialFtlFormData,
  LTL: initialLtlFormData,
  DRAYAGE: undefined,
};

type FtlState = {
  formData: FtlFormData | undefined;
  mode: Extract<ModeShipments, "FTL" | "INTERMODAL">;
};

type LTLState = {
  formData: LtlFormData | undefined;
  mode: Extract<ModeShipments, "LTL">;
};

type DrayageState = {
  formData: undefined;
  mode: Extract<ModeShipments, "DRAYAGE">;
};

export type State = {
  shipment?: Shipment;
  loadId?: Required<Shipment>["trucks"][number]["id"];
} & (FtlState | LTLState | DrayageState);

export const initialState: State = {
  mode: "FTL" as ModeShipments,
  formData: initialFtlFormData,
};

const generateBOLSlice = createSlice({
  name: "generateBOLSlice",
  initialState,
  reducers: {
    resetState: (_state, action: PayloadAction<Shipment | undefined>) => {
      const shipment = action.payload;

      return mapShipmentToFormData(shipment);
    },
    changeLoad: (state, action: PayloadAction<Required<Shipment>["trucks"][number]["id"]>) => {
      const loadId = action.payload;
      const load = state.shipment?.trucks?.find((truck) => truck.id === loadId);

      if (!load) {
        return;
      }

      state.loadId = loadId;

      if (state.formData) {
        state.formData.loadId = load.referenceNumber ?? undefined;
      }
    },
    changeFormData: (state, action: PayloadAction<Partial<FormData>>) => {
      if (!state.formData) {
        return;
      }

      state.formData = {
        ...state.formData,
        ...action.payload,
      };
    },
    changeStop: (state, action: PayloadAction<{ stopId: ShipmentStop["id"]; location: "pickup" | "delivery" }>) => {
      if (!state.formData || !state.shipment) {
        return;
      }

      const stop = state.shipment.stops.find((stop) => stop.id === action.payload.stopId);

      if (!stop) {
        return;
      }

      state.formData = {
        ...state.formData,
        ...mapShipmentStopToFormData(stop, action.payload.location),
      };
    },
    changeShipLocation: (
      state,
      action: PayloadAction<{ data: Partial<ShipLocation>; location: "pickup" | "delivery" }>
    ) => {
      if (!state.formData || !state.shipment) {
        return;
      }

      const field: "shipFrom" | "shipTo" = action.payload.location === "delivery" ? "shipTo" : "shipFrom";

      state.formData[field] = { ...state.formData[field], ...action.payload.data };
    },
    changeCargoDetail: (state, action: PayloadAction<{ data: Partial<PackingGroup>; index: number }>) => {
      if (!state.formData?.cargo?.[action.payload.index]) {
        return;
      }

      state.formData.cargo[action.payload.index] = {
        ...state.formData.cargo[action.payload.index],
        ...action.payload.data,
      };
    },
    removeCargo: (state, action: PayloadAction<{ index: number }>) => {
      if (!state.formData?.cargo?.[action.payload.index]) {
        return;
      }

      const newCargo = state.formData.cargo
        .slice(0, action.payload.index)
        .concat(state.formData.cargo.slice(action.payload.index + 1));

      state.formData.cargo = newCargo;
    },
    changeMode: (state, action: PayloadAction<ModeShipments>) => {
      state.mode = action.payload;
      state.formData = mapModeToInitialFormState[action.payload];
    },
    changeShipment: (state, action: PayloadAction<Shipment>) => {
      state.shipment = action.payload;
    },
  },
});

export const {
  useResetState,
  useChangeLoad,
  useChangeFormData,
  useChangeStop,
  useChangeShipLocation,
  useChangeCargoDetail,
  useChangeMode,
  useChangeShipment,
  useRemoveCargo,
} = usableActions(generateBOLSlice.actions);
export default generateBOLSlice;
