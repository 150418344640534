import { Step, StepLabel, Stepper } from "@portex-pro/ui-components";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import { useRequestManagementSliceSelector } from "../../store/requestManagementStore";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const QuoteRequestSidebar = withAsync({
  useHook: () => {
    return {
      isLoading: false,
    };
  },
  Component: () => {
    const { t } = useTranslation("quoteRequest");
    const currentStep = useRequestManagementSliceSelector((state) => state.quoteRequestSlice.step);

    const steps = [
      {
        step: undefined,
        label: t("lane"),
      },
      {
        step: QuoteRequestPageSteps.Routing,
        label: t(`QuoteRequestPageSteps_${QuoteRequestPageSteps.Routing}`),
      },
      {
        step: QuoteRequestPageSteps.Shipment,
        label: t(`QuoteRequestPageSteps_${QuoteRequestPageSteps.Shipment}`),
      },
      {
        step: QuoteRequestPageSteps.Partners,
        label: t(`QuoteRequestPageSteps_${QuoteRequestPageSteps.Partners}`),
      },
      {
        step: QuoteRequestPageSteps.Team,
        label: t(`QuoteRequestPageSteps_${QuoteRequestPageSteps.Team}`),
      },
      {
        step: QuoteRequestPageSteps.Review,
        label: t(`QuoteRequestPageSteps_${QuoteRequestPageSteps.Review}`),
      },
      {
        step: QuoteRequestPageSteps.Confirmation,
        hide: true,
      },
    ];

    return (
      <div className="mt-1">
        <Stepper activeStep={steps.findIndex(({ step }) => currentStep === step)} orientation="vertical">
          {steps
            .filter((step) => !step.hide)
            .map((step, i) => {
              return (
                <Step key={`${step}_${i}`}>
                  <StepLabel style={{ userSelect: "none" }}>{step.label}</StepLabel>
                </Step>
              );
            })}
        </Stepper>
      </div>
    );
  },
});

export default QuoteRequestSidebar;
