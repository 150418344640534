import { useMemo } from "react";

import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import { useUpdateQuoteRequestMutation } from "pages/shipper/pages/request-quote/pages/ltl/api/generated/updateQuoteRequest.generated";
import { useParams } from "react-router-dom";

import { QuoteManagementPageParams } from "../../types/QuoteManagementPageParams";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLoadQuoteRequestAndQuote = () => {
  const [_, { isLoading: isUpdatingQuoteRequest }] = useUpdateQuoteRequestMutation();
  const { quoteRequestId, quoteId } = useParams<QuoteManagementPageParams>();

  const { data } = useGetShipperQuoteRequestQuery(quoteRequestId ? { urlParams: { quoteRequestId } } : skipToken);
  const quoteRequest = data?.data.quoteRequest;

  const selectedQuote = useMemo(
    () => quoteRequest?.quotes.find((quote) => quote.mode === "DRAYAGE" && quote.drayage_quote_id === Number(quoteId)),
    [quoteId, quoteRequest]
  );

  if (!quoteRequest || isUpdatingQuoteRequest || !selectedQuote) {
    return {
      isLoading: true,
    };
  }

  return {
    isLoading: false,
    quoteRequest,
    quote: selectedQuote,
  };
};

export default useLoadQuoteRequestAndQuote;
