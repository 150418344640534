import { configureSlices } from "utils/store/configureSlices";

import cargoGroupsSlice from "./cargoGroupsSlice";

export const cargoGroupsSlicesMap = {
  cargoGroupsSlice,
};

export const { useSlices: useCargoGroupsSlices, useSliceSelector: useCargoGroupsSliceSelector } =
  configureSlices(cargoGroupsSlicesMap);
