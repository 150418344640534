import { VFC } from "react";

import { Clear } from "@material-ui/icons";
import { IconButton, IconButtonProps } from "@portex-pro/ui-components";

const ClearButtonView: VFC<IconButtonProps> = (props) => (
  <IconButton {...props}>
    <Clear />
  </IconButton>
);

export default ClearButtonView;
