import { EM_DASH } from "constants/index";

import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { ChatStatusUpdateShipmentDetailsUpdated } from "components/chat/types/ChatStatusUpdates";
import Text from "components/Text";
import isArray from "lodash/isArray";
import { useTranslation } from "react-i18next";

import { useStatusUpdateStyles } from "./statusUpdateStyles";

const StatusUpdateShipmentDetailsUpdatedView: VFC<{
  params: ChatStatusUpdateShipmentDetailsUpdated["notification_params"];
}> = ({ params }) => {
  const { t } = useTranslation("chat");
  const classes = useStatusUpdateStyles();

  return (
    <div className={classes["shipment-details-updated"]}>
      <div>
        <Info />
      </div>
      <Text size="small" weight="bold">
        {t(
          params.isEmailSent === false
            ? "statusUpdates_shipmentDetailsUpdated_noEmail_text"
            : "statusUpdates_shipmentDetailsUpdated_text"
        )}
        <ul>
          {params.changes.map((change, index) => {
            const key = `change-${index}-${change.label}`;
            return (
              <li key={key}>
                {change.label}:{" "}
                {isArray(change.value) ? (
                  <>
                    <br />
                    <ul>
                      <li>
                        {change.value.map((change, line) => (
                          <div key={`${key}-line-${line}`}>{change}</div>
                        ))}
                      </li>
                    </ul>
                  </>
                ) : (
                  change.value || EM_DASH
                )}
              </li>
            );
          })}
        </ul>
      </Text>
    </div>
  );
};

export default StatusUpdateShipmentDetailsUpdatedView;
