import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { DuplicateQuoteRequestBody, ShipperQuoteRequest, getShipperQuoteRequestApi } from "api/rest/quote-requests";
import usableActions from "utils/store/usableActions";

type State = {
  isDuplicatingQuoteRequest: boolean;
  canDuplicateQuoteRequest: boolean;
  stopsForDuplicateQuoteRequest: DuplicateQuoteRequestBody["stops"];
  _mode?: ShipperQuoteRequest["mode"];
};

const initialState: State = {
  isDuplicatingQuoteRequest: false,
  canDuplicateQuoteRequest: false,
  stopsForDuplicateQuoteRequest: [],
};

const initialStopChanges: State["stopsForDuplicateQuoteRequest"][0] = {
  start: null,
  is_time_tbd: false,
  end: null,
  cargo_ready_date: null,
  target_delivery_date: null,
};

function checkCanDuplicateQuoteRequest(state: State): boolean {
  if (!state._mode || !state.stopsForDuplicateQuoteRequest.length) {
    return false;
  }

  if (state._mode === "FCL" || state._mode === "AIR") {
    return !!state.stopsForDuplicateQuoteRequest[0].cargo_ready_date;
  }

  return true;
}

const slice = createSlice({
  name: "quoteRequestManagementSlice",
  initialState,
  reducers: {
    setIsDuplicatingQuoteRequest: (state, action: PayloadAction<State["isDuplicatingQuoteRequest"]>) => {
      state.isDuplicatingQuoteRequest = action.payload;
      if (state.isDuplicatingQuoteRequest === false) {
        state.stopsForDuplicateQuoteRequest.map((_, stopIndex) => {
          slice.caseReducers.setStopForDuplicateQuoteRequest(
            state,
            slice.actions.setStopForDuplicateQuoteRequest({ stop: initialStopChanges, stopIndex })
          );
        });
      }
    },
    setStopForDuplicateQuoteRequest: (
      state,
      action: PayloadAction<{ stop: State["stopsForDuplicateQuoteRequest"][0]; stopIndex: number }>
    ) => {
      state.stopsForDuplicateQuoteRequest[action.payload.stopIndex] = action.payload.stop;
      state.canDuplicateQuoteRequest = checkCanDuplicateQuoteRequest(state);
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getShipperQuoteRequestApi.endpoints.getShipperQuoteRequest.matchFulfilled, (state, action) => {
      state._mode = action.payload.data.quoteRequest.mode;
      state.stopsForDuplicateQuoteRequest = [];
      action.payload.data.quoteRequest.stops.map((_, stopIndex) => {
        slice.caseReducers.setStopForDuplicateQuoteRequest(
          state,
          slice.actions.setStopForDuplicateQuoteRequest({ stop: initialStopChanges, stopIndex })
        );
      });
    });
  },
});

export const { useSetIsDuplicatingQuoteRequest, useSetStopForDuplicateQuoteRequest } = usableActions(slice.actions);
export default slice;
