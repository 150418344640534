import { RootState, StatesFromSlicesMap } from "types/Store";
import { configureSlices } from "utils/store/configureSlices";

import dispatchManagementSlice from "./dispatchManagementSlice";
import quoteIndexSlice from "./quoteIndexSlice";
import quoteRequestManagementSlice from "./quoteRequestManagementSlice";
import quoteRequestSlice from "./quoteRequestSlice";
import requestManagementIndexSlice from "./requestManagementIndexSlice";

export const requestManagementSlicesMap = {
  dispatchManagementSlice,
  quoteRequestManagementSlice,
  quoteRequestSlice,
  requestManagementIndexSlice,
  quoteIndexSlice,
};

export const { useSlices: useRequestManagementSlices, useSliceSelector: useRequestManagementSliceSelector } =
  configureSlices(requestManagementSlicesMap);

export type RequestManagementStateType = RootState<StatesFromSlicesMap<typeof requestManagementSlicesMap>>;
