import { EM_DASH } from "constants/index";

import { ReactElement, VFC } from "react";

import { DeleteOutline, GetApp } from "@material-ui/icons";
import { Button, Route, portexColor } from "@portex-pro/ui-components";
import { BrokerDispatchRequest, DispatchFuelOption, ShipperDispatchRequest } from "api/rest/dispatches";
import { BrokerShipment, BrokerShipmentStopDetails } from "api/rest/shipments/types";
import { Mode, WeightUnit } from "api/types/generated-types";
import {
  DriverPreference,
  Shipment,
  ShipmentMode,
  ShipmentPackagingType,
  ShipmentPalletType,
  ShipmentStop,
} from "app/pages/shipments/types/domain";
import formatAddress from "app/pages/shipments/utils/formatAddress";
import formatBrokerAddress from "app/pages/shipments/utils/formatBrokerAddress";
import { ReactComponent as IntermodalCircleOrangeIcon } from "assets/intermodal/intermodal-arrows-circle-orange-filled.svg";
import ContractTrailerTypeWithIconView from "components/ContractTrailerTypeContainer";
import { FileControlWrapper } from "components/file-uploads/FilesCache";
import LoadTypeChip from "components/LoadTypeChip";
import SummaryPaperView from "components/SummaryPaperView";
import Text from "components/Text";
import compact from "lodash/compact";
import first from "lodash/first";
import last from "lodash/last";
import { mapDriverPreferenceToCopy } from "pages/shipper/utils/mapDriverPreferenceToCopy";
import { Trans, useTranslation } from "react-i18next";
import { ModeRfp } from "types/Mode";
import { TrailerSize } from "types/TrailerSize";
import { TrailerType } from "types/TrailerType";
import { downloadBlobFromUrl } from "utils/downloadBlobFromUrl";
import { formatCommodities } from "utils/formatCommodities";
import { formatUSD } from "utils/formatCurrency";
import { formatDateString } from "utils/formatDateString";
import VerticalStopsInfo from "views/stops/VerticalStopsInfo";

type Stops =
  | {
      brokerStops: BrokerShipmentStopDetails[];
      type: "broker";
    }
  | {
      shipperStops: ShipmentStop[];
      type: "shipper";
    };

type FileBaseProps<File> = {
  attachments: File[] | undefined;
  getAttachmentDetails: (file: File) => { name: string; url: string };
  onDeleteFile?: (file: File) => void | Promise<void>;
};

type ToUndefined<T> = { [Key in keyof T]: undefined };

type FileProps<File> = FileBaseProps<File> | ToUndefined<FileBaseProps<File>>;

type TenderDetailsProps<File> = Stops &
  FileProps<File> & {
    contract_request_title?: string;
    portex_id: string;
    shipperName: string;
    contract_request_start?: string;
    contract_request_end?: string;
    rate: number;
    fuel_cost?: number;
    mode: Mode | ShipmentMode;
    truck_quantity: number;
    reference_number?: string;
    load_spec: {
      trailer_size?: TrailerSize | null;
      trailer_type?: TrailerType;
      commodities?: string;
      is_palletized?: boolean;
      packaging_type?: ShipmentPackagingType;
      packaging_count?: number;
      driver_preference?: DriverPreference;
      pallet_count?: number;
      pallet_type?: ShipmentPalletType;
      total_weight?: number;
      weight_unit?: WeightUnit;
    };
    request_note?: string;
    fuel_option?: DispatchFuelOption;
  };

const TenderDetails = <File,>(props: TenderDetailsProps<File>): ReactElement | null => {
  const {
    contract_request_title,
    portex_id,
    shipperName,
    contract_request_start,
    contract_request_end,
    rate,
    fuel_cost,
    mode,
    truck_quantity,
    reference_number,
    load_spec: {
      trailer_size,
      trailer_type,
      commodities,
      packaging_type,
      driver_preference,
      is_palletized,
      packaging_count,
      pallet_count,
      pallet_type,
      total_weight,
      weight_unit,
    },
    request_note,
    fuel_option,
  } = props;
  const { t } = useTranslation(["dispatchRequest", "shipper", "common"]);

  const isFuelIncludedInPartnerBid = fuel_option === "PARTNER_BID";
  const isFuelRequestedFromPartner = !fuel_option || fuel_option === "PARTNER_RESPONSE";

  return (
    <SummaryPaperView
      header={
        <div className="flex flex-col items-center space-y-1.5">
          <Text size="x-large" weight="bold">
            {t("dispatchRequest:tenderRequest")}
          </Text>
          <Text size="small">{shipperName}</Text>
        </div>
      }
      description={
        <Route
          pickup={
            props.type === "broker"
              ? first(props.brokerStops)?.name || formatBrokerAddress(first(props.brokerStops), "short")
              : formatAddress(first(props.shipperStops)?.address, "short")
          }
          delivery={
            props.type === "broker"
              ? last(props.brokerStops)?.name || formatBrokerAddress(last(props.brokerStops), "short")
              : formatAddress(last(props.shipperStops)?.address, "short")
          }
          style={{ width: "100%" }}
          // @ts-expect-error: This doesn't match the svg icon type, but it works
          iconProps={
            mode === "INTERMODAL"
              ? {
                  as: IntermodalCircleOrangeIcon,
                  palette: "gold",
                }
              : undefined
          }
        />
      }
      ColumnListViewProps={{
        title: (
          <div className="flex justify-between">
            <Text size="medium" weight="bold">
              {t("dispatchRequest:tenderDetails")}
            </Text>
            <Text size="medium">{t("dispatchRequest:tenderDetails_portexId", { id: portex_id })}</Text>
          </div>
        ),
        rows: [
          {
            label: t("dispatchRequest:tenderDetails_rfp"),
            value: (
              <Text size="medium" weight="bold">
                {contract_request_title}
                {(!!contract_request_start || !!contract_request_end) &&
                  " // " +
                    compact([contract_request_start, contract_request_end]).map(formatDateString).join(` ${EM_DASH} `)}
              </Text>
            ),
          },
          {
            label: t("dispatchRequest:poRefNumber"),
            value: reference_number || EM_DASH,
          },
          {
            label: t("common:attachments"),
            value: (
              <div className="flex flex-col">
                {props.attachments && props.attachments.length
                  ? props.attachments.map((attachment, i) => {
                      const { name, url } = props.getAttachmentDetails(attachment);

                      return (
                        <div className="flex justify-between" key={i}>
                          <Text
                            size="medium"
                            weight="bold"
                            typographyProps={{ style: { padding: "0.5rem 0", color: portexColor.blue500 } }}
                          >
                            {name}
                          </Text>
                          <div>
                            {!!props.onDeleteFile && (
                              <Button
                                style={{ color: portexColor.blue500 }}
                                startIcon={<DeleteOutline />}
                                onClick={() => props.onDeleteFile?.(attachment)}
                              >
                                {t("common:delete")}
                              </Button>
                            )}
                            <Button
                              style={{ color: portexColor.blue500 }}
                              startIcon={<GetApp />}
                              onClick={() => downloadBlobFromUrl(url, name)}
                            >
                              {t("common:download")}
                            </Button>
                          </div>
                        </div>
                      );
                    })
                  : EM_DASH}
              </div>
            ),
          },
          {
            label: `${t("dispatchRequest:tenderDetails_total")} ${
              !isFuelIncludedInPartnerBid && !fuel_cost
                ? t("dispatchRequest:tenderDetails_withoutFuel")
                : t("dispatchRequest:tenderDetails_includingFuel")
            }`,
            value: (
              <Text size="medium" weight="bold">
                {formatUSD(rate + (fuel_cost ?? 0))}
              </Text>
            ),
          },
          {
            label: t("dispatchRequest:tenderDetails_baseRate"),
            value: (
              <Text size="medium" weight="bold">
                {formatUSD(rate)}
              </Text>
            ),
          },
          {
            label: t("dispatchRequest:tenderDetails_fuelSurcharge"),
            hide: isFuelIncludedInPartnerBid,
            value: !!fuel_cost ? (
              <Text size="medium">
                <Trans
                  t={t}
                  i18nKey={"dispatchRequest:tenderDetails_shipperFuel"}
                  components={[
                    <Text size="medium" weight="bold" typographyProps={{ style: { display: "inline" } }}>
                      {{ amount: formatUSD(fuel_cost) }}
                    </Text>,
                  ]}
                />
              </Text>
            ) : isFuelRequestedFromPartner ? (
              t("dispatchRequest:tenderDetails_pendingFuelByPartner")
            ) : (
              t("dispatchRequest:tenderDetails_pendingFuelByShipper")
            ),
          },
          {
            label: t("dispatchRequest:tenderDetails_mode"),
            value: <LoadTypeChip mode={mode} verbose={mode === "FTL"} textProps={{ size: "medium", weight: "bold" }} />,
            BoxProps: { style: { alignItems: "center" } },
          },
          {
            label: t("dispatchRequest:tenderDetails_equipment"),
            value:
              trailer_size !== undefined && trailer_type ? (
                <ContractTrailerTypeWithIconView
                  contract={{
                    trailer_size: trailer_size,
                    trailer_type: trailer_type,
                    mode: mode as ModeRfp, // We should lift this component out of RFP to be more generic, and then we wouldn't need to do this casting
                  }}
                />
              ) : (
                EM_DASH
              ),
          },
          {
            label: t("dispatchRequest:tenderDetails_numberOfTrucks"),
            value: truck_quantity
              ? t("dispatchRequest:tenderDetails_numberOfTrucks", { count: truck_quantity })
              : EM_DASH,
          },
          {
            label: t("dispatchRequest:tenderDetails_locations"),
            value: <VerticalStopsInfo.BrokerShipmentStops stops={props.type === "broker" ? props.brokerStops : []} />,
            hide: props.type !== "broker",
          },
          {
            label: t("dispatchRequest:tenderDetails_locations"),
            value: <VerticalStopsInfo.ShipmentStops stops={props.type === "shipper" ? props.shipperStops : []} />,
            hide: props.type !== "shipper",
          },
          {
            label: t("shipper:driverPreference"),
            value: mapDriverPreferenceToCopy[driver_preference || DriverPreference.None],
          },
          {
            label: t("dispatchRequest:tenderDetails_packingMethod"),
            value: is_palletized ? t("shipper:palletized") : t("shipper:floorLoaded"),
          },
          {
            label: t("dispatchRequest:palletType"),
            value: pallet_type ? t(`shipper:palletTypeMap.${pallet_type}`) : EM_DASH,
          },
          {
            label: t("dispatchRequest:palletCount"),
            value: pallet_count ?? EM_DASH,
          },
          {
            label: t("dispatchRequest:tenderDetails_packingType"),
            value: packaging_type ? t(`shipper:packingTypeMap.${packaging_type}`) : EM_DASH,
          },
          {
            label: t("dispatchRequest:packingCount"),
            value: packaging_count ?? EM_DASH,
          },
          {
            label: t("dispatchRequest:totalWeight"),
            value: total_weight
              ? t("dispatchRequest:totalWeight", { value: total_weight, context: weight_unit })
              : EM_DASH,
          },
          {
            label: t("dispatchRequest:tenderDetails_commodities"),
            value: formatCommodities(commodities),
          },
          {
            label: t("dispatchRequest:tenderDetails_additionalNotes"),
            value: request_note ? request_note : EM_DASH,
          },
        ],
      }}
    />
  );
};

type BrokerFileType = { name: string; url: string };
interface Broker {
  shipperName: string;
  dispatchRequest: BrokerDispatchRequest;
  shipment: BrokerShipment;
  attachments?: BrokerFileType[];
}

const Broker: VFC<Broker> = (props) => {
  const { dispatchRequest, shipment, shipperName, attachments } = props;

  return (
    <TenderDetails<BrokerFileType>
      shipperName={shipperName}
      {...shipment}
      {...dispatchRequest}
      {...shipment.load_spec}
      type="broker"
      brokerStops={shipment.stops}
      attachments={attachments}
      getAttachmentDetails={(attachment) => attachment}
      fuel_option={dispatchRequest.fuel_option}
    />
  );
};

interface Shipper {
  shipperName?: string;
  dispatchRequest: ShipperDispatchRequest;
  shipment: Shipment;
  attachments?: FileControlWrapper[];
  onDeleteAttachment?: (file: FileControlWrapper) => void | Promise<void>;
}

const Shipper: VFC<Shipper> = (props) => {
  const { shipperName, dispatchRequest, shipment, attachments, onDeleteAttachment } = props;

  return (
    <TenderDetails
      portex_id={dispatchRequest.portex_id}
      contract_request_title={dispatchRequest.contract_request?.title}
      fuel_cost={dispatchRequest.fuel_cost}
      request_note={dispatchRequest.request_note}
      type="shipper"
      shipperStops={shipment.stops}
      shipperName={shipperName ?? ""}
      rate={dispatchRequest.award_amount}
      reference_number={shipment.referenceNumber}
      load_spec={{
        commodities: shipment.loadSpec.commodities,
        is_palletized: shipment.loadSpec.isPalletized,
        driver_preference: shipment.loadSpec.driverPreference,
        packaging_type: shipment.loadSpec.packagingType,
        trailer_size: shipment.loadSpec.trailerSize,
        trailer_type: shipment.loadSpec.trailerType,
        packaging_count: shipment.loadSpec.packingCount,
        pallet_count: shipment.loadSpec.palletCount,
        pallet_type: shipment.loadSpec.palletType,
        total_weight: shipment.loadSpec.totalWeight,
      }}
      truck_quantity={shipment.trucks?.length ?? 0}
      mode={shipment.mode}
      contract_request_start={
        shipment.sourceType === "dispatch" ? shipment.dispatchRequest.contract_request.start : undefined
      }
      contract_request_end={
        shipment.sourceType === "dispatch" ? shipment.dispatchRequest.contract_request.end : undefined
      }
      attachments={attachments}
      getAttachmentDetails={(file) => ({ name: file.file.name ?? "", url: file.file.url ?? "" })}
      onDeleteFile={onDeleteAttachment}
      fuel_option={dispatchRequest.fuel_option}
    />
  );
};

TenderDetails.Broker = Broker;
TenderDetails.Shipper = Shipper;

export default TenderDetails;
