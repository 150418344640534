export enum EquipmentType {
  All = "ALL",
  BoxTruck = "BOX_TRUCK",
  Bulk = "BULK",
  CargoVan = "CARGO_VAN",
  Conestoga = "CONESTOGA",
  Drayage = "DRAYAGE",
  Dry = "DRY",
  Dump = "DUMP",
  Flatbed = "FLATBED",
  FoodGradeTank = "FOOD_GRADE_TANK",
  Hotshot = "HOTSHOT",
  NaPartnersChoice = "NA_PARTNERS_CHOICE",
  Other = "OTHER",
  PartialLoad = "PARTIAL_LOAD",
  Reefer = "REEFER",
  Rgn = "RGN",
  SprinterVan = "SPRINTER_VAN",
  StepDeck = "STEP_DECK",
  OpenTop = "OPEN_TOP",
  FlatRack = "FLAT_RACK",
  Platform = "PLATFORM",
}
