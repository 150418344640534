import { VFC } from "react";

import { Info } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import { ShipmentStop } from "app/pages/shipments/types/domain";
import Text from "components/Text";
import { FormData, PackingGroup, ShipLocation } from "features/generate-bol/store/generateBOLSlice";
import FormSelectView from "pages/shipper/pages/request-quote/components/FormSelectView";
import { useTranslation } from "react-i18next";

import BolFormView from "./BolFormView";
import StopSelectView from "./StopSelectView";

interface BolGenerateViewProps {
  loadNumber: number;
  selectedLoadId: number;
  loadIds: number[];
  onChangeLoad: (loadId: number) => void;
  onGenerate: () => void;
  disableGenerate: boolean;

  formData: FormData;
  stops: ShipmentStop[];

  onChange: (data: Partial<FormData>) => void;
  onChangeStop: (stopId: ShipmentStop["id"], location: "pickup" | "delivery") => void;
  onChangeShipLocation: (data: Partial<ShipLocation>, location: "pickup" | "delivery") => void;
  onChangeCargoDetail: (changes: Partial<PackingGroup>, index: number) => void;
  onAddAnotherItem: () => void;
  onRemoveCargo: (index: number) => void;
}

const BolGenerateView: VFC<BolGenerateViewProps> = (props) => {
  const {
    loadNumber,
    disableGenerate,
    selectedLoadId,
    loadIds,
    onChangeLoad,
    onGenerate,
    stops,
    onChangeStop,
    formData,
  } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="w-full h-full flex flex-col">
      <div className="flex flex-col mx-8 pt-3 pb-16 overflow-y-scroll">
        <div className="flex flex-row pb-3 items-center">
          <FormSelectView
            items={loadIds}
            getItemCopy={(loadId) => {
              const loadIndex = loadIds.findIndex((id) => id === loadId);

              return t("loadN", { loadNumber: loadIndex + 1 });
            }}
            value={selectedLoadId}
            onChange={onChangeLoad}
          />
          <Text size="small" weight="bold" typographyProps={{ style: { marginLeft: "2rem", marginRight: "0.75rem" } }}>
            {t("shipFrom")}
          </Text>
          <StopSelectView
            stops={stops}
            onChange={(data) => onChangeStop(data, "pickup")}
            selectedStopId={formData.shipFrom.stopId}
            addressFormat="short"
          />
          <Text size="small" weight="bold" typographyProps={{ style: { marginLeft: "2rem", marginRight: "0.75rem" } }}>
            {t("shipTo")}
          </Text>
          <StopSelectView
            stops={stops}
            onChange={(data) => onChangeStop(data, "delivery")}
            selectedStopId={formData.shipTo.stopId}
            addressFormat="short"
          />
        </div>
        <BolFormView {...props} />
      </div>
      <div className="m-auto" />
      <div className="p-4 bg-white flex flex-row justify-between items-center border-t border-grey-300">
        <div className="flex flex-row space-x-2 items-center">
          <Info color="error" />
          <Text size="small" weight="bold">
            {t("submit_description")}
          </Text>
        </div>
        <Button variant="contained" color="primary" onClick={onGenerate} disabled={disableGenerate}>
          {t("submit_title", { loadNumber })}
        </Button>
      </div>
    </div>
  );
};

export default BolGenerateView;
