import { TextInput } from "@portex-pro/ui-components";
import Text from "components/Text";
import withAsync from "components/withAsync";
import { useTranslation } from "react-i18next";

import useLoadQuoteRequest from "../../hooks/quotes/useLoadQuoteRequest";

const ReferencesContainer = withAsync({
  useHook: useLoadQuoteRequest,
  Component: ({ loadedData: { quoteRequest } }) => {
    const { t } = useTranslation(["shipper", "common", "shipments", "quoteDetails"]);

    return (
      <div className="w-full">
        <div className="border rounded w-full bg-white">
          <div className="flex flex-row py-3 px-4 w-full border-b">
            <Text size="medium" weight="bold">
              {t("shipments:shipmentDetails_poReference_title")}
            </Text>
          </div>

          <div className="even:bg-zebra w-full flex flex-row items-center py-3 px-4">
            <div className="w-1/3 min-w-60 text-nowrap">
              <Text size="medium" weight="bold">
                {t("quoteDetails:poReferenceDetails_shipment")}
              </Text>
            </div>
            <div className="w-full">
              <TextInput fullWidth value={quoteRequest.reference_number} disabled />
            </div>
          </div>
        </div>
      </div>
    );
  },
});

export default ReferencesContainer;
