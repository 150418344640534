import compact from "lodash/compact";

import { RequestLike, StopLike } from "../types";

export const getStopLabels = (
  stop: StopLike | undefined,
  mode: RequestLike["mode"]
): { label: string; subLabel: string } => {
  const address = stop?.address;
  let label = "";
  let subLabel = "";
  if (!address) {
    return { label, subLabel };
  }
  const country = address.country_code === "US" ? address.country_code : address.country_name;
  const region = address.province_code || address.province_name || country;
  const geoLabel = compact([address.city, region]).join(", ");

  if (address.port) {
    // ports
    if (mode === "DRAYAGE") {
      label = geoLabel;
      subLabel = address.port.long;
    }
    if (mode === "FCL") {
      label = geoLabel;
      subLabel = address.port.long;
    }
    if (mode === "AIR") {
      label = geoLabel;
      subLabel = address.port.short;
    }
  } else {
    // doors
    label = geoLabel;
    subLabel = address.name || address.address_1 || address.google_place_description || "";
  }

  return {
    label,
    subLabel,
  };
};
