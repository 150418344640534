import { useContext, VFC } from "react";

import { ShipmentPackagingType } from "app/pages/shipments/types/domain";

import { CargoGroupType } from "./api/types/CargoGroupType";
import { CargoGroupContext } from "./CargoGroupProvider";
import { selectManyCargoGroups } from "./store/cargoGroupsSlice";
import { useCargoGroupsSliceSelector } from "./store/cargoGroupsStore";
import ContainerCargoGroupsView from "./views/ContainerCargoGroupsView";
import HandlingUnitsView from "./views/HandlingUnitsView";
import PackingUnitsView from "./views/PackingUnitsView";

const PackingGroup: VFC<{ cargoGroupIds: Array<number | string>; parentId: string | number }> = ({
  cargoGroupIds,
  parentId,
}) => {
  const { addCargoGroup, removeCargoGroup, updateCargoGroup } = useContext(CargoGroupContext);
  const handlingGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, cargoGroupIds));

  return (
    <PackingUnitsView
      cargoGroups={handlingGroups}
      onAddPackingUnit={() =>
        addCargoGroup({
          cargo_group_type: CargoGroupType.PACKING,
          packaging_type: ShipmentPackagingType.Bags,
          handling_unit_id: parentId,
        })
      }
      onChangePackingUnit={updateCargoGroup}
      onRemovePackingUnit={removeCargoGroup}
    />
  );
};

const HandlingGroup: VFC<{ cargoGroupIds: Array<number | string>; parentId: string | number }> = ({
  cargoGroupIds,
  parentId,
}) => {
  const { addCargoGroup, removeCargoGroup, updateCargoGroup } = useContext(CargoGroupContext);
  const handlingGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, cargoGroupIds));

  return (
    <HandlingUnitsView
      cargoGroups={handlingGroups}
      onAddHandlingUnit={() =>
        addCargoGroup({
          cargo_group_type: CargoGroupType.HANDLING,
          packaging_type: ShipmentPackagingType.Pallets,
          cargo_unit_id: parentId,
        })
      }
      onChangeHandlingUnit={updateCargoGroup}
      onRemoveHandlingUnit={removeCargoGroup}
      renderPackingUnit={PackingGroup}
    />
  );
};

const ContainerCargoGroups: VFC = () => {
  const { topLevelCargoGroupIds, addCargoGroup, updateCargoGroup, removeCargoGroup } = useContext(CargoGroupContext);

  const cargoGroups = useCargoGroupsSliceSelector((state) => selectManyCargoGroups(state, topLevelCargoGroupIds));

  return (
    <ContainerCargoGroupsView
      cargoGroups={cargoGroups}
      onAddCargoGroup={() =>
        addCargoGroup(
          { cargo_group_type: CargoGroupType.CARGO, packaging_type: ShipmentPackagingType.Containers, count: 1 },
          { isTopLevelCargoGroup: true }
        )
      }
      onDuplicate={(cargoGroup) =>
        addCargoGroup({ ...cargoGroup, handling_units: [], packing_units: [] }, { isTopLevelCargoGroup: true })
      }
      onChangeCargoGroup={updateCargoGroup}
      onRemoveCargoGroup={removeCargoGroup}
      renderHandlingUnits={HandlingGroup}
    />
  );
};

export default ContainerCargoGroups;
