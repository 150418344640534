import { getShipperDispatchApi, useModifyShipperDispatchMutation } from "api/rest/dispatches";
import { getShipmentSourcesApi } from "api/rest/shipments";
import FilesControl from "components/file-uploads/FilesControl";
import Loading from "components/Loading";
import ProgressionButtons from "components/ProgressionButtons";
import withAsync from "components/withAsync";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Sentry } from "sentry";

import { useDispatchAndShipment } from "../hooks";
import { useDispatchRequestSliceSelector } from "../store/dispatchRequestStore";
import AutoConfirmContainer from "./AutoConfirmContainer";
import MockEmail from "./MockEmail";

const ReviewAndSendStep = withAsync({
  useHook: useDispatchAndShipment,
  LoadingComponent: <Loading />,
  Component: ({ loadedData: { dispatchRequest } }) => {
    const { shipmentId, pickupRequestId } = useParams<{ shipmentId: string; pickupRequestId: string }>();
    const { t } = useTranslation(["dispatchRequest", "common"]);
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const autoConfirm = useDispatchRequestSliceSelector((state) => state.dispatchRequestSlice.autoConfirm);

    const [modifyShipperDispatch, { isLoading }] = useModifyShipperDispatchMutation();

    const onBack = () => {
      history.push(`/shipper/dispatches/create/team/dispatch-request/${pickupRequestId}/shipment/${shipmentId}`);
    };

    const onNext = async () => {
      try {
        await modifyShipperDispatch({
          body: { status: "SENT", autoConfirm },
          urlParams: { requestId: Number(pickupRequestId) },
        }).unwrap();
        dispatch(getShipmentSourcesApi.util.invalidateTags(["SOURCES"]));
        dispatch(getShipperDispatchApi.util.invalidateTags([{ type: "DISPATCH", id: Number(pickupRequestId) }]));
        history.push(`/shipper/dispatches/create/confirmation`);
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const onUploadSuccess = async (results: { fileId: string }[]) => {
      try {
        await modifyShipperDispatch({
          body: {
            request_files: [...(dispatchRequest.request_files ?? []), ...results.map((result) => result.fileId)],
          },
          urlParams: { requestId: Number(pickupRequestId) },
        }).unwrap();
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    const onDeleteSuccess = async (fileId: string) => {
      try {
        await modifyShipperDispatch({
          body: { request_files: dispatchRequest.request_files?.filter((file) => file !== fileId) ?? [] },
          urlParams: { requestId: Number(pickupRequestId) },
        }).unwrap();
      } catch (e) {
        enqueueSnackbar(t("common:errors.generic"), { variant: "error" });
        Sentry.captureException(e);
      }
    };

    return (
      <ProgressionButtons
        nextCopy={t("dispatchRequest:reviewAndSend_next")}
        onBack={onBack}
        onNext={onNext}
        nextProps={{ loading: isLoading }}
        nextAdditionalContent={<AutoConfirmContainer />}
      >
        <FilesControl
          fileIds={dispatchRequest.request_files ?? []}
          onMultiUploadSuccess={onUploadSuccess}
          onDeleteSuccess={onDeleteSuccess}
          dropzoneOptions={{
            multiple: true,
            maxFiles: 4,
            maxSize: 5e6,
          }}
        >
          <MockEmail />
        </FilesControl>
      </ProgressionButtons>
    );
  },
});

export default ReviewAndSendStep;
