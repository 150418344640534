import { VFC } from "react";

import { FormControl, InputLabel, TextInput } from "@portex-pro/ui-components";
import ClearButtonView from "components/buttons/ClearButtonView";
import Text from "components/Text";
import { FormData, ShipLocation } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

interface ReferencesViewProps {
  shipFromRef?: string;
  shipToRef?: string;
  trailerNumber?: string;
  loadId?: string;
  poReferenceNumber?: string;
  additionalReferences?: string;

  onChange: (
    data: Partial<Pick<FormData, "trailerNumber" | "loadId" | "poReferenceNumber" | "additionalReferences">>
  ) => void;
  onChangeShipLocation: (data: Partial<Pick<ShipLocation, "referenceNumber">>, location: "pickup" | "delivery") => void;
}

const ReferencesView: VFC<ReferencesViewProps> = (props) => {
  const {
    onChange,
    onChangeShipLocation,
    shipFromRef,
    shipToRef,
    loadId,
    poReferenceNumber,
    trailerNumber,
    additionalReferences,
  } = props;
  const { t } = useTranslation("bolGeneration");

  return (
    <div className="flex flex-col w-full bg-white">
      <div className="w-full py-2.5 px-5 bg-grey-100 border-b border-t border-grey-300">
        <Text
          size="medium"
          weight="bold"
          typographyProps={{ style: { textAlign: "center", textTransform: "uppercase" } }}
        >
          {t("references")}
        </Text>
      </div>
      <div className="p-5 flex flex-col space-y-2.5">
        <FormControl fullWidth>
          <InputLabel>{t("shipFromRef")}</InputLabel>
          <TextInput
            value={shipFromRef}
            onChange={(event) => onChangeShipLocation({ referenceNumber: event.target.value }, "pickup")}
            endIcon={
              shipFromRef ? (
                <ClearButtonView onClick={() => onChangeShipLocation({ referenceNumber: "" }, "pickup")} />
              ) : undefined
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("shipToRef")}</InputLabel>
          <TextInput
            value={shipToRef}
            onChange={(event) => onChangeShipLocation({ referenceNumber: event.target.value }, "delivery")}
            endIcon={
              shipToRef ? (
                <ClearButtonView onClick={() => onChangeShipLocation({ referenceNumber: "" }, "delivery")} />
              ) : undefined
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("trailerNumber")}</InputLabel>
          <TextInput
            value={trailerNumber}
            onChange={(event) => onChange({ trailerNumber: event.target.value })}
            endIcon={trailerNumber ? <ClearButtonView onClick={() => onChange({ trailerNumber: "" })} /> : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("loadId")}</InputLabel>
          <TextInput
            value={loadId}
            onChange={(event) => onChange({ loadId: event.target.value })}
            endIcon={loadId ? <ClearButtonView onClick={() => onChange({ loadId: "" })} /> : undefined}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("poReferenceNumber")}</InputLabel>
          <TextInput
            value={poReferenceNumber}
            onChange={(event) => onChange({ poReferenceNumber: event.target.value })}
            endIcon={
              poReferenceNumber ? <ClearButtonView onClick={() => onChange({ poReferenceNumber: "" })} /> : undefined
            }
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>{t("additionalReferences")}</InputLabel>
          <TextInput
            value={additionalReferences}
            multiline
            rows={3}
            rowsMax={8}
            InputProps={{ style: { paddingTop: 0 } }}
            onChange={(event) => onChange({ additionalReferences: event.target.value })}
          />
        </FormControl>
      </div>
    </div>
  );
};

export default ReferencesView;
