import { VFC } from "react";

import { Box } from "@portex-pro/ui-components";
import { ContractRequestState } from "app/pages/bid-award/types";
import DropdownFilterView from "components/DropdownFilterView";
import LastRefreshedAtText from "components/LastRefreshedAtText";
import RefetchButton from "components/RefetchButton";
import SimpleSearchView from "components/SimpleSearchView";
import ModeButtonGroup from "pages/shipper/components/ModeButtonGroup";
import { TransportationMode } from "pages/shipper/pages/quotes/types/TransportationMode";
import { useTranslation } from "react-i18next";

type ContractListFilterBarViewProps = {
  mode: TransportationMode;
  states: ContractRequestState[];
  onChangeState: (value: ContractRequestState) => void;
  onChangeMode: (value: TransportationMode) => void;
  onClickRefresh: () => void;
  lastRefreshedAt: string;
  search: string;
  onSearch: (search: string) => void;
  disableIntermodal?: boolean;
};

const ContractsListFilterBarView: VFC<ContractListFilterBarViewProps> = ({ disableIntermodal = false, ...props }) => {
  const { t: tContractStates } = useTranslation("shipper", { keyPrefix: "bids.award.contractStates" });
  const { t: tShipper } = useTranslation("shipper");

  const dropdownOptions = [
    {
      label: tContractStates(ContractRequestState.REQUESTED),
      value: ContractRequestState.REQUESTED,
    },
    {
      label: tContractStates(ContractRequestState.RETURNED),
      value: ContractRequestState.RETURNED,
    },
    {
      label: tContractStates(ContractRequestState.PARTIALLY_AWARDED),
      value: ContractRequestState.PARTIALLY_AWARDED,
    },
    {
      label: tContractStates(ContractRequestState.FULLY_AWARDED),
      value: ContractRequestState.FULLY_AWARDED,
    },
    {
      label: tContractStates(ContractRequestState.CLOSED),
      value: ContractRequestState.CLOSED,
    },
  ];

  return (
    <Box display="flex" gridColumnGap="1rem" alignItems="center" bgcolor="background.paper" py={"0.5rem"} px={2}>
      <ModeButtonGroup
        value={props.mode}
        onChange={props.onChangeMode}
        includeModes={[TransportationMode.INTERMODAL]}
        disabledModes={[
          TransportationMode.LTL,
          TransportationMode.DRAYAGE,
          TransportationMode.Ocean,
          TransportationMode.Air,
          TransportationMode.INTERMODAL,
        ].filter((value) => {
          if (!disableIntermodal && value === "INTERMODAL") {
            return false;
          }
          return true;
        })}
      />
      <DropdownFilterView
        placeholder={tShipper("filterByStatus")}
        options={dropdownOptions}
        onChange={props.onChangeState}
        selected={props.states}
      />
      <SimpleSearchView search={props.search} onChange={props.onSearch} placeholder={tShipper("searchBidRequests")} />
      <Box marginLeft="auto" />
      <LastRefreshedAtText value={props.lastRefreshedAt} />
      <RefetchButton onClick={props.onClickRefresh} />
    </Box>
  );
};

export default ContractsListFilterBarView;
