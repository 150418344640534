import { baseRestApi } from "api/rest/baseRestApi";

import { NormalizedCargoGroup } from "./types";
import { mapNormalizedCargoGroupsToUpdateApi } from "./utils/mapNormalizedCargoGroupsToUpdateApi";

const API_ROOT = "/shipper/cargo-groups";
module.hot?.accept();

type BodyParams = {
  normalizedCargoGroups: NormalizedCargoGroup[];
  deletedCargoGroups: number[];
};

type QueryArgs = { body: BodyParams };

const updateCargoGroupsApi = baseRestApi.enhanceEndpoints({ addTagTypes: ["CARGO_GROUPS"] }).injectEndpoints({
  overrideExisting: module.hot?.status() === "apply",

  endpoints: (builder) => ({
    updateCargoGroups: builder.mutation<void, QueryArgs>({
      query: (params) => {
        const body = {
          cargo_groups: mapNormalizedCargoGroupsToUpdateApi(params.body.normalizedCargoGroups),
          deleted_cargo_groups: params.body.deletedCargoGroups,
        };

        return {
          url: `${API_ROOT}`,
          method: "PATCH",
          body,
        };
      },
      invalidatesTags: (_returnValue, _baseQuery, args) =>
        args.body.normalizedCargoGroups.map((cargoGroup) => ({ type: "CARGO_GROUPS", id: cargoGroup.id })),
    }),
  }),
});

export default updateCargoGroupsApi;

export const { useUpdateCargoGroupsMutation } = updateCargoGroupsApi;
