import { useEffect, useState } from "react";

import { usePaginatedGetPartnersRequestQuery } from "api/rest/partners/partnersApi";
import { ContactType } from "api/rest/partners/types/domain";
import { ShipperQuoteRequest } from "api/rest/quote-requests";
import usePrefetchAllContactIds from "app/pages/bid-request/hooks/usePrefetchAllPartnerIds";
import TeamSearchView from "app/pages/bid-request/pages/team/components/TeamSearchView";
import TeamSelectHeaderView from "app/pages/bid-request/pages/team/components/TeamSelectHeaderView";
import CursorPaginationTableControlsView from "components/pagination/CursorPaginationTableControlsView";
import ProgressionButtons from "components/ProgressionButtons";
import ScrollableView from "components/ScrollableView";
import SplitContentLayout from "components/SplitContentLayout";
import withAsync from "components/withAsync";
import AddTeamMemberButton from "features/contacts/add-team-member/AddTeamMemberButton";
import without from "lodash/without";
import { useBoolean } from "usehooks-ts";
import { filterContacts } from "utils/filterContacts";
import TeamSelectTable from "views/contacts/TeamSelectTable";

import useLoadQuoteRequestAndRoutingStep from "../../hooks/quotes/useLoadQuoteRequestAndRoutingStep";
import { useSaveAndChangeRoute } from "../../hooks/quotes/useSaveAndChangeRoute";
import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const mapContactTypeToTeammate = (contact: ContactType): ShipperQuoteRequest["teammates"][number] => ({
  contact_id: contact.id,
  email: contact.user.email,
  first_name: contact.first_name,
  last_name: contact.last_name,
  is_internal: true,
});

const TeamStep = withAsync({
  useHook: () => useLoadQuoteRequestAndRoutingStep(QuoteRequestPageSteps.Team),
  Component: ({ loadedData: { quoteRequest } }) => {
    const saveAndChangeRoute = useSaveAndChangeRoute();
    const changeQuoteRequest = quoteRequestActions.useChangeQuoteRequest();

    const [search, setSearch] = useState("");
    const isIndeterminate = useBoolean(false);
    const { data, isLoading, paginationOptions } = usePaginatedGetPartnersRequestQuery({
      queryParams: { partnerType: "internal", search },
    });
    const team = data?.data.partners;
    const selectedTeammates: ShipperQuoteRequest["teammates"] = quoteRequest.teammates;

    const { allContacts: allTeammembers, isLoading: isLoadingAllTeammembers } = usePrefetchAllContactIds("internal");
    const [isAwaitingTeamMembers, setIsAwaitingTeammembers] = useState(false);

    const isChecked = (teamMember: ContactType): boolean => {
      return selectedTeammates.some((teamMate) => teamMate.contact_id === teamMember.id);
    };

    const onClickRow = (teamMember: ContactType): void => {
      isIndeterminate.setTrue();
      const selectedTeamMate = selectedTeammates.find((teamMate) => teamMate.contact_id === teamMember.id);

      if (selectedTeamMate) {
        changeQuoteRequest({ changes: { teammates: without(selectedTeammates, selectedTeamMate) } });
      } else {
        changeQuoteRequest({ changes: { teammates: [...selectedTeammates, mapContactTypeToTeammate(teamMember)] } });
      }
    };

    const onClickSelectAll: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void = (
      _event,
      checked
    ) => {
      isIndeterminate.setFalse();
      if (!checked) {
        changeQuoteRequest({ changes: { teammates: [] } });
        return;
      }
      if (isLoadingAllTeammembers) {
        setIsAwaitingTeammembers(true);
      } else {
        const filteredTeammates = filterContacts(allTeammembers, search);

        changeQuoteRequest({ changes: { teammates: filteredTeammates.map(mapContactTypeToTeammate) } });
      }
    };

    useEffect(() => {
      if (!isLoadingAllTeammembers && isAwaitingTeamMembers) {
        changeQuoteRequest({ changes: { teammates: allTeammembers.map(mapContactTypeToTeammate) } });
        setIsAwaitingTeammembers(false);
      }
    }, [isLoadingAllTeammembers, isAwaitingTeamMembers, allTeammembers, changeQuoteRequest]);

    const onSearchChange = (search: string): void => {
      // without this check, checkbox is set to indeterminate on search when there are no selected partners
      if (selectedTeammates.length > 0) {
        isIndeterminate.setTrue();
      }
      setSearch(search);
    };

    return (
      <ProgressionButtons
        onBack={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Partners })}
        onNext={() => saveAndChangeRoute({ step: QuoteRequestPageSteps.Review })}
      >
        <TeamSelectHeaderView />
        <SplitContentLayout
          leftContent={
            <>
              <TeamSearchView search={search} onChange={onSearchChange} />
            </>
          }
          rightContent={<AddTeamMemberButton />}
        />
        <ScrollableView>
          <TeamSelectTable
            team={team ?? []}
            isLoading={isLoading}
            isChecked={isChecked}
            onClickRow={onClickRow}
            onClickSelectAll={onClickSelectAll}
            checkboxIndeterminate={isIndeterminate.value}
          />
        </ScrollableView>
        <CursorPaginationTableControlsView {...paginationOptions} />
      </ProgressionButtons>
    );
  },
});

export default TeamStep;
