export enum IncotermsEnum {
  FOB = "FOB",
  EXW = "EXW",
  FCA = "FCA",
  FAS = "FAS",
  CFR = "CFR",
  CIF = "CIF",
  CPT = "CPT",
  CIP = "CIP",
  DAP = "DAP",
  DPU = "DPU",
  DDP = "DDP",
}

export type Incoterms = `${IncotermsEnum}`;
