import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useGetShipperQuoteRequestQuery } from "api/rest/quote-requests";
import { useUpdateQuoteRequestMutation } from "pages/shipper/pages/request-quote/pages/ltl/api/generated/updateQuoteRequest.generated";
import { useParams } from "react-router-dom";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useLoadQuoteRequest = () => {
  const [_, { isLoading: isUpdatingQuoteRequest }] = useUpdateQuoteRequestMutation();
  const { quoteRequestId } = useParams<{ quoteRequestId?: string }>();

  const { data } = useGetShipperQuoteRequestQuery(quoteRequestId ? { urlParams: { quoteRequestId } } : skipToken);
  const quoteRequest = data?.data.quoteRequest;

  if (!quoteRequest || isUpdatingQuoteRequest) {
    return {
      isLoading: true,
    };
  }

  return {
    isLoading: false,
    quoteRequest,
  };
};

export default useLoadQuoteRequest;
