import { NormalizedCargoGroup, UpdateCargoGroup } from "../types";

export const mapNormalizedCargoGroupsToUpdateApi = (
  normalizedCargoGroups: NormalizedCargoGroup[]
): UpdateCargoGroup[] => {
  return normalizedCargoGroups
    .filter(
      (cargoGroup) =>
        typeof cargoGroup.id !== "string" &&
        typeof cargoGroup.cargo_unit_id !== "string" &&
        typeof cargoGroup.handling_unit_id !== "string"
    )
    .map(
      (cargoGroup): UpdateCargoGroup => ({
        ...cargoGroup,
        /**
         * These type conversions are fine because:
         * 1. We're filtering these cargo groups out above
         * 2. It shouldn't be possible to try to update new cargo groups
         * 3. It shouldn't be possible to update existing cargo groups to have new cargo groups as parents
         */
        id: cargoGroup.id as unknown as number,
        handling_unit_id: cargoGroup.handling_unit_id as unknown as number,
        cargo_unit_id: cargoGroup.cargo_unit_id as unknown as number,
      })
    );
};
