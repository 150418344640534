import { VFC } from "react";

import { AddCircleOutline } from "@material-ui/icons";
import { Button } from "@portex-pro/ui-components";
import classNames from "classnames";
import Text from "components/Text";
import { PackingGroup as PackingGroupType } from "features/generate-bol/store/generateBOLSlice";
import { useTranslation } from "react-i18next";

import PackingGroupView from "./PackingGroupView";

interface CargoProps {
  cargo: PackingGroupType[];
  onChangeCargoDetail: (changes: Partial<PackingGroupType>, index: number) => void;
  onRemoveCargo: (index: number) => void;
  onAddAnotherItem: () => void;
}

const CargoView: VFC<CargoProps> = (props) => {
  const { cargo, onChangeCargoDetail, onAddAnotherItem, onRemoveCargo } = props;
  const { t } = useTranslation("bolGeneration");

  const totalWeight = cargo.reduce((total, packingGroup) => total + (packingGroup.weight ?? 0), 0);

  return (
    <div className="grid grid-cols-[0.4fr_0.7fr_0.4fr_0.7fr_0.7fr_0.8fr_1.6fr_0.5fr_0.5fr] border-b border-grey-300 bg-white [&_>_div:not(:first-child)]:px-2 [&_>_div:not(:first-child_,_.noPadding)]:py-2 [&_>_.lastCargo]:pb-2 [&_>_div]:border-grey-300">
      <div className="p-5 col-start-1 col-span-full border-b border-t bg-grey-100">
        <Text size="medium" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("cargoDetails")}
        </Text>
      </div>
      <div className="col-start-1 col-span-2 border-r border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".25rem" } }}
        >
          {t("handlingUnit")}
        </Text>
      </div>
      <div className="col-start-3 col-span-2 border-r border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".25rem" } }}
        >
          {t("packingUnit")}
        </Text>
      </div>
      <div className="col-start-8 col-span-2 border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", textAlign: "center" } }}
        >
          {t("ltlOnly")}
        </Text>
      </div>
      <div className="col-start-1 border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".25rem" } }}
        >
          {t("quantity")}
        </Text>
      </div>
      <div className="col-start-2 border-b border-r">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("type")}
        </Text>
      </div>
      <div className="col-start-3 border-b">
        <Text
          size="small"
          weight="bold"
          typographyProps={{ style: { textTransform: "uppercase", paddingLeft: ".25rem" } }}
        >
          {t("quantity")}
        </Text>
      </div>
      <div className="col-start-4 border-b border-r">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("type")}
        </Text>
      </div>
      <div className="col-start-5 row-start-2 row-span-2 border-b flex items-center">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("weightTotal")}
        </Text>
      </div>
      <div className="col-start-6 row-start-2 row-span-2 border-b flex items-center">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("dimensions")}
        </Text>
      </div>
      <div className="col-start-7 row-start-2 row-span-2 border-b border-r flex items-center">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("commodityDescription")}
        </Text>
      </div>
      <div className="col-start-8 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("nmfc")}
        </Text>
      </div>
      <div className="col-start-9 border-b">
        <Text size="small" weight="bold" typographyProps={{ style: { textTransform: "uppercase" } }}>
          {t("class")}
        </Text>
      </div>
      {cargo.map((packingGroup, index) => (
        <>
          <PackingGroupView
            packingGroup={packingGroup}
            // We want the packaging groups to start in the 4th row
            gridPosition={index * 2 + 4}
            packingGroupIndex={index}
            onChangeCargoDetail={onChangeCargoDetail}
          />
          <div
            className={classNames(`row-start-${index * 2 + 5} col-span-full noPadding mt-[-0.5rem] mb-[-0.3rem]`, {
              lastCargo: index === cargo.length - 1,
            })}
          >
            <Button
              variant="text"
              color="primary"
              style={{ padding: 0, margin: 0, justifyContent: "left" }}
              size="small"
              disableRipple
              disableFocusRipple
              disableTouchRipple
              onClick={() => onRemoveCargo(index)}
            >
              {t("remove")}
            </Button>
          </div>
        </>
      ))}
      <div className="col-span-full border-t flex flex-row items-center justify-between">
        <Button onClick={onAddAnotherItem} startIcon={<AddCircleOutline />} color="primary">
          <Text size="small" weight="bold">
            {t("addAnotherItem")}
          </Text>
        </Button>
        <Text size="small">{t("totalWeight", { totalWeight })}</Text>
      </div>
    </div>
  );
};

export default CargoView;
