import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import usableActions from "utils/store/usableActions";

type QuoteIndexSliceState = {
  sortBy: "CHEAPEST" | "FASTEST" | "VALIDITY";
};

const initialState: QuoteIndexSliceState = {
  sortBy: "CHEAPEST",
};

const quoteIndexSliceName = "quoteIndexSlice";

const quoteIndexSlice = createSlice({
  name: quoteIndexSliceName,
  initialState,
  reducers: {
    resetState: () => initialState,
    setState: (state, action: PayloadAction<Partial<QuoteIndexSliceState>>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { useResetState, useSetState } = usableActions(quoteIndexSlice.actions);
export default quoteIndexSlice;
