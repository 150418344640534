import { SVGProps, VFC } from "react";

import {
  Icon,
  portexColor,
  SvgPlaneCircleFilled,
  SvgShipCircleFilled,
  SvgTruckCircleFilled,
  SvgTruckLTL,
} from "@portex-pro/ui-components";
import { ReactComponent as DrayageContainerCircleGreenIcon } from "assets/drayage/drayage-container-circle-green-filled.svg";
import { ReactComponent as IntermodalCircleOrangeIcon } from "assets/intermodal/intermodal-arrows-circle-orange-filled.svg";
import { Mode, ModeEnum } from "types/Mode";

interface BaseModeIconProps {
  mode: Mode | null;
  large?: boolean;
  svgProps?: SVGProps<SVGSVGElement>;
}

const styles = {
  small: {
    width: "2rem",
    height: "2rem",
  },
  large: {
    width: "3.5rem",
    height: "3.5rem",
  },
};

const BaseModeIcon: VFC<BaseModeIconProps> = ({ mode, large = false, svgProps: baseSvgProps }) => {
  const style = { ...(large ? styles.large : null) };

  const svgProps: SVGProps<SVGSVGElement> = { style, ...baseSvgProps };

  let ModeSvgIcon = null;

  switch (mode) {
    case ModeEnum.FTL: {
      ModeSvgIcon = SvgTruckCircleFilled;
      break;
    }
    case ModeEnum.DRAYAGE: {
      ModeSvgIcon = DrayageContainerCircleGreenIcon;
      break;
    }
    case ModeEnum.LTL: {
      const size = large ? styles.large.height : 36;
      const padding = large ? "0px 8px" : "0px 4px";
      return (
        <Icon
          as={SvgTruckLTL}
          // style's width/height is the wrapper size
          style={{ padding: padding, width: size, height: size }}
          width={size}
          height={size}
          variant="filled"
          palette="green"
        />
      );
    }
    case ModeEnum.FCL:
    case ModeEnum.LCL: {
      ModeSvgIcon = SvgShipCircleFilled;
      break;
    }
    case ModeEnum.AIR: {
      svgProps.color = portexColor.purple500;
      ModeSvgIcon = SvgPlaneCircleFilled;
      break;
    }
    case ModeEnum.INTERMODAL: {
      ModeSvgIcon = IntermodalCircleOrangeIcon;
      break;
    }
    default: {
      return null;
    }
  }

  return <ModeSvgIcon {...svgProps} />;
};

export default BaseModeIcon;
