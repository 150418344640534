import { ShipmentPackagingType } from "app/pages/shipments/types/domain";
import fromPairs from "lodash/fromPairs";
import { TrailerSizeEnum } from "types/TrailerSize";

import { EquipmentType } from "./api/types/EquipmentType";
import { ContainerSizeType, ContainerType, HandlingType, PackingType } from "./types";

const excludedPackingTypes: ShipmentPackagingType[] = [
  ShipmentPackagingType.Containers,
  ShipmentPackagingType.Pallets,
  ShipmentPackagingType.Pallets_48_40,
  ShipmentPackagingType.Pallets_48_48,
];

export const packingTypesList: PackingType[] = Object.values(ShipmentPackagingType).filter(
  (packagingType) => !(packagingType in excludedPackingTypes)
) as unknown as PackingType[];

const excludedHandlingTypes: ShipmentPackagingType[] = [ShipmentPackagingType.Containers];

export const handlingTypesList: HandlingType[] = Object.values(ShipmentPackagingType).filter(
  (packagingType) => !(packagingType in excludedHandlingTypes)
) as unknown as PackingType[];

const includedContainerTypes: EquipmentType[] = [
  EquipmentType.Dry,
  EquipmentType.Reefer,
  EquipmentType.Platform,
  EquipmentType.FlatRack,
  EquipmentType.OpenTop,
];

export const containerTypes: Record<string, ContainerType> = fromPairs(
  Object.entries(EquipmentType).filter(([_, equipmentType]) => includedContainerTypes.includes(equipmentType))
) as Record<string, ContainerType>;

export const containerSizesList: ContainerSizeType[] = [
  TrailerSizeEnum.C_20,
  TrailerSizeEnum.C_40,
  TrailerSizeEnum.C_20_HC,
  TrailerSizeEnum.C_40_HC,
  TrailerSizeEnum.C_45_HC,
  TrailerSizeEnum.NA,
];
