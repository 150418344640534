import { useEffectOnce } from "usehooks-ts";

import { quoteRequestActions } from "../../store/quoteRequestSlice";
import { QuoteRequestPageSteps } from "../../types/QuoteRequestPageSteps";

const useQuoteRequestStep = (step: "initial" | QuoteRequestPageSteps): void => {
  const setStep = quoteRequestActions.useSetStep();

  useEffectOnce(() => {
    if (step === "initial") {
      setStep(undefined);
      return;
    }

    setStep(step);
    return;
  });

  return;
};

export default useQuoteRequestStep;
