import { ShipperDispatchRequest } from "api/rest/dispatches";
import { PaginatedApiRequestQueryArgs } from "api/rest/types/requests";
import { ApiResponse, ApiResponsePaginatedCursor, UrlParam } from "api/rest/types/responses";
import { DriverPreference, WeightUnit } from "api/types/generated-types";
import {
  BaseShipment,
  Shipment,
  ShipmentAccessorial,
  ShipmentPackagingType,
  ShipmentPalletType,
  ShipmentQuoteDetails,
  ShipmentState,
} from "app/pages/shipments/types/domain";
import { ModeShipments } from "types/Mode";
import { TrailerSize } from "types/TrailerSize";
import { TrailerType } from "types/TrailerType";

export enum RawShipmentMode {
  Drayage = "DRAYAGE",
  Ftl = "FTL",
  Ltl = "LTL",
  Fcl = "FCL",
  Lcl = "LCL",
  Air = "AIR",
  Intermodal = "INTERMODAL",
}

export enum RawShipmentState {
  Draft = "DRAFT",
  Created = "CREATED",
  InTransit = "IN_TRANSIT",
  Delivered = "DELIVERED",
  Canceled = "CANCELED",
}

export enum RawPalletType {
  PALLET_48_40 = "PALLET_48_40",
  PALLET_42_42 = "PALLET_42_42",
  PALLET_48_48 = "PALLET_48_48",
  PALLET_40_48 = "PALLET_40_48",
  PALLET_48_42 = "PALLET_48_42",
  PALLET_40_40 = "PALLET_40_40",
  PALLET_48_45 = "PALLET_48_45",
  PALLET_44_44 = "PALLET_44_44",
  PALLET_36_36 = "PALLET_36_36",
  PALLET_48_36 = "PALLET_48_36",
  PALLET_45_455 = "PALLET_45_455",
  PALLET_48_20 = "PALLET_48_20",
  PALLET_NOT_SPECIFIED = "PALLET_NOT_SPECIFIED",
}

export enum RawPackagingType {
  PALLETS_48_48 = "PALLETS_48_48",
  PALLETS_48_40 = "PALLETS_48_40",
  CARTONS = "CARTONS",
  PALLETS = "PALLETS",
  BAGS = "BAGS",
  BALES = "BALES",
  BARRELS = "BARRELS",
  BOXES = "BOXES",
  BUNDLES = "BUNDLES",
  COILS = "COILS",
  CONTAINERS = "CONTAINERS",
  CRATES = "CRATES",
  ROLLS = "ROLLS",
  SKIDS = "SKIDS",
  TOTES = "TOTES",
  OTHER = "OTHER",
}

export enum RawAddressType {
  Airport = "AIRPORT",
  Seaport = "SEAPORT",
  Railhead = "RAILHEAD",
  Street = "STREET",
  Unknown = "UNKNOWN",
}

export interface RawAddress {
  id: number;
  address_1?: string | null;
  address_2?: string | null;
  address_contacts?: RawShipmentContact[] | null;
  city: string;
  country_code: string;
  country_name: string;
  google_place_description?: string | null;
  google_place_id?: string | null;
  hours_end?: string | null;
  hours_start?: string | null;
  iana_timezone: string;
  name?: string | null;
  province_code?: string | null;
  province_name?: string | null;
  type?: RawAddressType | null;
  zip?: string | null;
  booking_notes: string;
}

export enum RawDriverPreference {
  None = "NONE",
  Solo = "SOLO",
  Team = "TEAM",
}

export enum RawWeightUnit {
  Kg = "KG",
  Lb = "LB",
}

export enum RawShipmentAccessorialType {
  Delivery = "DELIVERY",
  Pickup = "PICKUP",
}

export interface RawShipmentAccessorial {
  id: number;
  name: string;
  type: RawShipmentAccessorialType;
}

export enum RawShipmentDimUnit {
  IN = "IN",
  CM = "CM",
}

export enum RawShipmentFreightClass {
  FC_50 = "FC_50",
  FC_55 = "FC_55",
  FC_60 = "FC_60",
  FC_65 = "FC_65",
  FC_70 = "FC_70",
  FC_77_5 = "FC_77_5",
  FC_85 = "FC_85",
  FC_92_5 = "FC_92_5",
  FC_100 = "FC_100",
  FC_110 = "FC_110",
  FC_125 = "FC_125",
  FC_150 = "FC_150",
  FC_175 = "FC_175",
  FC_200 = "FC_200",
  FC_250 = "FC_250",
  FC_300 = "FC_300",
  FC_400 = "FC_400",
  FC_500 = "FC_500",
}

export interface RawShipmentPackageGroup {
  packaging_type: RawPackagingType;
  item_quantity: number;
  is_stackable?: boolean | null;
  weight_per_package: number;
  length_per_package: number;
  height_per_package: number;
  width_per_package: number;
  dim_unit?: RawShipmentDimUnit | null;
  freight_class?: RawShipmentFreightClass | null;
  nmfc_code?: string | null;
  commodities?: string | null;
}

export interface RawLoadSpec {
  commodities?: string;
  driver_preference?: RawDriverPreference;
  is_palletized?: boolean;
  max_temp?: number | null;
  min_temp?: number | null;
  package_groups: RawShipmentPackageGroup[];
  packaging_type?: RawPackagingType;
  packing_count?: number;
  pallet_count?: number;
  pallet_type?: RawPalletType;
  total_weight?: number;
  trailer_size?: TrailerSize | null;
  trailer_type: TrailerType;
  unit_count?: number;
  weight_per_unit?: number;
  weight_unit?: RawWeightUnit;
}

export interface RawStop {
  id: number;
  address?: RawAddress;
  end?: string;
  is_time_tbd: boolean;
  note?: string | null;
  booking_notes: string;
  reference_number?: string | null;
  start?: string;
}

export interface RawShipmentOwner {
  id: number;
  first_name?: string;
  last_name?: string;
  email: string;
}

/** @todo: rename this - it's a short quote info, not only for booked quotes */
export interface RawBookedQuote {
  id: string;
  booking_notes?: string;
  company_name: string;
  created_at: string;
  max_transit_time?: number;
  min_transit_time?: number;
  notes?: string;
  rate_per_load: number;
  status: string;
  submitter_email: string;
  total_amount: number;
  carrier_name?: string;
}

type RawShipmentQuoteSource = {
  source_type: "quote";
  booked_quote: RawBookedQuote;
  quote_request_id: number;
  quote_history: RawBookedQuote[];
};

type RawShipmentDispatchSource = {
  dispatch_request: ShipperDispatchRequest;
  source_type: "dispatch";
};

type RawShipmentSource = RawShipmentQuoteSource | RawShipmentDispatchSource;

export type RawShipment = RawShipmentSource & {
  id: number;
  accessorials?: RawShipmentAccessorial[];
  additional_notes?: string | null;
  booking_date: string;
  canceled_reason?: string;
  conversation_id?: string;
  files?: string[] | null;
  goods_value?: number | null;
  hazardous_goods_details?: string | null;
  is_hazardous?: boolean | null;
  load_spec: RawLoadSpec;
  mode: RawShipmentMode;
  owner?: RawShipmentOwner;
  portex_id: string;
  reference_number: string | null;
  status: RawShipmentState;
  stops: RawStop[];
  trucks?: RawTruck[];
  estimated_mileage: number | null;
  adjusted_total?: number | null;
  version_number?: number;
  shipper: { name: string };
  partner: {
    email: string;
    company_name?: string | null;
  };
  lane: string;
  equipment: string;
  last_status_update_at: string | null;
  partner_contact_id: number;
  rating: number | null | undefined;
  rating_notes: string;
};

export type RawPublicShipment = Pick<
  RawShipment,
  | "id"
  | "booking_date"
  | "equipment"
  | "lane"
  | "partner"
  | "portex_id"
  | "reference_number"
  | "shipper"
  | "source_type"
  | "stops"
  | "trucks"
  | "last_status_update_at"
> & {
  mode: ModeShipments;
};

export type RawBaseShipment = {
  id: number;
  portex_id: string;
  reference_number: string;
  status: RawShipmentState;
  mode: RawShipmentMode;
  stops: RawStop[];
  load_spec: Pick<RawLoadSpec, "min_temp" | "max_temp" | "trailer_size" | "trailer_type">;
  trucks?: RawTruck[];
  partner: { company_name: string };
};

export interface RawTruck {
  id?: number;
  reference_number?: string | null;
  tracking_link?: string;
}

export interface RawShipmentContact {
  first_name?: string | null;
  last_name?: string | null;
  email?: string | null;
  phone_number?: string | null;
}

export interface UpdateShipmentAddressPayload {
  address_1?: string;
  address_2?: string;
  address_contacts?: RawShipmentContact[];
  city?: string;
  country_code?: string;
  country_name?: string;
  hours_end?: string | null;
  hours_start?: string | null;
  name?: string;
  province_code?: string;
  province_name?: string;
  zip?: string;
}

export interface ListShipmentsApi {
  queryArgs: PaginatedApiRequestQueryArgs<{
    urlParams?: undefined;
    queryParams?: {
      search?: string;
      mode?: ModeShipments;
      status?: Omit<ShipmentState, ShipmentState.Draft>[];
      pickupFrom?: string;
      pickupTo?: string;
      deliveryFrom?: string;
      deliveryTo?: string;
      ownerIds?: string;
      onlyUnseenNotifications?: boolean;
    };
  }>;
  response: ApiResponsePaginatedCursor<{ shipments: RawBaseShipment[] }>;
  hookReturnValue: ApiResponsePaginatedCursor<BaseShipment[]>;
}

export interface ShipmentDetailsApi {
  queryArgs: {
    urlParams: { shipmentId: UrlParam };
    queryParams?: { version?: "ORIGINAL" | number };
  };
  response: ApiResponse<{ shipment: RawShipment }>;
  hookReturnValue: Shipment;
}

export interface ListShipmentQuotesApi {
  queryArgs: {
    urlParams: { shipmentId: RawShipment["id"] };
  };
  response: ApiResponse<{ quotes: RawBookedQuote[] }>;
  hookReturnValue: ShipmentQuoteDetails[];
}

export interface UpdateShipmentApi {
  queryArgs: {
    urlParams: { shipmentId: RawShipment["id"] };
    body: Partial<Shipment> & { recreate?: {} | undefined };
  };
  response: ApiResponse<{
    shipment: RawShipment;
    recreate: { quoteRequestId: number } | undefined;
    isEmailSent: boolean;
  }>;
  hookReturnValue: {
    shipment: Shipment;
    recreate: { quoteRequestId: number } | undefined;
    isEmailSent: boolean;
  };
}

export interface ListAccessorialOptionsApi {
  queryArgs: {};
  response: ApiResponse<{ accessorials: RawShipmentAccessorial[] }>;
  hookReturnValue: ShipmentAccessorial[];
}

export interface RawBrokerShipment {
  load_spec: {
    trailer_size: TrailerSize | null;
    trailer_type: TrailerType | null;
    commodities: string;
    is_palletized: boolean;
    packaging_type: RawPackagingType | null; // TODO:
    driver_preference: DriverPreference | null;
    packaging_count?: number | null;
    pallet_count?: number | null;
    pallet_type?: ShipmentPalletType | null;
    total_weight?: number | null;
    weight_unit?: WeightUnit | null;
  };
  truck_quantity: number;
  trucks: {
    id: number;
    reference_number: string;
    tracking_link: string;
  }[];
  stops: {
    id: number;
    position: number;
    name?: string | null;
    address_1?: string | null;
    address_2?: string | null;
    city?: string | null;
    province_code?: string | null;
    province_name?: string | null;
    zip?: string | null;
    country_code?: string | null;
    country_name?: string | null;
    date_start: string | null;
    date_end: string | null;
    is_time_tbd: boolean | null;
    iana_timezone: string | null;
    hours_of_operation_start?: string | null;
    hours_of_operation_end?: string | null;
    reference_number?: string;
    operations_contact?: {
      first_name: string | null;
      last_name: string | null;
      phone_number: string | null;
      email: string | null;
    };
    note: string;
    booking_notes: string;
  }[];
  miles?: number | null;
  shipper_id: number;
  shipper_name: string;
  mode: ModeShipments;
  portex_id: string;
  reference_number: string;
  lane: string;
  equipment: string;
}

export interface BrokerShipment {
  load_spec: {
    trailer_size?: TrailerSize | null;
    trailer_type?: TrailerType;
    commodities: string;
    is_palletized?: boolean;
    packaging_type?: ShipmentPackagingType;
    driver_preference?: DriverPreference;
    packaging_count?: number;
    pallet_count?: number;
    pallet_type?: ShipmentPalletType;
    total_weight?: number;
    weight_unit?: WeightUnit;
  };
  truck_quantity: number;
  trucks: {
    id: number;
    reference_number: string;
    tracking_link: string;
  }[];
  stops: BrokerShipmentStopDetails[];
  miles: number | null;
  shipper_name: string;
  mode: ModeShipments;
  portex_id: string;
  reference_number: string;
  lane: string;
  equipment: string;
}

export interface BrokerShipmentStopDetails {
  id: number;
  position: number;
  name?: string;
  address_1?: string;
  address_2?: string;
  city?: string;
  province_code?: string;
  province_name?: string;
  zip?: string;
  country_code?: string;
  country_name?: string;
  date_start?: string;
  date_end?: string;
  is_time_tbd?: boolean;
  iana_timezone?: string;
  hours_of_operation_start?: string;
  hours_of_operation_end?: string;
  reference_number?: string;
  operations_contact?: {
    first_name?: string;
    last_name?: string;
    phone_number?: string;
    email?: string;
  };
  note?: string | null;
  booking_notes: string;
}
