import { ComponentProps, ReactElement, useCallback, useEffect, useState } from "react";

import { gql, TypedDocumentNode, useMutation } from "@apollo/client";
import { Close, Edit } from "@material-ui/icons";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Chip,
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Icon,
  portexColor,
  Radio,
  RadioGroup,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@portex-pro/ui-components";
import LocationPicker from "components/addresses/LocationPicker";
import compact from "lodash/compact";
import last from "lodash/last";
import without from "lodash/without";
import { DateTime } from "luxon";
import { useTranslation } from "react-i18next";
import { AddressOption } from "types/AddressOption";

import {
  Address,
  Maybe,
  Mode,
  Mutation,
  MutationUpdateStopsForQuoteRequestArgs,
  StopPayload,
} from "../../../../../../../api/types/generated-types";
import DoubleTimeRangeSelector from "../../../../../../../components/DoubleTimeRangeSelector";
import LayoutColumnTwo from "../../../../../../../components/LayoutColumnTwo";
import SingleCalendarPicker, { SingleCalendarPickerProps } from "../../../../../../../components/SingleCalendarPicker";
import SingleTimeRangeSelector, {
  SingleTimeRangeSelectorProps,
} from "../../../../../../../components/SingleTimeRangeSelector";
import ThrottledTextInput, { ThrottledTextInputProps } from "../../../../../../../components/ThrottledTextInput";
import { NON_BREAKING_SPACE } from "../../../../../../../constants";
import { useOnApolloError } from "../../../../../../../hooks/useOnApolloError";
import { TimeRange } from "../../../../../../../types/TimeRange";
import { convertStopToTimeRange } from "../../../../../../../utils/convertStopToTimeRange";
import { deserializeNotes } from "../../../../../../../utils/deserializeNotes";
import { displayTimeRange } from "../../../../../../../utils/displayTimeRange";
import { StepperStepProps } from "../../../components/StepperStep";
import { useGoToStep, useStepStates } from "../../../hooks/useStep";
import { useStopsForQuoteRequest, useStopsForQuoteRequest_Stop } from "../hooks/useStopsForQuoteRequest";
import { convertStopToStopPayload } from "../utils/convertStopToStopPayload";

const MIN_STOPS = 2;

const UPDATE_STOPS_FOR_QUOTE_REQUEST: TypedDocumentNode<
  { updateStopsForQuoteRequest: Mutation["updateStopsForQuoteRequest"] },
  MutationUpdateStopsForQuoteRequestArgs
> = gql`
  mutation ($input: UpdateStopsForQuoteRequestInput!) {
    updateStopsForQuoteRequest(input: $input) {
      id
      stops {
        ...useStopsForQuoteRequest_Stop
      }
    }
  }
  ${useStopsForQuoteRequest_Stop}
`;

type StopAccordion = {
  active: boolean;
  seen: boolean;
  done: boolean;
  timeFilled: boolean;
  stop: StopPayload;
  address?: Partial<Address>;
  timeRange: Maybe<TimeRange>;
  shouldShowDate?: boolean;
};

const mutateStopAccordionTimeRangeChange = (stopAccordion: StopAccordion, timeRange: TimeRange | null): void => {
  /** @todo If we ever don't get a timezone back from the address picker (which is possible now) this will cause issues */
  const timezone = stopAccordion.address?.iana_timezone ?? "";

  stopAccordion.timeRange = timeRange;

  stopAccordion.stop.start = timeRange?.start?.setZone(timezone, { keepLocalTime: true }).toJSDate() ?? null;
  stopAccordion.stop.end = timeRange?.end?.setZone(timezone, { keepLocalTime: true }).toJSDate() ?? null;
  stopAccordion.stop.is_time_tbd = timeRange?.isTimeTBD ?? false;
};

type LocationStepProps = StepperStepProps;

const LocationsStep = ({
  active,
  prevStep,
  nextStep,
  goToStep,
  onGoToStep,
  onLoading,
}: LocationStepProps): ReactElement => {
  const { t } = useTranslation(["common", "shipper"]);
  const { onApolloError } = useOnApolloError({ componentName: "LocationsStep" });
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down(1200));
  const step = useStepStates({ onLoading });
  const { dirty, setDirty, loading, setLoading, quoteRequestId, fromTemplate } = step;
  const [showUnfinishedHint, setShowUnfinishedHint] = useState(false);
  const [accordionHovered, setAccordionHovered] = useState<number | false>(false);

  const { data: stops, loading: stopsLoading } = useStopsForQuoteRequest({
    quoteRequestId,
    skip: !active || (!quoteRequestId && active),
  });
  const [updateStopsForQuoteRequest] = useMutation(UPDATE_STOPS_FOR_QUOTE_REQUEST, {
    onError: onApolloError("updateStopsForQuoteRequest"),
  });

  const [accordions, setAccordions] = useState<Array<StopAccordion>>([]);

  useEffect(() => {
    if (accordions.length || !active) return;

    setAccordions(
      stops.map((s, i) => {
        const timeFilled = !!s.start && !!s.end;
        const hasAddress = !!s.address;
        const isFirst = i === 0;

        return {
          active: isFirst,
          seen: isFirst,
          done: hasAddress,
          timeFilled: timeFilled,
          stop: { ...s },
          address: s.address ?? undefined,
          timeRange: convertStopToTimeRange(s),
          shouldShowDate: true,
        };
      })
    );
  }, [accordions.length, active, fromTemplate, stops, quoteRequestId]);

  const handleAddressChange =
    (index: number): ComponentProps<typeof LocationPicker>["onChange"] =>
    (address: Maybe<AddressOption>) => {
      if (!dirty) setDirty(true);

      setAccordions((previous) => {
        const stopAccordion = previous[index];
        stopAccordion.stop.address = address;
        stopAccordion.address = address ?? undefined;

        if (stopAccordion.timeFilled) {
          mutateStopAccordionTimeRangeChange(stopAccordion, stopAccordion.timeRange);
        }

        stopAccordion.done = !!address;

        return [...previous];
      });
    };

  const handleDateChange =
    (index: number): SingleCalendarPickerProps["onChange"] =>
    (date) => {
      if (!date) return;
      if (!dirty) setDirty(true);

      setAccordions((previous) => {
        const stopAccordion = previous[index];
        const currentTimeRange = stopAccordion.timeRange;
        const nextTimeRange = currentTimeRange
          ? {
              // update dates without changing times or isTimeTBD value
              ...currentTimeRange,
              start: currentTimeRange.start?.set({ month: date.month, day: date.day, year: date.year }) ?? null,
              end: currentTimeRange.end?.set({ month: date.month, day: date.day, year: date.year }) ?? null,
            }
          : {
              // by default, don't require change to TOD
              isTimeTBD: true,
              start: date.startOf("day"),
              end: date.endOf("day"),
            };

        mutateStopAccordionTimeRangeChange(stopAccordion, nextTimeRange);

        return [...previous];
      });
    };

  const handleTimeRangeChange =
    (index: number): SingleTimeRangeSelectorProps["onChange"] =>
    (timeRange) => {
      if (!dirty) setDirty(true);

      setAccordions((previous) => {
        const stopAccordion = previous[index];

        mutateStopAccordionTimeRangeChange(stopAccordion, timeRange);
        return [...previous];
      });

      markAccordionTimeFilled(index);
    };

  const handleNoteChange =
    (index: number): ThrottledTextInputProps["onChange"] =>
    (note) => {
      if (!dirty) setDirty(true);

      setAccordions((previous) => {
        previous[index].stop.note = note;
        return [...previous];
      });
    };

  const markAccordionActive = (index: number) => {
    setAccordions((previous) => {
      if (previous[index].active) return previous;

      for (const a of previous) {
        a.active = false;
      }

      previous[index].active = true;
      previous[index].seen = true;

      return [...previous];
    });
  };

  const markAccordionDone = useCallback(
    (index: number) => {
      if (!dirty) setDirty(true);

      setAccordions((previous) => {
        previous[index].done = true;

        return [...previous];
      });
    },
    [dirty, setDirty]
  );

  const markAccordionTimeFilled = (index: number) => {
    if (!dirty) setDirty(true);

    setAccordions((previous) => {
      previous[index].timeFilled = true;

      return [...previous];
    });
  };

  const handleAccordionDateRequired = (index: number, shouldShowDate: boolean) => {
    if (!dirty) setDirty(true);

    setAccordions((previous) => {
      previous[index].shouldShowDate = shouldShowDate;
      if (!shouldShowDate) {
        mutateStopAccordionTimeRangeChange(previous[index], null);
      }

      return [...previous];
    });
  };

  const handleAccordionSave = (index: number) => {
    setAccordions((previous) => {
      if (index < previous.length - 1) {
        previous[index].active = false;
        previous[index + 1].active = true;
        previous[index + 1].seen = true;
      }

      return [...previous];
    });
  };

  const handleAddAnotherStop = () => {
    if (!dirty) setDirty(true);

    setAccordions((previous) => {
      for (const a of previous) a.active = false;
      return [
        ...previous,
        {
          active: true,
          seen: true,
          done: false,
          timeFilled: false,
          stop: {},
          timeRange: null,
          shouldShowDate: true,
        },
      ];
    });
  };

  const handleRemoveStop = (index: number) => {
    if (!dirty) setDirty(true);

    setAccordions((previous) => {
      const removed = without(previous, previous[index]);

      return [...removed];
    });
  };

  const handleSync = useCallback(async () => {
    const { errors } = await updateStopsForQuoteRequest({
      variables: {
        input: {
          quoteRequestId,
          stops: accordions.map((a) => convertStopToStopPayload(a.stop)),
        },
      },
    });

    return !errors;
  }, [accordions, quoteRequestId, updateStopsForQuoteRequest]);

  useGoToStep({ ...step, active, goToStep, onGoToStep, handleSync });

  const handleBackOrNext = async (): Promise<boolean> => {
    let success = true;
    if (dirty) {
      setLoading(true);
      success = await handleSync();
      setLoading(false);
      if (success) setDirty(false);
    }

    return success;
  };

  const showAddAnotherStop = last(accordions)?.done;

  const allRequiredFieldsAreMet =
    accordions.length >= MIN_STOPS &&
    accordions.every((a) => {
      const isTimeFilled = a.shouldShowDate ? a.timeFilled : true;

      const isStopComplete = a.done || (!!a.stop.address && isTimeFilled);
      return isStopComplete;
    });
  const canGoNext = allRequiredFieldsAreMet;

  const backDisabled = loading;
  const nextDisabled = loading || !canGoNext;

  useEffect(() => {
    const lastAccordion = last(accordions);
    const isAccordionDone =
      !!lastAccordion?.stop.address &&
      !lastAccordion.done &&
      (!!lastAccordion?.shouldShowDate || lastAccordion?.timeFilled);
    if (lastAccordion?.done) return;

    if (isAccordionDone) {
      markAccordionDone(accordions.length - 1);
    }
  }, [accordions, markAccordionDone]);

  return (
    <LayoutColumnTwo.Content
      active={active}
      loading={loading || stopsLoading}
      backProps={{
        disabled: backDisabled,
        onClick: async () => {
          const success = await handleBackOrNext();
          onGoToStep(success, prevStep);
        },
      }}
      next={
        <Box onMouseEnter={() => setShowUnfinishedHint(true)} onMouseLeave={() => setShowUnfinishedHint(false)}>
          <Button
            disabled={nextDisabled}
            variant={"contained"}
            color={"primary"}
            size={"large"}
            onClick={async () => {
              const success = await handleBackOrNext();
              onGoToStep(success, nextStep);
            }}
          >
            {t("common:next")}
          </Button>
        </Box>
      }
    >
      <Box display="flex" flexDirection="column" alignItems="center" height="100%" flexGrow={1}>
        {accordions.filter((a) => a.seen).length >= 2 ? <Box mt={-3} /> : null}
        <Box mt={2} px={3} pb={2} pt={3} flexGrow={1} width="96%" maxWidth="100%" mx="auto">
          {accordions.map((a, idx, arr) => {
            const hidden = !a.done && !fromTemplate && !a.seen && idx > 0;
            if (hidden) return null;

            const selectedDate = a.timeRange?.start ?? null;

            const isTimeFilled = a.shouldShowDate ? a.timeFilled : true;
            const isStopComplete = !!a.stop.address && isTimeFilled;
            const isAccordionDone = a.done || isStopComplete;
            const isAccordionNextHidden = idx === arr.length - 1;
            const isAccordionNextDisabled = !isAccordionDone;
            const isDeleteButtonVisible = arr.length > MIN_STOPS;
            const isFirst = idx === 0;
            const isMultiStop = arr.length > 2;
            const unfinishedStop =
              (a.seen && !isAccordionDone && !a.active) || (!isAccordionDone && showUnfinishedHint);

            const accordionLabel = (() => {
              const formatted_long_name = a.address
                ? a.address.formatted_long_name ||
                  [
                    [a.address.address_1, a.address.address_2].filter(Boolean).join(" "),
                    a.address.city,
                    a.address.province_code,
                  ]
                    .filter(Boolean)
                    .join(", ")
                : "";
              const labelPrefix = isFirst && formatted_long_name ? t("shipper:locationsStep.pickup") : "";
              const addressLabel = compact([labelPrefix, formatted_long_name]).join(" ");

              if (formatted_long_name) return addressLabel;

              const multiStopLabel = t("shipper:stopLabel", { no: idx });
              const pickupDeliveryLabel = isFirst ? t("shipper:pickup") : t("shipper:delivery");
              return isMultiStop ? multiStopLabel : pickupDeliveryLabel;
            })();

            const prevStop = idx > 0 ? arr[idx - 1].stop : null;

            const minDate =
              prevStop?.start && a.address?.iana_timezone
                ? DateTime.fromJSDate(prevStop.start, { zone: a.address.iana_timezone }).startOf("day")
                : undefined;

            return (
              <Accordion
                key={`accordions-${idx}`}
                variant="outlined"
                square={false}
                expanded={a.active}
                onChange={() => markAccordionActive(idx)}
                onMouseEnter={() => setAccordionHovered(idx)}
                onMouseLeave={() => setAccordionHovered(false)}
              >
                <Tooltip
                  disableHoverListener
                  open={(!isAccordionDone && showUnfinishedHint) || (unfinishedStop && accordionHovered === idx)}
                  title={t("shipper:missingDetails", { item: "stop" })}
                  arrow
                  placement="right"
                >
                  <AccordionSummary style={{ backgroundColor: unfinishedStop ? portexColor.red100 : "unset" }}>
                    <Typography
                      variant={"subtitle1"}
                      color={isAccordionDone ? "textPrimary" : unfinishedStop ? "error" : "textSecondary"}
                    >
                      {accordionLabel}
                    </Typography>
                    {selectedDate && (
                      <>
                        <Box ml="auto" />
                        <Chip
                          label={
                            <Typography variant="subtitle2">
                              <strong>
                                {compact([
                                  selectedDate.toFormat("LLL dd"),
                                  a.timeFilled ? displayTimeRange(a.timeRange) : "",
                                ]).join(" ")}
                              </strong>
                            </Typography>
                          }
                        />
                        <Box width={10} />
                        <Icon as={Edit} palette={unfinishedStop ? "red" : "grey"} />
                      </>
                    )}
                  </AccordionSummary>
                </Tooltip>
                <AccordionDetails className="Por-dim">
                  <Box px={1} py={0.5}>
                    <LocationPicker
                      mode={Mode.Ftl}
                      label={t("shipper:address")}
                      highlight={!a.stop.address}
                      value={a.address}
                      onChange={handleAddressChange(idx)}
                    />
                    <Box py={1} />
                    <FormControl fullWidth margin={"dense"}>
                      <FormLabel>{t("shipper:locationsStep.confirmDateAndTime")}</FormLabel>
                      <RadioGroup row aria-label="dates" value={a.shouldShowDate} style={{ marginLeft: "6px" }}>
                        <FormControlLabel
                          value={false}
                          control={<Radio name="isAddDates" />}
                          checked={!a.shouldShowDate}
                          onClick={() => handleAccordionDateRequired(idx, false)}
                          label={t("common:no")}
                        />
                        <FormControlLabel
                          value={true}
                          control={<Radio name="isAddDates" />}
                          checked={!!a.shouldShowDate}
                          onClick={() => handleAccordionDateRequired(idx, true)}
                          label={t("common:yes")}
                        />
                      </RadioGroup>
                    </FormControl>
                    <Collapse in={a.shouldShowDate}>
                      <Box py={1} />
                      <Box display="flex" alignItems="center" flexWrap="wrap" gridColumnGap={16}>
                        <FormControl margin="dense" fullWidth style={{ flex: smallScreen ? "0 0 100%" : "1 1 100px" }}>
                          <SingleCalendarPicker
                            label={t("shipper:date")}
                            disabled={!a.stop.address}
                            highlight={!selectedDate}
                            minDate={minDate}
                            onChange={handleDateChange(idx)}
                            required={false}
                            value={selectedDate}
                          />
                          <FormHelperText>
                            {selectedDate ? selectedDate.toFormat("cccc, LLLL dd") : NON_BREAKING_SPACE}
                          </FormHelperText>
                        </FormControl>
                        <FormControl margin="dense" fullWidth style={{ flex: "2 2 150px" }}>
                          <FormLabel>{t("shipper:time")}</FormLabel>
                          <Box display="flex" alignItems="center">
                            <SingleTimeRangeSelector
                              value={a.timeRange}
                              forceNoValue={!a.timeFilled}
                              highlight={!!selectedDate && !a.timeFilled}
                              onChange={handleTimeRangeChange(idx)}
                              disabled={!selectedDate}
                              style={{ flex: 1 }}
                            />
                            <Typography color="textSecondary" style={{ margin: "0 0.75rem" }}>
                              <strong>or</strong>
                            </Typography>
                            <DoubleTimeRangeSelector
                              value={a.timeRange}
                              placeholders={{ from: t("shipper:start"), to: t("shipper:end") }}
                              onChange={handleTimeRangeChange(idx)}
                              highlight={!!selectedDate && !a.timeFilled}
                              disabled={!selectedDate}
                              style={{ flex: 1 }}
                            />
                          </Box>
                          <FormHelperText>
                            {a.timeFilled ? displayTimeRange(a.timeRange) : NON_BREAKING_SPACE}
                          </FormHelperText>
                        </FormControl>
                      </Box>
                    </Collapse>
                    <ThrottledTextInput
                      multiline
                      rows={3}
                      label={t("common:notes")}
                      placeholder={t("shipper:locationsStep.notesPlaceholder")}
                      InputProps={{ style: { paddingTop: 0, paddingBottom: 0 } }}
                      value={deserializeNotes(a.stop.note ?? "")}
                      onChange={handleNoteChange(idx)}
                    />
                    <Box mt={1} display="flex" justifyContent="space-between" width="100%">
                      <Tooltip title={t("shipper:locationsStep.removeStopTooltip")} arrow placement="right">
                        <Button
                          startIcon={<Icon as={Close} style={{ fontSize: 24 }} />}
                          style={{
                            visibility: isDeleteButtonVisible ? "visible" : "hidden",
                            backgroundColor: "#fff",
                            color: portexColor.red500,
                            borderColor: portexColor.red500,
                            padding: "8px 12px",
                          }}
                          variant="outlined"
                          onClick={() => handleRemoveStop(idx)}
                        >
                          {t("shipper:locationsStep.removeStop")}
                        </Button>
                      </Tooltip>
                      <Button
                        disabled={isAccordionNextDisabled}
                        style={{
                          visibility: isAccordionNextHidden ? "hidden" : "visible",
                          minWidth: 160,
                        }}
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          markAccordionDone(idx);
                          handleAccordionSave(idx);
                        }}
                      >
                        {t("shipper:save")}
                      </Button>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            );
          })}
          {showAddAnotherStop ? (
            <Box display="flex" flexDirection="row-reverse" width="100%" pt={2}>
              <Tooltip title={t("shipper:locationsStep.addAnotherStop")} arrow placement="left">
                <Button style={{ minWidth: 220 }} variant="outlined" color="primary" onClick={handleAddAnotherStop}>
                  + {t("shipper:locationsStep.addAnotherStop")}
                </Button>
              </Tooltip>
            </Box>
          ) : null}
        </Box>
      </Box>
    </LayoutColumnTwo.Content>
  );
};

export default LocationsStep;
